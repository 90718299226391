import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import axios from 'axios';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:768px)']: { 
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    businessName: {
        fontSize: 30,
        fontWeight: 'bold'
    },
    logoContainer: {
        paddingTop: 24,
        paddingBottom: 24
    },
    businessImage: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        borderRadius: 12
    },
    fieldsContainer: {
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingRight: 48,
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    inputContainer: {
        width: '100%',
        height: 65
    },
    inputContainerSlogan: {
        width: '100%',
        height: 65,
        marginTop: 38
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    leftBusinessPanel: {
        ['@media (max-width:768px)']: { 
          width: '90%'
        }
    },
    rightBusinessPanel: {
        textAlign: 'left'
    },
    openingHoursContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: -8,
        ['@media (max-width:768px)']: { 
            width: '100%',
            display: 'block'
        }
    },
    day: {
        position: 'relative',
        marginTop: 10,
        marginRight: 15,
        width: '20%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    startTime: {
        width: '35%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    endTime: {
        width: '35%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    opening: {
        marginTop: 38
    },
    changeLogo: {
        backgroundColor: 'transparent',
        border: 'none',
        fontFamily: 'Nunito',
        fontSize: 16,
        position: 'absolute',
        marginTop: 30,
        marginLeft: 20,
        "&:focus": {
            boxShadow: "none",
            outline: "none"
        },
        "&:active": {
            boxShadow: "none",
            outline: "none"
        },
        "&:hover": {
            cursor: "pointer"
        }
    },
    saveContainer: {
        float: 'right',
        // marginRight: 42
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: 7,
        marginTop: 6
    },
    inputContainerHalf: {
        width: '48%',
        height: 65,
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    inputContainerSloganHalf: {
        width: '48%',
        height: 65,
        // marginTop: 38,
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    halfWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ['@media (max-width:768px)']: { 
            display: 'block',
        }
    }
}));

const Profile = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const { t, i18n } = useTranslation();

    const inputFile = useRef(null);
    const file = useRef(null);

    let [business, setBusiness] = useState('');
    let [businessName, setBusinessName] = useState('');
    let [businessNameAr, setBusinessNameAr] = useState('');
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    let [phone, setPhone] = useState('');
    let [address, setAddress] = useState('');
    let [servicesPerHour, setServicesPerHour] = useState('');
    let [slogan, setSlogan] = useState('');
    let [sloganAr, setSloganAr] = useState('');
    let [tags, setTags] = useState('');
    let [tagsAr, setTagsAr] = useState('');

    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);

    const [mondayStartTime, setMondayStartTime] = useState('');
    const [tuesdayStartTime, setTuesdayStartTime] = useState('');
    const [wednesdayStartTime, setWednesdayStartTime] = useState('');
    const [thursdayStartTime, setThursdayStartTime] = useState('');
    const [fridayStartTime, setFridayStartTime] = useState('');
    const [saturdayStartTime, setSaturdayStartTime] = useState('');
    const [sundayStartTime, setSundayStartTime] = useState('');
    const [breakStartTime, setBreakStartTime] = useState('');

    const [mondayEndTime, setMondayEndTime] = useState('');
    const [tuesdayEndTime, setTuesdayEndTime] = useState('');
    const [wednesdayEndTime, setWednesdayEndTime] = useState('');
    const [thursdayEndTime, setThursdayEndTime] = useState('');
    const [fridayEndTime, setFridayEndTime] = useState('');
    const [saturdayEndTime, setSaturdayEndTime] = useState('');
    const [sundayEndTime, setSundayEndTime] = useState('');
    const [breakEndTime, setBreakEndTime] = useState('');

    const [cash, setCash] = useState(false);
    const [card, setCard] = useState(false);

    const [state, setState] = useState({
        file: null
    });

    const handleChange = (event) => {
        switch(event.target.name) {
            case 'monday': 
                setMonday(event.target.checked);
                if(!event.target.checked) {
                    setMondayStartTime('');
                    setMondayEndTime('');
                }
                break;
            case 'tuesday': 
                setTuesday(event.target.checked);
                if(!event.target.checked) {
                    setTuesdayStartTime('');
                    setTuesdayEndTime('');
                }
                break;  
            case 'wednesday': 
                setWednesday(event.target.checked);
                if(!event.target.checked) {
                    setWednesdayStartTime('');
                    setWednesdayEndTime('');
                }
                break; 
            case 'thursday': 
                setThursday(event.target.checked);
                if(!event.target.checked) {
                    setThursdayStartTime('');
                    setThursdayEndTime('');
                }
                break; 
            case 'friday': 
                setFriday(event.target.checked);
                if(!event.target.checked) {
                    setFridayStartTime('');
                    setFridayEndTime('');
                }
                break;  
            case 'saturday': 
                setSaturday(event.target.checked);
                if(!event.target.checked) {
                    setSaturdayStartTime('');
                    setSaturdayEndTime('');
                }
                break;
            case 'sunday': 
                setSunday(event.target.checked);
                if(!event.target.checked) {
                    setSundayStartTime('');
                    setSundayEndTime('');
                }
                break;  
            case 'cash': 
                setCash(event.target.checked);
                break;
            case 'card': 
                setCard(event.target.checked);
                break;  
        }
    };

    const handleDateChange = (date, type) => {
        switch(type) {
            case 'mondayStartTime': 
                setMondayStartTime(date)
                break;
            case 'mondayEndTime': 
                setMondayEndTime(date)
                break;
            case 'tuesdayStartTime': 
                setTuesdayStartTime(date)
                break;
            case 'tuesdayEndTime': 
                setTuesdayEndTime(date)
                break;  
            case 'wednesdayStartTime': 
                setWednesdayStartTime(date)
                break; 
            case 'wednesdayEndTime': 
                setWednesdayEndTime(date)
                break; 
            case 'thursdayStartTime': 
                setThursdayStartTime(date)
                break; 
            case 'thursdayEndTime': 
                setThursdayEndTime(date)
                break; 
            case 'fridayStartTime': 
                setFridayStartTime(date)
                break;  
            case 'fridayEndTime': 
                setFridayEndTime(date)
                break; 
            case 'saturdayStartTime': 
                setSaturdayStartTime(date)
                break;
            case 'saturdayEndTime': 
                setSaturdayEndTime(date)
                break;
            case 'sundayStartTime': 
                setSundayStartTime(date)
                break;  
            case 'sundayEndTime': 
                setSundayEndTime(date)
                break;  
            case 'breakStartTime': 
                setBreakStartTime(date)
                break;  
            case 'breakEndTime': 
                setBreakEndTime(date)
                break;  
        }
    };

    useEffect(() => {
        axios.get(API_URL + `/wash-center/${id}`)
            .then(res => {
                let business = res.data.business;
                let business_hours = business.business_opening_hours;
                let monday = false;
                let tuesday = false;
                let wednesday = false;
                let thursday = false;
                let friday = false;
                let saturday = false;
                let sunday = false;
                let mondayStartTime;
                let mondayEndTime;
                let tuesdayStartTime;
                let tuesdayEndTime;
                let wednesdayStartTime;
                let wednesdayEndTime;
                let thursdayStartTime;
                let thursdayEndTime;
                let fridayStartTime;
                let fridayEndTime;
                let saturdayStartTime;
                let saturdayEndTime;
                let sundayStartTime;
                let sundayEndTime;
                let breakStartTime;
                let breakEndTime;

                business_hours = business_hours.map(function(bus, i) {
                    let utc_time = '';
                    let timezoneOffset = new Date().getTimezoneOffset();
                    let tz = moment(bus.start_time).utc().utcOffset(timezoneOffset);

                    let utc_start_time;
                    if(bus.start_time) {
                        utc_start_time = (moment(bus.start_time).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm").toString() + ":00Z").replace(" ", "T");
                    }

                    let utc_end_time;
                    if(bus.end_time) {
                        utc_end_time = (moment(bus.end_time).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm").toString() + ":00Z").replace(" ", "T");
                    }

                    if(bus.day_id == 1 && utc_start_time && utc_end_time) {
                        monday = true;
                        mondayStartTime = utc_start_time;
                        mondayEndTime = utc_end_time;
                        setMonday(monday);
                        setMondayStartTime(mondayStartTime);
                        setMondayEndTime(mondayEndTime);
                    } else if(bus.day_id == 2 && utc_start_time && utc_end_time) {
                        tuesday = true;
                        tuesdayStartTime = utc_start_time;
                        tuesdayEndTime = utc_end_time;
                        setTuesday(tuesday);
                        setTuesdayStartTime(tuesdayStartTime);
                        setTuesdayEndTime(tuesdayEndTime);
                    } else if(bus.day_id == 3 && utc_start_time && utc_end_time) {
                        wednesday = true;
                        wednesdayStartTime = utc_start_time;
                        wednesdayEndTime = utc_end_time;
                        setWednesday(wednesday);
                        setWednesdayStartTime(wednesdayStartTime);
                        setWednesdayEndTime(wednesdayEndTime);
                    } else if(bus.day_id == 4 && utc_start_time && utc_end_time) {
                        thursday = true;
                        thursdayStartTime = utc_start_time;
                        thursdayEndTime = utc_end_time;
                        setThursday(thursday);
                        setThursdayStartTime(thursdayStartTime);
                        setThursdayEndTime(thursdayEndTime);
                    } else if(bus.day_id == 5 && utc_start_time && utc_end_time) {
                        friday = true;
                        fridayStartTime = utc_start_time;
                        fridayEndTime = utc_end_time;
                        setFriday(friday);
                        setFridayStartTime(fridayStartTime);
                        setFridayEndTime(fridayEndTime);
                    } else if(bus.day_id == 6 && utc_start_time && utc_end_time) {
                        saturday = true;
                        saturdayStartTime = utc_start_time;
                        saturdayEndTime = utc_end_time;
                        setSaturday(saturday);
                        setSaturdayStartTime(saturdayStartTime);
                        setSaturdayEndTime(saturdayEndTime);
                    } else if(bus.day_id == 7 && utc_start_time && utc_end_time) {
                        sunday = true;
                        sundayStartTime = utc_start_time;
                        sundayEndTime = utc_end_time;
                        setSunday(sunday);
                        setSundayStartTime(sundayStartTime);
                        setSundayEndTime(sundayEndTime);
                    }
        
                    return {
                        utc_start_time,
                        utc_end_time,
                        ...bus
                    }
                })
                
                setBusiness(business);
                setBusinessName(business.business_name);
                setBusinessNameAr(business.business_name_ar);
                setName(business.name);
                setEmail(business.email);
                setPassword(business.password);
                setPhone(business.phone_number);
                setAddress(business.address);
                setServicesPerHour(business.services_per_hour);
                setSlogan(business.slogan);
                setSloganAr(business.slogan_ar);

                if(business.start_break) {
                    setBreakStartTime(business.start_break);
                }

                if(business.end_break) {
                    setBreakEndTime(business.end_break);
                }

                axios.get(API_URL + `/business/payment-methods/${ res.data.business.id}`)
                    .then(resp => {
                        let payment_methods = resp.data.payment_methods;
                        payment_methods.forEach(payment_method => {
                            console.log(payment_method)
                            if(payment_method.payment_method_id == 1) {
                                setCash(true);
                            }
                            if(payment_method.payment_method_id == 2) {
                                setCard(true);
                            }
                        });
                    });

                axios.get(API_URL + `/business/tags/${ res.data.business.id}`)
                    .then(rsp => {
                        setTags(rsp.data.tags);
                        setTagsAr(rsp.data.tags_ar);
                    });
            })
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    }

    const onButtonClick = () => {
       inputFile.current.click();
    };

    const handleFileChange = (event) => {
        var inputfile = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(inputfile);

        reader.onloadend = function (e) {
            setState({
                file: [reader.result],
                inputFile: inputfile
            })
        }.bind(this);
    };

    function handleClick(e) {
        e.preventDefault();

        let timezoneOffset = new Date().getTimezoneOffset();
        timezoneOffset = timezoneOffset * -1;

         const formData = new FormData();

        if(state.file) {
            let random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6);
            formData.append("image", state.inputFile);
        }

        formData.append('business_name', businessName);
        formData.append('business_name_ar', businessNameAr);
        formData.append('name', name);
        formData.append('phone_number', phone);
        formData.append('email', email);
        formData.append('address', address);
        formData.append('user_id', business.user_id);
        formData.append('services_per_hour', servicesPerHour);
        formData.append('slogan', slogan);
        formData.append('slogan_ar', sloganAr);
        formData.append('cash', cash ? '1' : '0');
        formData.append('card', card ? '1' : '0');
        formData.append('tags', tags);
        formData.append('tags_ar', tagsAr);
        if(password) {
            formData.append('password', password);
        }

        axios.post(API_URL + `/business/profile/update-new/${business.id}`, formData)
        .then(res => {
            if(res.data && res.data.business) {
                axios.post(API_URL + `/wash-center/hours/update/${business.id}`, {
                    monday_start_time: !mondayStartTime ? '' : moment(mondayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    monday_end_time: !mondayEndTime ? '' : moment(mondayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    tuesday_start_time: !tuesdayStartTime ? '' : moment(tuesdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    tuesday_end_time: !tuesdayEndTime ? '' : moment(tuesdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    wednesday_start_time: !wednesdayStartTime ? '' : moment(wednesdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    wednesday_end_time: !wednesdayEndTime ? '' : moment(wednesdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    thursday_start_time: !thursdayStartTime ? '' : moment(thursdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    thursday_end_time: !thursdayEndTime ? '' : moment(thursdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    friday_start_time: !fridayStartTime ? '' : moment(fridayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    friday_end_time: !fridayEndTime ? '' : moment(fridayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    saturday_start_time: !saturdayStartTime ? '' : moment(saturdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    saturday_end_time: !saturdayEndTime ? '' : moment(saturdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    sunday_start_time: !sundayStartTime ? '' : moment(sundayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString(),
                    sunday_end_time: !sundayEndTime ? '' : moment(sundayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm").toString(),
                    break_start_time: !breakStartTime ? '' : moment(breakStartTime).utc().utcOffset(timezoneOffset).format("HH:mm").toString(),
                    break_end_time: !breakEndTime ? '' : moment(breakEndTime).utc().utcOffset(timezoneOffset).format("HH:mm").toString(),
                }).then(response => {
                    if(response.data.message) {
                        toast.success(t("profile.success"), {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if(state.file) {
                            localStorage.setItem('image', state.inputFile.name);
                        }
                        localStorage.setItem('name', businessName);
                        localStorage.setItem('email', email);
                        window.location.reload(true);
                    } else {
                        toast.error(t("profile.error"), {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                
            } else {
                toast.error("There was an error processing your request", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'businessName': 
                setBusinessName(event.target.value);
                break;
            case 'businessNameAr': 
                setBusinessNameAr(event.target.value);
                break;
            case 'name':
                setName(event.target.value);
                break;
            case 'email': 
                setEmail(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;
            case 'phone':
                setPhone(event.target.value);
                break;
            case 'address': 
                setAddress(event.target.value);
                break;
            case 'servicesPerHour': 
                setServicesPerHour(event.target.value);
                break;
            case 'slogan':
                setSlogan(event.target.value);
                break;
            case 'sloganAr':
                setSloganAr(event.target.value);
                break;
            case 'tags':
                setTags(event.target.value);
                break;
            case 'tagsAr':
                setTagsAr(event.target.value);
                break;
        }
    };

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                    <ToastContainer />
                    <Grid
                        container
                    >
                        <div className={classes.leftBusinessPanel}>
                            <div className={classes.businessName}>{business.business_name}</div>
                            <div className={classes.logoContainer}>
                                {!state.file &&
                                    <img
                                        className={classes.businessImage}
                                        src={API_URL + '/business/image/' + business.image} 
                                    />
                                }
                                {state.file &&
                                    <img
                                        className={classes.businessImage}
                                        src={state.file} 
                                    />
                                }
                                <input type='file' id='file' name="image" ref={inputFile} style={{display: 'none'}} onChange={handleFileChange} />
                                <button className={classes.changeLogo} onClick={onButtonClick}>{t('profile.changelogo')}</button>
                            </div>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.halfWrapper}>
                                    <div className={classes.inputContainerHalf}>
                                        <TextField 
                                            id="businessname"
                                            label={t('profile.nameofbusiness')} 
                                            className={classes.input}
                                            placeholder={t('profile.nameofbusiness')}
                                            value={businessName || ''}
                                            onChange={(event) => handleFieldChange(event, 'businessName')}
                                        />
                                    </div>
                                    <div className={classes.inputContainerHalf}>
                                        <TextField 
                                            id="businessname"
                                            label={t('profile.nameofbusinessar')} 
                                            className={classes.input}
                                            placeholder={t('profile.nameofbusinessar')}
                                            value={businessNameAr || ''}
                                            onChange={(event) => handleFieldChange(event, 'businessNameAr')}
                                        />
                                    </div>
                                </div>
                                <div className={classes.halfWrapper}>
                                    <div className={classes.inputContainerSloganHalf}>
                                        <TextField 
                                            id="slogan"
                                            label={t('profile.description')} 
                                            className={classes.input}
                                            placeholder={t('profile.description')}
                                            value={slogan || ''}
                                            onChange={(event) => handleFieldChange(event, 'slogan')}
                                        />
                                    </div>
                                    <div className={classes.inputContainerSloganHalf}>
                                        <TextField 
                                            id="slogan"
                                            label={t('profile.descriptionar')} 
                                            className={classes.input}
                                            placeholder={t('profile.descriptionar')}
                                            value={sloganAr || ''}
                                            onChange={(event) => handleFieldChange(event, 'sloganAr')}
                                        />
                                    </div>
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="name"
                                        label={t('profile.firstandlastname')} 
                                        className={classes.input}
                                        placeholder={t('profile.firstandlastname')}
                                        value={name || ''}
                                        onChange={(event) => handleFieldChange(event, 'name')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="email"
                                        label={t('profile.emailaddress')}
                                        className={classes.input}
                                        placeholder={t('profile.emailaddress')}
                                        value={email || ''}
                                        onChange={(event) => handleFieldChange(event, 'email')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="password"
                                        label={t('profile.password')}
                                        className={classes.input}
                                        placeholder={t('profile.password')}
                                        type="password"
                                        value={password || ''}
                                        onChange={(event) => handleFieldChange(event, 'password')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="phoneNumber"
                                        label={t('profile.telephonenumber')}
                                        className={classes.input}
                                        placeholder={t('profile.telephonenumber')}
                                        value={phone || ''}
                                        onChange={(event) => handleFieldChange(event, 'phone')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="address"
                                        label={t('profile.address')} 
                                        className={classes.input}
                                        placeholder={t('profile.address')}
                                        value={address || ''}
                                        onChange={(event) => handleFieldChange(event, 'address')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="businessType"
                                        label={t('profile.typeofbusiness')}
                                        className={classes.input}
                                        placeholder={t('profile.typeofbusiness')}
                                        value={business.business_type_id == 2 ? 'Washing Center' : ''}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="servicesPerHour"
                                        label={t('profile.servicesperhour')} 
                                        className={classes.input}
                                        placeholder={t('profile.servicesperhour')}
                                        value={servicesPerHour || ''}
                                        onChange={(event) => handleFieldChange(event, 'servicesPerHour')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.rightBusinessPanel}>
                            <div className={classes.businessName}>{business.business_name}</div>
                            <div style={{
                                marginTop: 24
                            }}>
                                <h4
                                    style={{
                                        marginBottom: 0
                                    }}
                                >{t('profile.openinghours')} :</h4>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={monday} 
                                                    onChange={handleChange} 
                                                    name="monday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Monday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="monday-start-time"
                                                onChange={(date) => handleDateChange(date, 'mondayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={mondayStartTime}
                                                disabled={!monday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="monday-end-time"
                                                onChange={(date) => handleDateChange(date, 'mondayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={mondayEndTime}
                                                disabled={!monday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={tuesday} 
                                                    onChange={handleChange} 
                                                    name="tuesday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Tuesday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="tuesday-start-time"
                                                onChange={(date) => handleDateChange(date, 'tuesdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={tuesdayStartTime}
                                                disabled={!tuesday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="tuesday-end-time"
                                            onChange={(date) => handleDateChange(date, 'tuesdayEndTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            inputVariant="outlined"  
                                            keyboardIcon={<KeyboardArrowDownIcon />}
                                            value={tuesdayEndTime}
                                            disabled={!tuesday}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={wednesday} 
                                                    onChange={handleChange} 
                                                    name="wednesday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Wednesday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="wednesday-start-time"
                                                onChange={(date) => handleDateChange(date, 'wednesdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={wednesdayStartTime}
                                                disabled={!wednesday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="wednesday-end-time"
                                            onChange={(date) => handleDateChange(date, 'wednesdayEndTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            inputVariant="outlined"  
                                            keyboardIcon={<KeyboardArrowDownIcon />}
                                            value={wednesdayEndTime}
                                            disabled={!wednesday}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={thursday} 
                                                    onChange={handleChange} 
                                                    name="thursday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Thursday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="thursday-start-time"
                                                onChange={(date) => handleDateChange(date, 'thursdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={thursdayStartTime}
                                                disabled={!thursday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="thursday-end-time"
                                            onChange={(date) => handleDateChange(date, 'thursdayEndTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            inputVariant="outlined"  
                                            keyboardIcon={<KeyboardArrowDownIcon />}
                                            value={thursdayEndTime}
                                            disabled={!thursday}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={friday} 
                                                    onChange={handleChange} 
                                                    name="friday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Friday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="friday-start-time"
                                                onChange={(date) => handleDateChange(date, 'fridayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={fridayStartTime}
                                                disabled={!friday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="friday-end-time"
                                                onChange={(date) => handleDateChange(date, 'fridayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={fridayEndTime}
                                                disabled={!friday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={saturday} 
                                                    onChange={handleChange} 
                                                    name="saturday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Saturday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="saturday-start-time"
                                                onChange={(date) => handleDateChange(date, 'saturdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={saturdayStartTime}
                                                disabled={!saturday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="saturday-end-time"
                                                onChange={(date) => handleDateChange(date, 'saturdayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={saturdayEndTime}
                                                disabled={!saturday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={sunday} 
                                                    onChange={handleChange} 
                                                    name="sunday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Sunday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="sunday-start-time"
                                                onChange={(date) => handleDateChange(date, 'sundayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={sundayStartTime}
                                                disabled={!sunday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="sunday-end-time"
                                                onChange={(date) => handleDateChange(date, 'sundayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={sundayEndTime}
                                                disabled={!sunday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                marginTop: 11
                            }}>
                                <h4 style={{
                                    marginBottom: 0
                                }}>{t('profile.breakhours')} :</h4>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <div style={{
                                            paddingTop: 15
                                        }}>
                                            {t('profile.time')}
                                        </div>
                                    </div>
                                    <div className={classes.startTime} id="break-start-container">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="break-start-time"
                                                onChange={(date) => handleDateChange(date, 'breakStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={breakStartTime}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime} id="break-end-container">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="break-end-time"
                                                onChange={(date) => handleDateChange(date, 'breakEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={breakEndTime}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                marginTop: 12
                            }}>
                                <h4 style={{
                                    marginBottom: 0
                                }}>{t('profile.paymentmethods')} :</h4>
                                <div style={{
                                    marginLeft: 8,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={cash} 
                                                    onChange={handleChange} 
                                                    name="cash" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Cash"
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={card} 
                                                    onChange={handleChange} 
                                                    name="card" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Card (Benefit, Visa, MasterCard)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: 19
                                }}
                            >
                                <h4
                                    style={{
                                        marginBottom: 0
                                    }}
                                >{t('profile.tags')} :</h4>
                                <div className={classes.halfWrapper}>
                                    <div className={classes.inputContainerHalf}>
                                        <TextField 
                                            id="tags"
                                            label=""
                                            className={classes.input}
                                            placeholder=""
                                            value={tags || ''}
                                            onChange={(event) => handleFieldChange(event, 'tags')}
                                        />
                                    </div>
                                    <div className={classes.inputContainerHalf}>
                                        <TextField 
                                            id="tagsar"
                                            label=""
                                            className={classes.input}
                                            placeholder={t('profile.tagsar')}
                                            value={tagsAr || ''}
                                            onChange={(event) => handleFieldChange(event, 'tagsAr')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.saveContainer}>
                                <div className={classes.saveText}>{t('profile.save')}</div>
                                <div 
                                    onClick={handleClick}
                                    className={classes.saveBtnContainer} 
                                >
                                    <img 
                                        className={classes.saveBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Carcare" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </div>
            </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default Profile;