import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextareaAutosize } from '@material-ui/core';
import logoimage from '../images/logo-image.png'; 
import logotext from '../images/logo-text.png'; 
import facebookimage from '../images/facebook.png'; 
import instagramimage from '../images/instagram.png'; 
import twitterimage from '../images/twitter.png'; 
import linkedinimage from '../images/linkedin.png'; 
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: '#fff',
        width: '100%',
        bottom: 0,
        paddingTop: 250,
        paddingBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:768px)']: { 
          paddingTop: 100
        }
    },
    col1: {
        width: '20%',
        textAlign: 'left',
        marginRight: '10%',
        ['@media (max-width:768px)']: { 
          width: '100%',
          textAlign: 'center',
          marginRight: 0
        },
        ['@media (min-width:769px) and (max-width:1094px)']: { 
            width: '50%'
        }
    },
    col2: {
        width: '15%',
        ['@media (max-width:768px)']: { 
          width: '100%',
          marginTop: 100
        },
        ['@media (min-width:769px) and (max-width:1094px)']: { 
            width: '40%'
        }
    },
    col3: {
        width: '15%',
        ['@media (max-width:768px)']: { 
          width: '100%',
          marginTop: 100
        },
        ['@media (min-width:769px) and (max-width:1094px)']: { 
            width: '50%',
            marginTop: 100
        }
    },
    col4: {
        width: '40%',
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingBottom: 100,
          marginTop: 100
        },
        ['@media (min-width:769px) and (max-width:1094px)']: { 
            marginBottom: 120,
            marginTop: 100
        }
    },
    copyright: {
        fontSize: 14,
        color: '#a7a7a7'
    },
    logoImage: {
        width: 65
    },
    logoText: {
        top: -24,
        left: 7,
        width: 132,
        position: 'relative'
    },
    col1Text: {
        color: '#a7a7a7',
        fontSize: 14,
        textAlign: 'left',
        marginTop: 9,
        marginBottom: 60,
        height: 95,
        width: '77%',
        ['@media (max-width:768px)']: { 
          width: '100%',
          textAlign: 'center'
        }
    },
    socialMediaImage: {
        width: 50,
        marginRight: 10
    },
    colContent: {
        marginTop: 19,
        textAlign: 'left',
        ['@media (max-width:768px)']: { 
          textAlign: 'center'
        }
    },
    colHeader: {
        fontWeight: 'bold'
    },
    linkContainer: {
        marginTop: 38
    },
    link: {
        color: '#9F0000',
        textDecoration: 'none',
        fontSize: 14,
        display: 'block',
        paddingBottom: 18
    },
    email: {
        marginTop: 40,
        width: '71%',
        marginRight: 20
    },
    subscribeContainer: {
        display: 'inline-block',
        marginTop: 58
    },
    subscribe: {
        color: '#fff',
        zIndex: 2,
        position: 'relative',
        fontWeight: 'bold',
        paddingTop: 16,
        paddingLeft: 36.5,
        borderRadius: 12,
        paddingRight: 36.5,
        paddingBottom: 16,
        textDecoration: 'none',
        backgroundColor: '#9F0000',
        "&:hover": {
            cursor: "pointer",
            color: '#FFF !important'
        }
    }
}));

function Footer() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
    }, [])

    return (
        <footer className={classes.footer}>
            <Grid
              justify="space-around"
              container 
              spacing={24}
              className={classes.row}
            >
                <div className={classes.col1}>
                    <Grid item>
                        <img 
                            className={classes.logoImage} 
                            src={logoimage} 
                            alt="Carcare" />
                        <img 
                            className={classes.logoText} 
                            src={logotext} 
                            alt="Carcare" />
                    </Grid> 
                    <div className={classes.col1Text}>
                        {/* Carcare is a mobile app and web platform that enables car owners to quickly book car washing and washing center owners to increase revenues by (...) */}
                    </div>
                    <div className={classes.socialMediaContainer}>
                        <Grid item>
                            <a href="https://www.facebook.com/Carcareappbh/" target="_blank">
                                <img 
                                    className={classes.socialMediaImage} 
                                    src={facebookimage} 
                                    alt="Facebook" />
                            </a>
                            <a href="https://www.instagram.com/carcareapp/" target="_blank">
                                <img 
                                    className={classes.socialMediaImage} 
                                    src={instagramimage} 
                                    alt="Instagram" />
                            </a>
                            <a href="https://twitter.com/carcare_" target="_blank">
                                <img 
                                    className={classes.socialMediaImage} 
                                    src={twitterimage} 
                                    alt="Twitter" />
                            </a>
                            {/* <img 
                                className={classes.socialMediaImage} 
                                src={linkedinimage} 
                                alt="LinkedIn" /> */}
                        </Grid> 
                    </div>
                </div>
                <div className={classes.col2}>
                    <div className={classes.colContent}>
                        <div className={classes.colHeader}>{t('footer.website')}</div>
                        <div className={classes.linkContainer}>
                            <a className={classes.link} href="#howitworks">{t('footer.howitworks')}</a>
                            <a className={classes.link} href="#ourservices">{t('landing.ourservices')}</a>
                            <a className={classes.link} href="/wash-centers">{t('landing.allwashcenters')}</a>
                            <a className={classes.link} href="#partnerwithus">{t('footer.partnerwithus')}</a>
                            <a className={classes.link} href="#contact">{t('footer.contact')}</a>
                        </div>
                    </div>
                </div>
                <div className={classes.col3}>
                    <div className={classes.colContent}>
                        <div className={classes.colHeader}>{t('footer.otherlinks')}</div>
                        <div className={classes.linkContainer}>
                            <a className={classes.link} href="/terms">{t('footer.termsofservice')}</a>
                            <a className={classes.link} href="/privacy-policy">{t('footer.privacypolicy')}</a>
                        </div>
                    </div>
                </div>
                <div className={classes.col4}>
                    <div className={classes.colContent}>
                        <div className={classes.colHeader}>{t('footer.newsletter')}</div>
                        <div>
                            <TextField id="email" className={classes.email} label={t('footer.email')} />
                            <div className={classes.subscribeContainer}>
                                <a className={classes.subscribe} href="#">{t('footer.subscribe')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
            <div className={classes.copyright}>© 2021 carcare. {t('footer.allrightsreserved')}</div>
        </footer>
    );
}

export default Footer;