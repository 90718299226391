import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API_URL } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import Title from '../components/Title';
import OtherTopMenu from '../components/OtherTopMenu';
import TextField from '@material-ui/core/TextField';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 48,
        paddingRight: 48,
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    submitContainer: {
        paddingTop: 60,
        float: 'right'
    },
    submitText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -60,
        width: 44
    },
    submitBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    submitBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: -11,
        marginTop: 6
    }
}));

const PartnerWithUs = () => {
    const classes = useStyles();
    const nameRef = useRef('');
    const centerNameRef = useRef('');
    const emailRef = useRef('');
    const phoneNumberRef = useRef('');
    const { t, i18n } = useTranslation();
    const language = localStorage.getItem('language');
    
    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
    }, [])

    function handleClick(e) {
        e.preventDefault();
    
        let name = nameRef.current.value;
        let centerName = centerNameRef.current.value;
        let email = emailRef.current.value;
        let phoneNumber = phoneNumberRef.current.value;

        if(name && centerName && email && phoneNumber) {
            axios.post(API_URL + `/user/partner`, { 
                name: name,
		        center_name: centerName,
		        email: email,
                phone_number: phoneNumber,
                created_by: 0
            })
            .then(res => {
                if(res.data && res.data.partner) {
                    nameRef.current.value = '';
                    centerNameRef.current.value = '';
                    emailRef.current.value = '';
                    phoneNumberRef.current.value = '';
                    toast.success(t('partnerwithus.wereceivedyourmessage'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(t('partnerwithus.error'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }  else {
            toast.error(t('partnerwithus.filluprequiredfields'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className='inner'>
            <OtherTopMenu />
            <Title lineContent={t('partnerwithus.partnerwithus')} lineContent2={t('partnerwithus.sendyourdetails')} />
            <div className={classes.fieldsContainer}>
                <ToastContainer />
                <form>
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="name"
                            label={t('partnerwithus.firstandlastname')}
                            className={classes.input}
                            placeholder={t('partnerwithus.firstandlastname')}
                            inputRef={nameRef}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="centerName"
                            label={t('partnerwithus.companyname')}
                            className={classes.input}
                            placeholder={t('partnerwithus.companyname')}
                            inputRef={centerNameRef}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="email"
                            label={t('partnerwithus.email')}
                            className={classes.input}
                            placeholder={t('partnerwithus.email')}
                            inputRef={emailRef}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="phoneNumber"
                            label={t('partnerwithus.phonenumber')}
                            className={classes.input}
                            placeholder={t('partnerwithus.phonenumber')}
                            inputRef={phoneNumberRef}
                        />
                    </div>
                    <div className={classes.submitContainer}>
                        <div className={classes.submitText}>{t('partnerwithus.submit')}</div>
                        <div 
                            onClick={handleClick}
                            className={classes.submitBtnContainer} 
                        >
                            <img 
                                className={classes.submitBtn} 
                                src={arrowrightwhiteimage} 
                                alt={t('partnerwithus.submit')} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PartnerWithUs;