import React, { useRef, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%'
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 65,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '25%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    selectService: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
}));

const AddPromoCode = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const roleId = localStorage.getItem('roleId');
    const promoCodeRef = useRef('');
    const descriptionRef = useRef('');
    const descriptionArRef = useRef('');
    const percentageRef = useRef('');
    const amountRef = useRef('');
    const limitRef = useRef('');
    let [business, setBusiness] = useState('');
    let [validFrom, setValidFrom] = useState(new Date());
    let [validTo, setValidTo] = useState(new Date());
    let [services, setServices] = useState('');
    let [service, setService] = useState('');
    const history = useHistory();
    const params = useParams();
    const { t, i18n } = useTranslation();
    let [washCenters, setWashCenters] = useState('');
    let [selectedWashCenter, setSelectedWashCenter] = useState('');

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        axios.get(API_URL + `/business/${id}`)
        .then(res => {
            let business = res.data.business;
            setBusiness(business);

            setValidFrom(moment(new Date()).format("YYYY-MM-DD"));
            setValidTo(moment(new Date()).add(7, 'days').format("YYYY-MM-DD"));

            axios.get(API_URL + `/business/all-services/${business.id}`)
                .then(response => {
                    let svcs = response.data.services;
                    if(svcs) {
                        svcs = svcs[0].services;
                        svcs = svcs.filter(s => {
                            return s.status == 1
                        })
                        svcs = svcs.sort((a, b) => {
                            if (a.service_name < b.service_name) {
                                return -1
                            }
                    
                            if (a.service_name > b.service_name) {
                                return 1
                            }
                            return 0
                        })
                    }
                    setServices(svcs)
                })

            axios.get(API_URL + `/admin-dashboard/wash-centers`)
                .then(response => {
                  let wash_centers = response.data.wash_centers;
                  setWashCenters(wash_centers);
                })
        })
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'validFrom': 
                setValidFrom(event.target.value);
                break;
            case 'validTo': 
                setValidTo(event.target.value);
                break;
        }
    };

    function handleClick(e) {
        let promoCode = promoCodeRef.current.value;
        let description = descriptionRef.current.value;
        let descriptionAr = descriptionArRef.current.value;
        let percentage = percentageRef.current.value;
        let amount = amountRef.current.value;
        let limit = limitRef.current.value ? limitRef.current.value : 0;
        
        if(promoCode && (description || descriptionAr) && (percentage || amount)) {
            if(validFrom) {
                if(validTo) {
                    addPromoCode(promoCode, description, descriptionAr, percentage, amount, limit);
                } else {
                    toast.error(t('promo-codes.fillup'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                addPromoCode(promoCode, description, descriptionAr, percentage, amount);
            }
        } else {
            toast.error(t('promo-codes.fillup'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function addPromoCode(promoCode, description, descriptionAr, percentage, amount, limit) {
        axios.post(API_URL + `/add-promo-code`, { 
            business_id: selectedWashCenter ? selectedWashCenter : 0,
            promo_code: promoCode,
            description,
            description_ar: descriptionAr,
            percentage,
            amount,
            valid_from: validFrom,
            valid_to: validTo,
            service_id: service,
            limit: limit ? limit : 0
        })
        .then(res => {
            if(res.status == 200) {
                history.push("/promo-codes")
            }
        })
    }

    function handleClose(e) {
        history.push("/promo-codes")
    }

    const handleServiceChange = (event) => {
        setService(event.target.value);
    };

    const handleWashCenterChange = (event) => {
        setSelectedWashCenter(event.target.value);

        // Update services
        let business_id = 0
        if(event.target.value > 0) {
            business_id = event.target.value
        }
        if(business_id > 0) {
            axios.get(API_URL + `/business/all-services/${business_id}`)
            .then(response => {
                let svcs = response.data.services;
                if(svcs && svcs[0]) {
                    svcs = svcs[0].services;
                    svcs = svcs.filter(s => {
                        return s.status == 1
                    })
                    svcs = svcs.sort((a, b) => {
                        if (a.service_name < b.service_name) {
                            return -1
                        }
                
                        if (a.service_name > b.service_name) {
                            return 1
                        }
                        return 0
                    })
                }
                setServices(svcs)
            })
        } else {
            setServices([])
        }
    };

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.center}>
                                <div className={classes.header}>
                                    <div className={classes.title}>{t('promo-codes.addpromocode')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <div className={classes.fieldsContainer}>
                                    <form>
                                        {roleId == 3 &&
                                            <div id="promo-service" className={classes.inputContainerDesc}>
                                                <div className={classes.serviceLabel}>{t('statement.washcenter')}</div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <NativeSelect
                                                        value={selectedWashCenter}
                                                        onChange={handleWashCenterChange}
                                                        className={classes.selectService}
                                                        input={<BootstrapInput />}
                                                    >
                                                        <option aria-label="None" value="0">{t('landing.allwashcenters')}</option>
                                                        {washCenters && washCenters.map((item) => (
                                                            <option value={item.id}>{item.business_name}</option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            </div>
                                        }
                                        <div className={classes.inputContainer}>
                                            <TextField 
                                                id="promoCode"
                                                label={t('promo-codes.promocode')} 
                                                className={classes.input}
                                                placeholder={t('promo-codes.promocode')}
                                                inputRef={promoCodeRef}
                                            />
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="description"
                                                label={t('services.description')} 
                                                multiline
                                                className={classes.input}
                                                placeholder={t('services.description')}
                                                inputRef={descriptionRef}
                                            />
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="descriptionAr"
                                                label={t('services.descriptionar')} 
                                                multiline
                                                className={classes.input}
                                                placeholder={t('services.descriptionar')}
                                                inputRef={descriptionArRef}
                                            />
                                        </div>
                                        <div className={classes.inputFlexContainer}>
                                            <TextField 
                                                id="percentage"
                                                label={t('promo-codes.percentage')} 
                                                className={classes.input}
                                                placeholder={t('promo-codes.percentage')}
                                                inputRef={percentageRef}
                                            />
                                            <TextField 
                                                id="amount"
                                                label={t('promo-codes.amount')} 
                                                className={classes.flexLastInput}
                                                placeholder={t('promo-codes.amount')}
                                                inputRef={amountRef}
                                            />
                                        </div>
                                        <div className={classes.inputFlexContainer}>
                                            <TextField 
                                                id="validFrom"
                                                type="date"
                                                label={t('promo-codes.validfrom')} 
                                                className={classes.input}
                                                placeholder={t('promo-codes.validfrom')}
                                                value={validFrom || ''}
                                                onChange={(event) => handleFieldChange(event, 'validFrom')}
                                            />
                                            <TextField 
                                                id="validTo"
                                                type="date"
                                                label={t('promo-codes.validto')} 
                                                className={classes.flexLastInput}
                                                placeholder={t('promo-codes.validto')}
                                                value={validTo || ''}
                                                onChange={(event) => handleFieldChange(event, 'validTo')}
                                            />
                                        </div>
                                        <div id="promo-service" className={classes.inputContainerDesc}>
                                            <div className={classes.serviceLabel}>{t('services.service')}</div>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <NativeSelect
                                                    value={service}
                                                    onChange={handleServiceChange}
                                                    className={classes.selectService}
                                                    input={<BootstrapInput />}
                                                >
                                                    <option aria-label="None" value="">{t('services.selectservice')}</option>
                                                    {services && services.map((item) => (
                                                        <option value={item.id}>{item.service_name}</option>
                                                    ))}
                                                </NativeSelect>
                                            </FormControl>
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="description"
                                                type="number"
                                                label={t('services.limitpercustomer')}
                                                className={classes.input}
                                                placeholder={t('services.limitpercustomer')}
                                                inputRef={limitRef}
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className={classes.saveContainer}>
                                    <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                    <div 
                                        onClick={handleClick}
                                        className={classes.saveBtnContainer} 
                                    >
                                        <img 
                                            className={classes.saveBtn} 
                                            src={arrowrightwhiteimage} 
                                            alt="Carcare" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default AddPromoCode;