import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    policyHeader: {
        paddingTop: 16
    },
    policyText: {
        paddingTop: 0,
        marginTop: 0,
        marginBottom: 10
    }
}));

const Terms = () => {
  const classes = useStyles();
  const id = localStorage.getItem('id');
  
  // if (!id) {
  //   return <Redirect to={'/login'} />;
  // } 

  return (
    <div className="inner">
      <LoggedInTopMenu />
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                    <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article} id="terms-container">
                    <p className={classes.policyText}>
                      These terms and conditions ("Agreement") sets forth the general terms and conditions of your use of the "CarCare" website ("Website" or "Service") and any of its related products and services (collectively, "Services"). This Agreement is legally binding between you ("User", "you" or "your") and Streamline for software solutions ("Streamline for software solutions", "we", "us" or "our"). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Website and Services. You acknowledge that this Agreement is a contract between you and Streamline for software solutions, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Booking terms and conditions</strong> 
                    </p>
                    <p className={classes.policyText}>
                      Please ensure that all valuable items such as bags, wallets money etc. are removed before service takes place. CarCare or its partners will not hold responsibility for any missing items.
                    </p>
                    <p className={classes.policyText}>
                      CarCare and its partners will not hold responsibility for any damages that occur while services are taking place. However, we will ensure that highest quality of service is being delivered. In the case of having clear evidence that quality of service delivered by the agent is poorly performed, CarCare at our own cost will redo or refund the poorly performed and unsatisfactory service. 
                    </p>
                    <p className={classes.policyText}>
                      CarCare and its partners have the right to modify/cancel any booking in case of not being able to deliver the service on that time. However, we will ensure that your service is carried out at the same time of your booking. In the case of any modification/cancellation to booking date and time you will be informed prior to your booking through any of the following communication channels: Email, SMS, Phone Call.
                    </p>
                    <p className={classes.policyText}>
                      In the case of modifying the desired service date and time, please give at least 4 hours window prior to the actual booking time or charges might apply.
                    </p>
                    <p className={classes.policyText}>
                      Refunds take up to 7 working days.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>CANCELLATION TERMS & POLICY</strong> 
                    </p>
                    <p className={classes.policyText}>
                      In the case of cancelling the desired booking please give at least 4 hours window prior to the actual booking time. However, if cancellation takes place within that period, 15% will be charged from the amount of actual booking. Refunds can take up to 7 working days.
                    </p>
                    <p className={classes.policyText}>
                      It is your responsibility to ensure that the intended vehicle is the service location at the booked time. Failure to be present at the booked location at the specified time can lead to taking full amount of service price.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Accounts and membership</strong> 
                    </p>
                    <p className={classes.policyText}>
                      If you create an account in the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Billing and payments</strong> 
                    </p>
                    <p className={classes.policyText}>
                      You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after the free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and the Website and Services are also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users. Scans for malware are performed on a regular basis for additional security and protection. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Accuracy of information</strong> 
                    </p>
                    <p className={classes.policyText}>
                      Occasionally there may be information in the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Website or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information in the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Website should be taken to indicate that all information in the Website or Services has been modified or updated.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Third party services</strong> 
                    </p>
                    <p className={classes.policyText}>
                      If you decide to enable, access or use third party services, be advised that your access and use of such other services are governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services. You irrevocably waive any claim against Streamline for software solutions with respect to such other services. Streamline for software solutions is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. You may be required to register for or log into such other services on their respective platforms. By enabling any other services, you are expressly permitting Streamline for software solutions to disclose your data as necessary to facilitate the use or enablement of such other service.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Links to other resources</strong> 
                    </p>
                    <p className={classes.policyText}>
                      Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some of the links in the Website may be "affiliate links". This means if you click on the link and purchase an item, Streamline for software solutions will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link in the Website and Services. Your linking to any other off-site resources is at your own risk.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Intellectual property rights</strong> 
                    </p>
                    <p className={classes.policyText}>
                      "Intellectual Property Rights" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by Streamline for software solutions or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Streamline for software solutions. All trademarks, service marks, graphics and logos used in connection with the Website and Services, are trademarks or registered trademarks of Streamline for software solutions or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Website and Services may be the trademarks of other third parties. Your use of the Website and Services grants you no right or license to reproduce or otherwise use any of Streamline for software solutions or third party trademarks.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Disclaimer of warranty</strong> 
                    </p>
                    <p className={classes.policyText}>
                      You agree that such Service is provided on an "as is" and "as available" basis and that your use of the Website and Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Limitation of liability</strong> 
                    </p>
                    <p className={classes.policyText}>
                      To the fullest extent permitted by applicable law, in no event will Streamline for software solutions, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Streamline for software solutions and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to Streamline for software solutions for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Indemnification</strong> 
                    </p>
                    <p className={classes.policyText}>
                      You agree to indemnify and hold Streamline for software solutions and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website and Services or any willful misconduct on your part.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Severability</strong> 
                    </p>
                    <p className={classes.policyText}>
                      All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Changes and amendments</strong> 
                    </p>
                    <p className={classes.policyText}>
                      We reserve the right to modify this Agreement or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Agreement in the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Acceptance of these terms</strong> 
                    </p>
                    <p className={classes.policyText}>
                      You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services.
                    </p>
                    <p className={classes.policyHeader}>
                      <strong>Contacting us</strong> 
                    </p>
                    <p className={classes.policyText}>
                      If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to <a href="mailto:info@carcareapp.org">info@carcareapp.org</a> or through our social media channels and WhatsApp <a href="https://wa.me/message/LVFL4ZA33OWAO1">https://wa.me/message/LVFL4ZA33OWAO1</a>. 
                    </p>
                </div>
            </div>
        </Grid>
      </div>
    </div>
  );
};
  
export default Terms;