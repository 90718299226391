import React, { useRef, useEffect, useState }  from "react";
import { makeStyles } from '@material-ui/core/styles';
import { API_URL } from '../constants';
import { Redirect, useParams, useHistory } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ReactLoading from 'react-loading';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    paymentContainer: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    paymentSuccess: {
        color: '#000',
        fontSize: 25,
        width: 200,
        margin: '0 auto'
    },
    check: {
        fontSize: 60,
        color: '#22bb33'
    },
    charge: {
        marginTop: 80,
        fontSize: 14
    },
    error: {
        fontSize: 60,
        color: '#ff3333'
    },
    paymentFailed: {
        color: '#000',
        fontSize: 25,
        width: 205,
        margin: '0 auto'
    },
    errorMessage: {
        marginTop: 80,
        fontSize: 14
    }
}));

const PaymentFail =() => {
    const classes = useStyles();
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [charge, setCharge] = useState(false);
    const params = useParams();
    const [requestId, setRequestId] = useState(params.id);
    const [paymentUnsuccessful, setPaymentUnsuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [washCenterId, setWashCenterId] = useState(0);
    const [referenceNumber, setReferenceNumber] = useState('');

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        // Get charge details
        setLoading(true);
        axios.get(API_URL + `/charge-details/${requestId}`)
        .then(response => {
            console.log(response);
            console.log(response.data.statusCode);
            if(response.data.statusCode == 200) {
                if(response.data.body.response.code == '000') {
                    // Amount got charged successfully
                    setLoading(false);
                    setCharge(requestId);
                    setPaymentSuccessful(true);
                } else {
                    setLoading(false);
                    setErrorMessage(response.data.body.response.message);
                    setPaymentUnsuccessful(true);
                }
            } else {
                setLoading(false);
                setErrorMessage('There was an error processing your payment');
                setPaymentUnsuccessful(true);
            }
            setWashCenterId(response.data.wash_center_id)
            setReferenceNumber(response.data.reference_number)
        })
    }, []);
    
    return (
        <div className={classes.paymentContainer}>
            {paymentSuccessful &&
                <div>
                    <CheckCircleIcon className={classes.check} />
                    <div className={classes.paymentSuccess}>Your payment was successful!</div>
                    <div className={classes.charge}><b>Charge ID:</b> {charge}</div>
                    <a href={`https://appcarcare.com/booking/booking-received/${referenceNumber}`} className="ActionButtonFail">OK</a>
                </div>
            }
            {paymentUnsuccessful &&
                <div>
                    <ErrorIcon className={classes.error} />
                    <div className={classes.paymentFailed}>Your payment was unsuccessful.</div>
                    <div className={classes.errorMessage}><b>Error:</b> {errorMessage}</div>
                    <a href={`https://appcarcare.com/booking/booking-failed/${referenceNumber}`} className="ActionButtonFail" style={{
                        height: 53,
                        display: 'block',
                        marginTop: 40,
                        padding: '15px 30px',
                        backgroundColor: '#9f0000',
                        borderRadius: 10,
                        color: '#fff',
                        textDecoration: 'none',
                        fontWeight: 'bold'
                    }}>OK</a>
                </div>
            }
            {loading && 
                <ReactLoading type={'bars'} color="#9f0000" />
            }
        </div>
    );
}

export default PaymentFail;