import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';
import ExportCSV from '../components/ExportCSV';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import starImage from '../images/star.png'; 
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Geocode from "react-geocode";
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Calendar from 'react-calendar-multiday';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: 130,
        marginLeft: '-6%'
    },
    sideMenu: {
        backgroundColor: '#9F0000',
        width: 256,
        height: `calc(100vh - 130px)`,
        borderTopRightRadius: 75,
        color: '#fff',
        paddingTop: 70
    },
    mainContent: {
        width: `calc(100% - 256px)`,
        textAlign: 'left',
        ['@media (max-width:959)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    article: {
        paddingTop: 20,
        paddingLeft: 50
    },
    pageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: 20
    },
    statementHeader: {
        paddingTop: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    indentedHeader: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
        paddingLeft: '3%',
        ['@media (max-width:959px)']: { 
          paddingLeft: 0
        },
        ['@media (min-width:959px)']: { 
          visibility: 'hidden'
        }
    },
    leftHeader: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
        ['@media (min-width:959px)']: { 
          visibility: 'hidden'
        }
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
    },
    history: {
        width: '100%'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    saveContainer: {
        marginLeft: 65,
        marginTop: 10
    },
    saveText: {
          color: '#9F0000',
          fontSize: 16,
          fontWeight: 500,
          display: 'inline-block',
          position: 'absolute',
          marginTop: 7,
          marginLeft: -62,
          width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
          cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: 7,
        marginTop: 6
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 20,
        width: '75%',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
        }
    },
    exportContainer: {
        paddingTop: 1,
        backgroundColor: '#F7F7F7',
        marginTop: 11,
        borderRadius: 8,
        border: `solid 1px #d3d3d3`,
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10
    },
    input: {
        width: 280,
        fontSize: 16
    },
    viewDetails: {
        fontSize: 12,
        color: '#f1a542',
        border: 'solid 1px #f1a542',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    addManualRating: {
        fontSize: 12,
        color: '#9f0000',
        border: 'solid 1px #9f0000',
        fontWeight: 'bold',
        width: 145,
        margin: '0 auto',
        marginTop: 10,
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    dialogTitle: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    dialogContent: {
        width: 480,
        marginTop: 0,
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    dialogUserContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    dialogUserImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogCarImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogUser: {
        textAlign: 'center'
    },
    dialogCar: {
        textAlign: 'center'
    },
    dialogFlexContainer: {
        display: 'flex'
    },
    dialogRow: {
        marginTop: 20
    },
    modalIconImage: {
        width: 36,
        height: 36
    },
    dialogRightText: {
        marginLeft: 20,
        paddingTop: 6,
        marginLeft: 20,
        paddingTop: 6,
        width: '85%'
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftColumn: {
        flex: '30%',
    },
    rightColumn: {
        width: '70%',
    },
    dialogFlexEndContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogRight: {
        position: 'absolute',
        right: 64,
        fontWeight: 'bold'
    },
    dialogRightLast: {
        position: 'absolute',
        right: 64,
        marginTop: 25,
        fontWeight: 'bold'
    },
    dialogBottomRow: {
        marginTop: 25
    },
    mapContainer: {
        height: 319, 
        width: '100%', 
        borderRadius: 40,
        marginTop: 20
    },
    mapIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    whatsappIcon: {
        color: '#075e54',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    emailIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    sectionTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 40,
        marginBottom: 20
    },
    serviceImage: {
        width: '100%',
        height: 250,
        marginBottom: 20,
        borderRadius: 10,
        objectFit: 'cover'
    },
    selectRating: {
        width: 315,
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
}));

const Statement = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const roleId = localStorage.getItem('roleId');
    const { t, i18n } = useTranslation();

    let [business, setBusiness] = useState('');
    let [statements, setStatements] = useState([]);
    let [allStatements, setAllStatements] = useState([]);
    let [exportStatements, setExportStatements] = useState([]);
    let [language, setLanguage] = useState('');
    let [startDate, setStartDate] = useState(new Date('2021-01-01'));
    let [endDate, setEndDate] = useState(new Date('2021-12-31'));
    let [searchKeyword, setSearchKeyword] = useState('');
    let [statement, setStatement] = useState({});
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('body');
    const [manualRatingOpen, setManualRatingOpen] = React.useState(false);
    const [rating, setRating] = useState('');
    let [feedback, setFeedback] = useState('');

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language)

        axios.get(API_URL + `/business-details/${id}`)
        .then(res => {
            let business = res.data.business ? res.data.business[0] : [];
            setBusiness(business);

            axios.get(API_URL + `/wash-center/statement`)
            .then(response => {
                let statements = response.data.statement;
                console.log('all: ' + statements)
                if(roleId != 3) {
                    console.log('business_id: ' + business.id)
                    statements = statements.filter(s => {
                        return s.business_id == business.id
                    })
                    console.log('business: ' + statements)
                }
                setStatements(statements)
                setAllStatements(statements)

                let export_statements = []
                if(statements) {
                    if(roleId == 3) {
                        statements.forEach(s => {
                            export_statements.push({
                                "transaction_date": s.display_date,
                                "wash_center": s.business_name,
                                "service": s.service,
                                "payment": s.payment_method_id == 1 ? 'Cash' : 'Card',
                                "amount": s.currency + ' ' + s.amount,
                                "reference_number": s.reference_number
                            })
                        });
                    } else {
                        statements.forEach(s => {
                            export_statements.push({
                                "transaction_date": s.display_date,
                                "service": s.service,
                                "payment": s.payment_method_id == 1 ? 'Cash' : 'Card',
                                "amount": s.currency + ' ' + s.amount,
                                "reference_number": s.reference_number
                            })
                        });
                    }
                }
                setExportStatements(export_statements)
            })
        })
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    }

    const handleDateChange = (date, type) => {
        switch(type) {
            case 'startDate': 
                setStartDate(date)
                break;
            case 'endDate': 
                setEndDate(date)
                break;
        }
    }

    function handleFilter(e) {
        e.preventDefault();

        setStatements([])
        let sts = allStatements

        let start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : '';
        let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : '';

        if(start_date && end_date) {
            sts = sts.filter(s => {
                let include = false

                if(moment(s.created_at).isBetween(startDate, endDate, 'days', true)) {
                    include = true
                }

                return include
            })
        }

        if(searchKeyword) {
            sts = sts.filter(s => {
                let include = false

                if(
                    (s.reference_number && searchKeyword &&
                        s.reference_number.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
                    ) || 
                    (s.first_name && searchKeyword &&
                        s.first_name.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
                    ) || 
                    (s.last_name && searchKeyword &&
                        s.last_name.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
                    ) 
                ) {
                    include = true
                }

                return include
            })
        }

        let export_statements = []
        if(sts) {
            if(roleId == 3) {
                sts.forEach(s => {
                    export_statements.push({
                        "transaction_date": s.display_date,
                        "wash_center": s.business_name,
                        "service": s.service,
                        "duration": s.duration_arrived + ', ' + s.duration_completed,
                        "payment": s.payment_method_id == 1 ? 'Cash' : 'Card',
                        "amount": s.currency + ' ' + s.amount,
                        "reference_number": s.reference_number
                    })
                });
            } else {
                sts.forEach(s => {
                    export_statements.push({
                        "transaction_date": s.display_date,
                        "service": s.service,
                        "duration": s.duration_arrived + ', ' + s.duration_completed,
                        "payment": s.payment_method_id == 1 ? 'Cash' : 'Card',
                        "amount": s.currency + ' ' + s.amount,
                        "reference_number": s.reference_number
                    })
                });
            }
        }
        setStatements(sts)
        setExportStatements(export_statements)
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'searchKeyword': 
                setSearchKeyword(event.target.value);
                break;
            case 'feedback': 
                setFeedback(event.target.value);
                break;
        }
    };

    const handleClickOpen = (scrollType, statement) => () => {
        setOpen(true)
        setScroll(scrollType)
        setStatement(statement)
    }

    const handleClose = () => {
        setOpen(false)
        setManualRatingOpen(false)
    }

    const handleManualRatingOpen = (scrollType, statement) => () => {
        console.log(statement)
        setManualRatingOpen(true)
        setScroll(scrollType)
        setStatement(statement)
    }

    const handleManualRatingClose = () => {
        setManualRatingOpen(false)
    }

    const handleRatingChange = (event) => {
        setRating(event.target.value);
    };

    function handleAddManualRating(e) {
        e.preventDefault();

        if(rating && parseInt(rating) > 0) {
            axios.post(API_URL + `/service/manual/rating/add`, {
                feedback: feedback,
                mode: 'feedback',
                created_by: id,
                rate: rating,
                reference_number: statement.reference_number
            })
            .then(res => {
                window.location.reload(true)
            })
        }
    }

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.pageHeader}>{t('statement.statement')}</div>
                            <div className={classes.filterContainer}>
                                <div>
                                    <TextField 
                                        id="searchKeyword"
                                        label={t('dashboard.enternamereference')} 
                                        className={classes.input}
                                        placeholder={t('dashboard.enternamereference')}
                                        value={searchKeyword || ''}
                                        onChange={(event) => handleFieldChange(event, 'searchKeyword')}
                                    />
                                </div>
                                <div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="MM/dd/yyyy"
                                            value={startDate}
                                            onChange={(date) => handleDateChange(date, 'startDate')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="MM/dd/yyyy"
                                            value={endDate}
                                            onChange={(date) => handleDateChange(date, 'endDate')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.saveContainer}>
                                    <div className={classes.saveText}>{t('statement.filter')}</div>
                                    <div 
                                        onClick={handleFilter}
                                        className={classes.saveBtnContainer} 
                                    >
                                        <img 
                                            className={classes.saveBtn} 
                                            src={arrowrightwhiteimage} 
                                            alt="Carcare" />
                                    </div>
                                </div>
                                <div className={classes.exportContainer}>
                                    <ExportCSV csvData={exportStatements} fileName={'statements'} />
                                </div>
                            </div>
                            <Table className={classes.history}>
                                <Thead>
                                    <Tr>
                                        <Th className={classes.header}>{t('statement.transactiondate')}</Th>
                                        {roleId == 3 &&
                                            <Th className={classes.header}>{t('statement.washcenter')}</Th>
                                        }
                                        <Th className={classes.header}>{t('statement.customer')}</Th>
                                        <Th className={classes.header}>{t('statement.service')}</Th>
                                        <Th className={classes.header}>{t('statement.duration')}</Th>
                                        <Th className={classes.header}>{t('statement.payment')}</Th>
                                        <Th className={classes.header}>{t('statement.amount')}</Th>
                                        <Th className={classes.header}>{t('statement.referencenumber')}</Th>
                                        <Th className={classes.header}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {statements && statements.map((statement, i) => (
                                        <Tr key={i + '-' + statement.reference_number}>
                                            <Td className={classes.left}>
                                                {statement.display_date}
                                            </Td>
                                            {roleId == 3 &&
                                                <Td className={classes.left}>
                                                    {language == 'ar' ? (statement.business_name_ar ? statement.business_name_ar : statement.business_name) : statement.business_name}
                                                </Td>
                                            }
                                            <Td className={classes.left}>
                                                { statement?.first_name ? (statement?.first_name + ' ' + (statement?.last_name && statement?.last_name != 'null' ? statement?.last_name : '' )) : statement?.email }
                                            </Td>
                                            <Td className={classes.left}>
                                                {language == 'ar' ? (statement.service_ar ? statement.service_ar : statement.service) : statement.service}
                                            </Td>
                                            <Td className={classes.left}>
                                                {statement.duration_arrived}
                                                <p>{statement.duration_completed}</p>
                                            </Td>
                                            {statement.manual_booking == 1 &&
                                                 <Td className={classes.left}>
                                                    {t('statement.manualbooking') + ' - ' + (statement.paid == 1 ? t('statement.paid') : t('statement.notpaid'))}
                                                    {statement.notes &&
                                                        <p style={{
                                                            color: '#000',
                                                            opacity: 0.5
                                                        }}>{statement.notes}</p>
                                                    }
                                                 </Td> 
                                            }
                                            {statement.manual_booking != 1 &&
                                                <Td className={classes.left}>
                                                    {statement.payment_method_id == 1 ? t('statement.cash') : t('statement.card')}
                                                </Td>
                                            }
                                            <Td className={classes.left}>
                                                {statement.currency + ' ' + statement.amount}
                                            </Td>
                                            <Td className={classes.left}>
                                                {statement.reference_number}
                                            </Td>
                                            <Td className={classes.center}>
                                                <div className={classes.viewDetails} onClick={handleClickOpen('body', statement)}>
                                                    {t('feedback.viewdetails')}
                                                </div>
                                                {roleId == 3 &&
                                                    <div className={classes.addManualRating} onClick={handleManualRatingOpen('body', statement)}>
                                                        {t('services.addmanualrating')}
                                                    </div>
                                                }
                                            </Td>  
                                        </Tr>
                                    ))}     
                                </Tbody>
                            </Table>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                maxWidth = {'sm'}
                            >
                                <DialogTitle id="scroll-dialog-title">
                                    <div className={classes.titleContainer}>
                                        <div className={classes.dialogTitle}>{t('feedback.outcallserviceinfo')}</div>
                                        <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                    </div>
                                </DialogTitle>
                                <DialogContent dividers={scroll === 'paper'}>
                                    <div className={classes.dialogContent}>
                                        <div className={classes.dialogUserContainer}>
                                            <div className={classes.dialogUser}>
                                                <div>
                                                    <img
                                                        className={classes.dialogUserImage}
                                                        src={API_URL + '/profile/image/' + (statement.image ? statement.image : 'default.png')} 
                                                    />
                                                </div>  
                                                <div className={classes.textCenter}>
                                                    {statement?.first_name ? (statement?.first_name + ' ' + (statement?.last_name && statement?.last_name != 'null' ? statement?.last_name : '' )) : statement?.email}
                                                </div>
                                                {statement?.phone_number &&
                                                    <div className={classes.textCenter}>
                                                        {statement?.phone_number ? statement?.phone_number : ''}
                                                    </div>
                                                }
                                            </div>
                                            <div className={classes.dialogCar}>
                                                <div>
                                                    <img
                                                        className={classes.dialogCarImage}
                                                        src={API_URL + '/car/type/image/' + statement.car_type_image} 
                                                    />
                                                </div>
                                                <div>
                                                    {statement.car_type}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dialogFlexContainer}>
                                                <img
                                                    className={classes.modalIconImage}
                                                    src={washmodalimage} 
                                                />
                                                <div className={classes.dialogRightText}> 
                                                    {statement.service}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dialogFlexContainer}>
                                                <div
                                                    style={{
                                                        width: 36,
                                                        height: 36,
                                                        borderRadius: 10,
                                                        backgroundColor: '#F7F7F7'
                                                    }}
                                                >
                                                    <AttachMoneyIcon style={{
                                                        fontSize: 23,
                                                        color: '#9F0000',
                                                        marginLeft: 7,
                                                        marginTop: 6
                                                    }} />
                                                </div>
                                                <div className={classes.dialogRightText}> 
                                                    {statement.currency} {statement.amount} {statement?.manual_booking == 1 ? 
                                                        (statement?.paid == 1 ? t('statement.paid') : t('statement.notpaid')) : 
                                                        (
                                                            statement.payment_method ? 
                                                                ('(' + statement.payment_method.substr(0, 4) + ')') : 
                                                                ''
                                                        )
                                                    } 
                                                    <div
                                                        style={{
                                                            width: '104%',
                                                            marginTop: 10
                                                        }}
                                                    >
                                                        <b>{t('feedback.referencenumber')}: </b>{statement.reference_number}
                                                    </div>
                                                    {statement?.invoice &&
                                                        <div
                                                            style={{
                                                                width: '104%',
                                                                marginTop: 10
                                                            }}
                                                        >
                                                            <b><a href={statement.invoice}>{t('statement.viewinvoice')}</a></b>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dialogFlexContainer}>
                                                <img
                                                    className={classes.modalIconImage}
                                                    src={pinmodalimage} 
                                                />
                                                <div className={classes.dialogRightText}> 
                                                    <span>{t('feedback.housebuilding')} {statement.building}, {t('feedback.road')} {statement.road}, {t('feedback.block')} {statement.block}, {statement.location}</span>
                                                </div>
                                            </div>    
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dialogFlexContainer}>
                                                <img
                                                    className={classes.modalIconImage}
                                                    src={calendarmodalimage} 
                                                />
                                                <div className={classes.dialogRightText}> 
                                                    <div className={classes.dialogFlexEndContainer}>
                                                        <div>{t('statement.bookingdate')}</div>
                                                        <div className={classes.dialogRight}>{statement.display_date}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dialogFlexContainer}>
                                                <img
                                                    className={classes.modalIconImage}
                                                    src={calendarmodalimage} 
                                                />
                                                <div className={classes.dialogRightText}> 
                                                    <div className={classes.dialogFlexEndContainer}>
                                                        <div>{t('statement.scheduleddate')}</div>
                                                        <div className={classes.dialogRight}>{statement.scheduled_date}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {statement?.service_images && statement?.service_images?.length > 0 &&
                                            <div className={classes.sectionTitleContainer}>
                                                <div className={classes.dialogTitle}>{t('statement.beforeafter')}</div>
                                            </div>
                                        }
                                        {statement?.service_images && statement?.service_images?.length > 0 && statement?.service_images.map((svc, x) => (
                                            <img
                                                className={classes.serviceImage}
                                                src={API_URL + '/business/image/' + svc.image} 
                                            />
                                        ))}
                                    </div>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                open={manualRatingOpen}
                                onClose={handleManualRatingClose}
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                maxWidth = {'sm'}
                                >
                                <DialogTitle id="scroll-dialog-title">
                                    <div className={classes.titleContainer}>
                                        <div className={classes.dialogTitle}>{t('services.addmanualrating')}</div>
                                        <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                    </div>
                                </DialogTitle>
                                <DialogContent dividers={scroll === 'paper'}>
                                    <div className={classes.dialogContent}>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dataRow}>    
                                                <div className={classes.leftColumn}>{t('notifications.referencenumber')}</div>
                                                <div className={classes.rightColumn}>
                                                    { statement?.reference_number }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dataRow}>    
                                                <div className={classes.leftColumn}>{t('services.service')}</div>
                                                <div className={classes.rightColumn}>
                                                {language == 'ar' ? (statement.service_ar ? statement.service_ar : statement.service) : statement.service}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dataRow}>    
                                                <div className={classes.leftColumn}>{t('customers.name')}</div>
                                                <div className={classes.rightColumn}>
                                                    { statement?.first_name ? (statement?.first_name + ' ' + (statement?.last_name && statement?.last_name != 'null' ? statement?.last_name : '' )) : statement?.email }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dataRow}>    
                                                <div className={classes.leftColumn}>{t('customers.email')}</div>
                                                <div className={classes.rightColumn}>
                                                    { statement?.email }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dataRow}>    
                                                <div className={classes.leftColumn}>{t('services.rating')}</div>
                                                <div className={classes.rightColumn}>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <Select
                                                            labelId="hours-select-outlined-label"
                                                            id="hours-select-outlined"
                                                            value={rating}
                                                            onChange={handleRatingChange}
                                                            className={classes.selectRating}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="">{t('services.selectrating')}</MenuItem>
                                                            <MenuItem value={1}>1</MenuItem>
                                                            <MenuItem value={2}>2</MenuItem>
                                                            <MenuItem value={3}>3</MenuItem>
                                                            <MenuItem value={4}>4</MenuItem>
                                                            <MenuItem value={5}>5</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div className={classes.dataRow}>    
                                                <div className={classes.leftColumn}>{t('side.feedback')}</div>
                                                <div className={classes.rightColumn}>
                                                    <TextField 
                                                        id="feedback"
                                                        label={t('side.feedback')}
                                                        multiline
                                                        className={classes.input}
                                                        value={feedback || ''}
                                                        onChange={(event) => handleFieldChange(event, 'feedback')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.dialogRow}>
                                            <div style={{
                                                float: 'right',
                                                marginRight: 20
                                            }}>
                                                <div className={classes.saveContainer}>
                                                    <div className={classes.saveText}>{t('dashboard.save')}</div>
                                                    <div 
                                                        onClick={handleAddManualRating}
                                                        className={classes.saveBtnContainer} 
                                                    >
                                                        <img 
                                                            className={classes.saveBtn} 
                                                            src={arrowrightwhiteimage} 
                                                            alt="Save" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                                </Dialog>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

export default Statement;