import React, { useRef, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'react-toastify/dist/ReactToastify.css';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      zIndex: 9999,
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDurationTo: {
        width: '100%',
        height: 72,
        ['@media (max-width:959px)']: { 
            marginTop: 100
        }
    },
    inputContainerDesc: {
        width: '100%'
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexServiceInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    durationLabel: {
        // paddingTop: 25,
        paddingBottom: 20,
        fontSize: 16,
        marginTop: 20,
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    durationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    selectDuration: {
        width: 280,
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '55%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    deleteContainer: {
        marginTop: 20,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '62%',
            marginLeft: 15
        }
    },
    offerFlex: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 10,
        marginTop: 15
    },
    priceFlex: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 25
    },
    flexInput: {
        fontSize: 16,
        width: '33%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '34%'
    },
    typeContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    typeContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    washTypeLabel: {
        // width: '32%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    typeContainer: {
        width: '65%',
        display: 'inline',
        marginTop: 10,
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    monthlyPackagesContainer: {
        width: '100%',
        height: 72,
        width: '30%',
        display: 'inline-block',
        marginLeft: 20,
        position: 'absolute',
        marginTop: -12,
        ['@media (max-width:959px)']: { 
            display: 'block',
            width: '100%',
            position: 'relative',
            marginLeft: 0
        }
    },
    monthlyPackagesContainer2: {
        width: '100%',
        height: 72,
        width: '30%',
        display: 'inline-block',
        marginLeft: 220,
        position: 'absolute',
        marginTop: -12,
        ['@media (max-width:959px)']: { 
            display: 'block',
            width: '100%',
            position: 'relative',
            marginLeft: 0
        }
    }
}));

const EditService = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    let [service, setService] = useState('');
    let [serviceName, setServiceName] = useState('');
    let [serviceNameAr, setServiceNameAr] = useState('');
    let [businessId, setBusinessId] = useState(0);
    let [price, setPrice] = useState('');
    let [smallPrice, setSmallPrice] = useState('');
    let [mediumPrice, setMediumPrice] = useState('');
    let [largePrice, setLargePrice] = useState('');
    let [motorbikePrice, setMotorbikePrice] = useState('');
    let [buildingSmallPrice, setBuildingSmallPrice] = useState('');
    let [buildingMediumPrice, setBuildingMediumPrice] = useState('');
    let [buildingLargePrice, setBuildingLargePrice] = useState('');
    let [description, setDescription] = useState('');
    let [descriptionAr, setDescriptionAr] = useState('');
    let [daysPerMonth, setDaysPerMonth] = useState('');
    let [categoryName, setCategoryName] = useState('');
    let [categoryNameAr, setCategoryNameAr] = useState('');
    let [percentOffer, setPercentOffer] = useState('');
    let [durationDays, setDurationDays] = useState('');
    let [durationHours, setDurationHours] = useState('');
    let [buildingSetting, setBuildingSetting] = useState(false);
    const [days, setDays] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [hoursTo, setHoursTo] = useState('');
    const [minutesTo, setMinutesTo] = useState('');
    const [showMore, setShowMore] = useState(false);
    const history = useHistory();
    const params = useParams();
    const [selectedTypeValue, setSelectedTypeValue] = React.useState('Wash Types');
    const [otherServices, setOtherServices] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        if(params && params.id && params.type) {
            axios.get(API_URL + `/wash-center/service/${params.id}/${params.type}`)
            .then(res => {
                let service = res.data.service;
                setService(service);
                setServiceName(service.name);
                setServiceNameAr(service.name_ar);
                setBusinessId(service.business_id);
                setSmallPrice(service.small_price);
                setMediumPrice(service.medium_price);
                setLargePrice(service.large_price);
                setMotorbikePrice(service.motorbike_price);
                setBuildingSmallPrice(service.building_small_price);
                setBuildingMediumPrice(service.building_medium_price);
                setBuildingLargePrice(service.building_large_price);
                setDescription(service.description);
                setDescriptionAr(service.description_ar);
                setDaysPerMonth(service.times);
                setShowMore(service.show_all);
                setPercentOffer(service.offer_percent);
                setDurationDays(service.offer_duration_days);
                setDurationHours(service.offer_duration_hours);
                let type = 'Wash Types';
                if(service.times) {
                    type = 'Monthly Packages';
                } else if(service.others == 1) {
                    type = 'Others';
                } else if(service.category) {
                    type = service.category;
                }
                setSelectedTypeValue(type);

                if(service.unit == 'day') {
                    setDays(service.duration);
                } else if(service.unit == 'hr') {
                    setHours(service.duration);
                } else if(service.unit == 'min') {
                    setMinutes(service.duration);
                }

                if(service.unit_to == 'day') {
                    setDays(service.duration_to);
                } else if(service.unit_to == 'hr') {
                    setHoursTo(service.duration_to);
                } else if(service.unit_to == 'min') {
                    setMinutesTo(service.duration_to);
                }

                axios.get(API_URL + `/business/other-services/${service.business_id}/${params.id}`)
                .then(resp => {
                    setOtherServices(resp.data.other_services)
                })

                axios.get(API_URL + `/wash-center/building-settings/${service.business_id}`)
                .then(rspns => {
                    let settings =rspns.data.wash_center_building_settings
                    if(settings && settings[0]) {
                        setBuildingSetting(settings[0].enabled);
                    }
                })
            })
        }
    }, [])

    const handleHoursChange = (event) => {
        setHours(event.target.value);
    };

    const handleMinutesChange = (event) => {
        setMinutes(event.target.value);
    };

    const handleHoursToChange = (event) => {
        setHoursTo(event.target.value);
    };

    const handleMinutesToChange = (event) => {
        setMinutesTo(event.target.value);
    };
  
    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    function handleClick(e) {
        let duration;
        let unit;
        let duration_to;
        let unit_to;
        if(days) {
            duration = days;
            unit = 'day';
        } else if(hours) {
            duration = hours;
            unit = 'hr';
        }  else if(minutes) {
            duration = minutes;
            unit = 'min';
        }

        if(hoursTo) {
            duration_to = hoursTo;
            unit_to = 'hr';
        }  else if(minutesTo) {
            duration_to = minutesTo;
            unit_to = 'min';
        }

        if(serviceName && smallPrice && mediumPrice && largePrice && description && selectedTypeValue && (days || hours || minutes)) {
            axios.post(API_URL + `/wash-center/service/edit/${params.id}/${params.type}`, { 
                business_id: service.business_id,
                name: serviceName,
                name_ar: serviceNameAr,
                description,
                description_ar: descriptionAr,
                small_price: smallPrice,
                medium_price: mediumPrice,
                large_price: largePrice,
                building_small_price: buildingSmallPrice,
                building_medium_price: buildingMediumPrice,
                building_large_price: buildingLargePrice,
                motorbike_price: motorbikePrice,
                outcall_type: selectedTypeValue,
                duration,
                unit,
                duration_to,
                unit_to,
                times: daysPerMonth,
                service_type: selectedTypeValue,
                category: categoryName ? categoryName : (selectedTypeValue != 'Wash Types' && selectedTypeValue != 'Monthly Packages' && selectedTypeValue != 'New Category' ? selectedTypeValue : ''),
                category_ar: categoryNameAr,
                show_all: showMore ? 1 : 0,
                offer_percent: percentOffer,
                offer_duration_days: durationDays,
                offer_duration_hours: durationHours
            })
            .then(res => {
                if(res.status == 200) {
                    history.push("/services")
                }
            })
        } else {
            toast.error(t('services.fillup'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function handleClose(e) {
        history.push("/services")
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'serviceName': 
                setServiceName(event.target.value);
                break;
            case 'serviceNameAr': 
                setServiceNameAr(event.target.value);
                break;
            case 'small_price':
                setSmallPrice(event.target.value);
                break;
            case 'medium_price':
                setMediumPrice(event.target.value);
                break;
            case 'large_price':
                setLargePrice(event.target.value);
                break;
            case 'motorbike_price':
                setMotorbikePrice(event.target.value);
                break;
            case 'building_small_price':
                setBuildingSmallPrice(event.target.value);
                break;
            case 'building_medium_price':
                setBuildingMediumPrice(event.target.value);
                break;
            case 'building_large_price':
                setBuildingLargePrice(event.target.value);
                break;
            case 'description': 
                setDescription(event.target.value);
                break;
            case 'descriptionAr': 
                setDescriptionAr(event.target.value);
                break;
            case 'daysPerMonth': 
                setDaysPerMonth(event.target.value);
                break;
            case 'categoryName': 
                setCategoryName(event.target.value);
                break;
            case 'categoryNameAr': 
                setCategoryNameAr(event.target.value);
                break;
            case 'percentOffer':
                setPercentOffer(event.target.value);
                break;
            case 'durationDays':
                setDurationDays(event.target.value);
                break;
            case 'durationHours':
                setDurationHours(event.target.value);
                break;
        }
    };

    function handleDelete(e) {
        if(params.id && params.type) {
            axios.post(API_URL + `/business/service/delete/${params.id}/${params.type}`, {})
            .then(res => {
                if(res.status == 200) {
                    history.push("/services")
                }
            })
        } else {
            toast.error(t('services.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleTypeChange = (event) => {
        setSelectedTypeValue(event.target.value);
    };

    const handleShowMoreChange = (event) => {
        switch(event.target.name) {
            case 'showMore': 
                setShowMore(event.target.checked);
                break;
        }
    };

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
                <Hidden smDown>
                    <div className={classes.sideMenu}>
                        <SideMenu />
                    </div>
                </Hidden>
                <ToastContainer />
                <div className={classes.mainContent}>
                    <div className={classes.article}>
                        <div className={classes.center}>
                            <div className={classes.header}>
                                <div className={classes.title}>{t('services.editservice')}</div>
                                <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                            </div>
                            <div className={classes.fieldsContainer}>
                                <form>
                                    <div className={classes.inputFlexContainer}>
                                        <TextField 
                                            id="serviceName"
                                            label={t('services.servicename')} 
                                            className={classes.input}
                                            placeholder={t('services.servicename')}
                                            value={serviceName || ''}
                                            onChange={(event) => handleFieldChange(event, 'serviceName')}
                                        />
                                        <TextField 
                                            id="serviceNameAr"
                                            label={t('services.servicenamear')} 
                                            className={classes.flexServiceInput}
                                            placeholder={t('services.servicenamear')}
                                            value={serviceNameAr || ''}
                                            onChange={(event) => handleFieldChange(event, 'serviceNameAr')}
                                        />
                                    </div>
                                    <div className={classes.priceFlex}>
                                        <TextField 
                                            id="smallprice"
                                            label={t('services.pricesmall')} 
                                            className={classes.flexInput}
                                            placeholder={t('services.pricesmall')}
                                            value={smallPrice || ''}
                                            onChange={(event) => handleFieldChange(event, 'small_price')}
                                        />
                                        <TextField 
                                            id="mediumprice"
                                            label={t('services.pricemedium')} 
                                            className={classes.flexInput}
                                            placeholder={t('services.pricemedium')}
                                            value={mediumPrice || ''}
                                            onChange={(event) => handleFieldChange(event, 'medium_price')}
                                        />
                                        <TextField 
                                            id="largeprice"
                                            label={t('services.pricelarge')} 
                                            className={classes.flexInputLast}
                                            placeholder={t('services.pricelarge')}
                                            value={largePrice || ''}
                                            onChange={(event) => handleFieldChange(event, 'large_price')}
                                        />
                                        {/* <TextField 
                                            id="motorbikeprice"
                                            label={t('services.pricemotorbike')} 
                                            className={classes.flexInputLast}
                                            placeholder={t('services.pricemotorbike')}
                                            value={motorbikePrice || ''}
                                            onChange={(event) => handleFieldChange(event, 'motorbike_price')}
                                        /> */}
                                    </div>
                                    {buildingSetting == 1 &&
                                    <div className={classes.priceFlex}>
                                        <TextField 
                                            id="buildingsmallprice"
                                            label={t('services.buildingpricesmall')} 
                                            className={classes.flexInput}
                                            placeholder={t('services.buildingpricesmall')}
                                            value={buildingSmallPrice || ''}
                                            onChange={(event) => handleFieldChange(event, 'building_small_price')}
                                        />
                                        <TextField 
                                            id="buildingmediumprice"
                                            label={t('services.buildingpricemedium')} 
                                            className={classes.flexInput}
                                            placeholder={t('services.buildingpricemedium')}
                                            value={buildingMediumPrice || ''}
                                            onChange={(event) => handleFieldChange(event, 'building_medium_price')}
                                        />
                                        <TextField 
                                            id="buildinglargeprice"
                                            label={t('services.buildingpricelarge')} 
                                            className={classes.flexInputLast}
                                            placeholder={t('services.buildingpricelarge')}
                                            value={buildingLargePrice || ''}
                                            onChange={(event) => handleFieldChange(event, 'building_large_price')}
                                        />
                                    </div>
                                    }
                                    <div className={classes.inputContainerDesc}>
                                        <TextField 
                                            id="description"
                                            label={t('services.description')} 
                                            multiline
                                            className={classes.input}
                                            placeholder={t('services.description')}
                                            value={description || ''}
                                            onChange={(event) => handleFieldChange(event, 'description')}
                                        />
                                    </div>
                                    <div className={classes.inputContainerDesc}>
                                        <TextField 
                                            id="descriptionAr"
                                            label={t('services.descriptionar')} 
                                            multiline
                                            className={classes.input}
                                            placeholder={t('services.descriptionar')}
                                            value={descriptionAr || ''}
                                            onChange={(event) => handleFieldChange(event, 'descriptionAr')}
                                        />
                                    </div>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={showMore} 
                                                    onChange={handleShowMoreChange} 
                                                    name="showMore" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label={t('services.showmorebutton')}
                                        />
                                    </div>
                                    <div style={{
                                        marginTop: 20
                                    }}>
                                        <div className={classes.typeContainer}>
                                            <FormControlLabel 
                                                className={classes.washTypeLabel}
                                                value="end"
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'Wash Types'}
                                                    onChange={handleTypeChange}
                                                    value="Wash Types"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'Wash Types' }} />} 
                                                label={t('services.washtypes')} />
                                            <FormControlLabel 
                                                className={classes.washTypeLabel}
                                                value="end" 
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'Monthly Packages'}
                                                    onChange={handleTypeChange}
                                                    value="Monthly Packages"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'Monthly Packages' }} />} 
                                                label={t('services.monthlypackages')} />
                                            <FormControlLabel 
                                                className={classes.washTypeLabel}
                                                value="end" 
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'Others'}
                                                    onChange={handleTypeChange}
                                                    value="Others"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'Others' }} />} 
                                                label={t('services.others')} />
                                            {otherServices && otherServices.map((otherService) => (
                                                <FormControlLabel 
                                                    className={classes.washTypeLabel}
                                                    value="end" 
                                                    control={<Radio 
                                                        checked={selectedTypeValue == otherService.category}
                                                        onChange={handleTypeChange}
                                                        value={otherService.category}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': otherService.category }} />} 
                                                    label={localStorage.getItem('language') == 'ar' ? (otherService.category_ar ? otherService.category_ar : otherService.category) : otherService.category} />
                                            ))}
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div className={classes.typeContainer}>
                                            <FormControlLabel value="end" 
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'New Category'}
                                                    onChange={handleTypeChange}
                                                    value="New Category"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'New Category' }} />} 
                                                label={t('services.newcategory')} />
                                            {selectedTypeValue === 'New Category' &&
                                                <>
                                                    <div className={classes.monthlyPackagesContainer}>
                                                        <TextField 
                                                            id="categoryName"
                                                            label={t('services.categoryname')} 
                                                            className={classes.input}
                                                            placeholder={t('services.categoryname')}
                                                            value={categoryName || ''}
                                                            onChange={(event) => handleFieldChange(event, 'categoryName')}
                                                        />
                                                    </div>
                                                    <div className={classes.monthlyPackagesContainer2}>
                                                        <TextField 
                                                            id="categoryNameAr"
                                                            label={t('services.categorynamear')} 
                                                            className={classes.input}
                                                            placeholder={t('services.categorynamear')}
                                                            value={categoryNameAr || ''}
                                                            onChange={(event) => handleFieldChange(event, 'categoryNameAr')}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {selectedTypeValue === 'Monthly Packages' &&
                                        <div className={classes.inputContainer}>
                                            <TextField 
                                                id="daysPerMonth"
                                                label={t('services.dayspermonth')} 
                                                className={classes.input}
                                                placeholder={t('services.dayspermonth')}
                                                value={daysPerMonth || ''}
                                                onChange={(event) => handleFieldChange(event, 'daysPerMonth')}
                                            />
                                        </div>
                                    }
                                    <div className={classes.inputContainer}>
                                        <div className={classes.durationLabel}>{t('services.durationfrom')}</div>
                                        <div className={classes.durationContainer}>
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    {/* <InputLabel htmlFor="demo-customized-select-native">{t('services.durationfrom')}</InputLabel> */}
                                                    <NativeSelect
                                                        id="hours-select-outlined"
                                                        value={hours}
                                                        onChange={handleHoursChange}
                                                        className={classes.selectDuration}
                                                        input={<BootstrapInput />}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={1}>1 hour</option>
                                                        <option value={2}>2 hours</option>
                                                        <option value={3}>3 hours</option>
                                                        <option value={4}>4 hours</option>
                                                        <option value={5}>5 hours</option>
                                                        <option value={6}>6 hours</option>
                                                        <option value={7}>7 hours</option>
                                                    </NativeSelect>
                                                    {/* <Select
                                                        labelId="hours-select-outlined-label"
                                                        id="hours-select-outlined"
                                                        value={hours}
                                                        onChange={handleHoursChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 hour</MenuItem>
                                                        <MenuItem value={1}>1 hour</MenuItem>
                                                        <MenuItem value={2}>2 hours</MenuItem>
                                                        <MenuItem value={3}>3 hours</MenuItem>
                                                        <MenuItem value={4}>4 hours</MenuItem>
                                                        <MenuItem value={5}>5 hours</MenuItem>
                                                        <MenuItem value={6}>6 hours</MenuItem>
                                                        <MenuItem value={7}>7 hours</MenuItem>
                                                    </Select> */}
                                                </FormControl>
                                            </div>
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    {/* <InputLabel htmlFor="demo-customized-select-native">{t('services.durationfrom')}</InputLabel> */}
                                                    <NativeSelect
                                                        id="minutes-select-outlined"
                                                        value={minutes}
                                                        onChange={handleMinutesChange}
                                                        className={classes.selectDuration}
                                                        input={<BootstrapInput />}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={15}>15 minutes</option>
                                                        <option value={30}>30 minutes</option>
                                                        <option value={45}>45 minutes</option>
                                                    </NativeSelect>
                                                    {/* <Select
                                                        labelId="minutes-select-outlined-label"
                                                        id="minutes-select-outlined"
                                                        value={minutes}
                                                        onChange={handleMinutesChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 minute</MenuItem>
                                                        <MenuItem value={15}>15 minutes</MenuItem>
                                                        <MenuItem value={30}>30 minutes</MenuItem>
                                                        <MenuItem value={45}>45 minutes</MenuItem>
                                                    </Select> */}
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.inputContainerDurationTo}>
                                        <div className={classes.durationLabel}>{t('services.durationto')}</div>
                                        <div className={classes.durationContainer}>
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    {/* <Select
                                                        labelId="hours-select-outlined-label"
                                                        id="hours-select-outlined"
                                                        value={hoursTo}
                                                        onChange={handleHoursToChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 hour</MenuItem>
                                                        <MenuItem value={1}>1 hour</MenuItem>
                                                        <MenuItem value={2}>2 hours</MenuItem>
                                                        <MenuItem value={3}>3 hours</MenuItem>
                                                        <MenuItem value={4}>4 hours</MenuItem>
                                                        <MenuItem value={5}>5 hours</MenuItem>
                                                        <MenuItem value={6}>6 hours</MenuItem>
                                                        <MenuItem value={7}>7 hours</MenuItem>
                                                    </Select> */}
                                                    <NativeSelect
                                                        id="hours-select-outlined"
                                                        value={hoursTo}
                                                        onChange={handleHoursToChange}
                                                        className={classes.selectDuration}
                                                        input={<BootstrapInput />}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={1}>1 hour</option>
                                                        <option value={2}>2 hours</option>
                                                        <option value={3}>3 hours</option>
                                                        <option value={4}>4 hours</option>
                                                        <option value={5}>5 hours</option>
                                                        <option value={6}>6 hours</option>
                                                        <option value={7}>7 hours</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    {/* <Select
                                                        labelId="minutes-select-outlined-label"
                                                        id="minutes-select-outlined"
                                                        value={minutesTo}
                                                        onChange={handleMinutesToChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 minute</MenuItem>
                                                        <MenuItem value={15}>15 minutes</MenuItem>
                                                        <MenuItem value={30}>30 minutes</MenuItem>
                                                        <MenuItem value={45}>45 minutes</MenuItem>
                                                    </Select> */}
                                                    <NativeSelect
                                                        id="minutes-select-outlined"
                                                        value={minutesTo}
                                                        onChange={handleMinutesToChange}
                                                        className={classes.selectDuration}
                                                        input={<BootstrapInput />}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={15}>15 minutes</option>
                                                        <option value={30}>30 minutes</option>
                                                        <option value={45}>45 minutes</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.offerFlex}>
                                        <TextField 
                                            id="percentOffer"
                                            label={t('services.percentoffer')} 
                                            className={classes.flexInput}
                                            placeholder={t('services.percentoffer')}
                                            value={percentOffer || ''}
                                            onChange={(event) => handleFieldChange(event, 'percentOffer')}
                                        />
                                        <TextField 
                                            id="durationDays"
                                            label={t('services.durationdays')} 
                                            className={classes.flexInput}
                                            placeholder={t('services.durationdays')}
                                            value={durationDays || ''}
                                            onChange={(event) => handleFieldChange(event, 'durationDays')}
                                        />
                                        <TextField 
                                            id="durationHours"
                                            label={t('services.durationhours')} 
                                            className={classes.flexInputLast}
                                            placeholder={t('services.durationhours')}
                                            value={durationHours || ''}
                                            onChange={(event) => handleFieldChange(event, 'durationHours')}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className={classes.wrapper}>
                                <div 
                                    className={classes.deleteContainer}
                                    onClick={handleDelete}
                                >
                                    {t('services.delete')}
                                </div>
                                <div className={classes.saveContainer}>
                                    <div className={classes.saveText}>{t('services.save')}</div>
                                    <div 
                                        onClick={handleClick}
                                        className={classes.saveBtnContainer} 
                                    >
                                        <img 
                                            className={classes.saveBtn} 
                                            src={arrowrightwhiteimage} 
                                            alt="Carcare" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default EditService;