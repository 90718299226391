import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Masonry } from "masonic";
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import WashCenterTopMenu from '../components/WashCenterTopMenu';
import BackToTop from "react-back-to-top-button";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import serviceimages from "../data/serviceimages";

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    scrollUp: {
      backgroundColor: '#9F0000'
    },
    container: {
        width: '100%',
        maxWidth: 800,
        padding: 8,
        margin: `163px auto 0`,
        boxSizing: 'border-box'
    },
    header1: {
        textAlign: 'left',
        marginBottom: 0
    },
    header4: {
        textAlign: 'left',
        marginBottom: 0,
        marginTop: 0,
        ['@media (max-width:768px)']: { 
          width: 130,
          wordBreak: 'break-word'
        }
    }
}));

const WashCenter = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const params = useParams();
    let [business, setBusiness] = useState('');
    let [services, setServices] = useState('');

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }

        if(params && params.id) {
            axios.get(API_URL + `/business-detail/${params.id}`)
            .then(res => {
                let business = res.data.business;
                setBusiness(business);
            })

            axios.get(API_URL + `/business/all-services/${params.id}`)
            .then(res => {
                let services = res.data.services;
                let svcs = services[0].services;
                if(svcs) {
                    svcs.forEach(service => {
                        service.description = randomChoice(serviceimages);
                    })
                }
                setServices(svcs);
            })
        }
      }, [])

    return (
        <div className={classes.root}>
            <WashCenterTopMenu />
            <main className={classes.container}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 60
                    }}
                >
                    <img 
                        style={{
                            width: 150,
                            display: 'block',
                            borderTopLeftRadius: `0.5rem`,
                            borderTopRightRadius: `0.5rem`,
                            display: 'block',
                            borderRadius: '50%'
                        }} 
                        alt="carcare" 
                        src={API_URL + `/business/image/${business?.image}`} 
                    />
                    <div
                        style={{
                            marginLeft: '15%'
                        }}
                    >
                        <h1 className={classes.header1}>
                            {business?.business_name}
                        </h1>
                        <h4 className={classes.header4}>
                            {business?.business_name_ar}
                        </h4>
                        <h4 className={classes.header4}>
                            {business?.email}
                        </h4>
                    </div>
                </div>
                <div className={classes.masonic}>
                    {services && services.length > 0 &&
                        <Masonry
                            items={services}
                            columnGutter={8}
                            columnWidth={200}
                            overscanBy={3}
                            render={ServiceCard}
                        />
                    }
                </div>
            </main>
        </div>
    );
}

const ServiceCard = ({ data: { id, service_name, description } }) => (
    <div className={'service-card'}>
        <img 
            style={{
                width: '100%',
                display: 'block',
                borderTopLeftRadius: `0.5rem`,
                borderTopRightRadius: `0.5rem`,
                display: 'block'
            }} 
            alt="carcare" 
            src={description}
        />
      <span children={service_name} />
    </div>
);

const randomChoice = (items) => items[Math.floor(Math.random() * items.length)];

export default WashCenter;