import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import { gsap } from "gsap";
import './App.scss';
import { withTranslation, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import Home from "./pages/Home";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Metrics from "./pages/Metrics";
import AdminMetrics from "./pages/AdminMetrics";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Support from "./pages/Support";
import NotFound from './pages/NotFound';
import PartnerWithUs from './pages/PartnerWithUs';
import Profile from './pages/Profile';
import History from './pages/History';
import ForgotPassword from './pages/ForgotPassword';
import Services from './pages/Services';
import AddService from './pages/AddService';
import EditService from './pages/EditService';
import Register from './pages/Register';
import Payment from './pages/Payment';
import PaymentFail from './pages/PaymentFail';
import PaymentSuccess from './pages/PaymentSuccess';
import Feedback from './pages/Feedback';
import Notifications from './pages/Notifications';
import Notification from './pages/Notification';
import Admin from "./pages/Admin";
import AdminProfile from './pages/AdminProfile';
import Pay from './pages/Pay';
import Customers from './pages/Customers';
import PromoCodes from './pages/PromoCodes';
import AddPromoCode from './pages/AddPromoCode';
import EditPromoCode from './pages/EditPromoCode';
import WashCenters from './pages/WashCenters';
import WashCenter from './pages/WashCenter';
import Statement from './pages/Statement';
import AddBooking from './pages/AddBooking';
import TapResponse from './pages/TapResponse';

const routes = [
  {
    path: "/", 
    name: "Home",
    Component: Home
  },
  {
    path: "/login", 
    name: "Login",
    Component: Login
  },
  {
    path: "/dashboard", 
    name: "Dashboard",
    Component: Dashboard
  },
  {
    path: "/metrics", 
    name: "Metrics",
    Component: Metrics
  },
  {
    path: "/privacy-policy", 
    name: "PrivacyPolicy",
    Component: PrivacyPolicy
  },
  {
    path: "/terms", 
    name: "Terms",
    Component: Terms
  },
  {
    path: "/support", 
    name: "Support",
    Component: Support
  },
  {
    path: "/partner-with-us", 
    name: "PartnerWithUs",
    Component: PartnerWithUs
  },
  {
    path: "/profile", 
    name: "Profile",
    Component: Profile
  },
  {
    path: "/history", 
    name: "History",
    Component: History
  },
  {
    path: "/forgot-password", 
    name: "forgot-password",
    Component: ForgotPassword
  },
  {
    path: "/services", 
    name: "services",
    Component: Services
  },
  {
    path: "/add-service/:type", 
    name: "add-service",
    Component: AddService
  },
  {
    path: "/edit-service/:id/:type", 
    name: "edit-service",
    Component: EditService
  },
  {
    path: "/register", 
    name: "Register",
    Component: Register
  },
  {
    path: "/not-found", 
    name: "NotFound",
    Component: NotFound
  },
  {
    path: "/payment/:id", 
    name: "Payment",
    Component: Payment
  },
  {
    path: "/payment-fail/:id", 
    name: "PaymentFail",
    Component: PaymentFail
  },
  {
    path: "/payment-success/:id", 
    name: "PaymentSuccess",
    Component: PaymentSuccess
  },
  {
    path: "/feedback", 
    name: "Feedback",
    Component: Feedback
  },
  {
    path: "/notifications", 
    name: "Notifications",
    Component: Notifications
  },
  {
    path: "/notification/:id", 
    name: "Notification",
    Component: Notification
  },
  {
    path: "/admin", 
    name: "Admin",
    Component: Admin
  },
  {
    path: "/admin-profile", 
    name: "Admin Profile",
    Component: AdminProfile
  },
  {
    path: "/pay/:id", 
    name: "Pay",
    Component: Pay
  },
  {
    path: "/customers", 
    name: "Customers",
    Component: Customers
  },
  {
    path: "/admin-metrics", 
    name: "AdminMetrics",
    Component: AdminMetrics
  },
  {
    path: "/promo-codes", 
    name: "PromoCodes",
    Component: PromoCodes
  },
  {
    path: "/add-promo-code", 
    name: "AddPromoCode",
    Component: AddPromoCode
  },
  {
    path: "/edit-promo-code/:id", 
    name: "EditPromoCode",
    Component: EditPromoCode
  },
  {
    path: "/wash-centers", 
    name: "WashCenters",
    Component: WashCenters
  },
  {
    path: "/wash-center/:id", 
    name: "WashCenter",
    Component: WashCenter
  },
  {
    path: "/statement", 
    name: "Statement",
    Component: Statement
  },
  {
    path: "/add-booking", 
    name: "AddBooking",
    Component: AddBooking
  },
  {
    path: "/tap-response", 
    name: "TapResponse",
    Component: TapResponse
  },
]

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Nunito',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Switch>
            {routes.map(({ path, Component }) => (
              <Route key={path} exact path={path}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={1200}
                    classNames='page'
                    unmountOnExit>
                    <div className='page'>
                      <Component />
                    </div>
                  </CSSTransition>
                )}
              </Route>
            ))}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default withTranslation()(App);