import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menu: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 18,
        fontWeight: 500,
        display: 'block',
        paddingBottom: 30,
        textAlign: 'left',
        paddingLeft: 64,
        opacity: 0.25,
        "&:hover": {
            cursor: "pointer",
            color: '#FFF !important'
        }
    },
    logout: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 18,
        fontWeight: 500,
        display: 'block',
        bottom: 35,
        textAlign: 'left',
        paddingLeft: 64,
        position: 'absolute',
        opacity: 0.25
    }
}));

const SideMenu = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const roleId = localStorage.getItem('roleId');
    const id = localStorage.getItem('id');

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
    }, [])

    function handleLogout(e) {
        localStorage.clear()
        history.push("/login")
    }

    return (
        <div>
            {roleId == 3 &&
                <>
                <NavLink
                    to='/admin'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.admin')}
                </NavLink>
                <NavLink
                    to='/statement'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.statement')}
                </NavLink>
                <NavLink
                    to='/register?key=80lDrO0lQbOY43'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.addcenter')}
                </NavLink>
                <NavLink
                    to='/admin-metrics'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.adminmetrics')}
                </NavLink>
                </>
            }
            {id && id > 0 &&
            <>
                <NavLink
                    to='/add-booking'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.addbooking')}
                </NavLink>
                <NavLink
                    to='/dashboard'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.dashboard')}
                </NavLink>
                {roleId != 3 &&
                    <NavLink
                        to='/statement'
                        exact
                        activeStyle={{
                            fontWeight: "bold",
                            color: "#fff",
                            opacity: 1
                        }}
                        className={classes.menu}
                    >
                        {t('side.statement')}
                    </NavLink>
                }
                <NavLink
                    to='/metrics'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.metrics')}
                </NavLink>
                <NavLink
                    to='/customers'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.customers')}
                </NavLink>
                <NavLink
                    to='/services'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.services')}
                </NavLink>
                <NavLink
                    to='/promo-codes'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.promocodes')}
                </NavLink>
                <NavLink
                    to='/history'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.history')}
                </NavLink>
                <NavLink
                    to='/feedback'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.feedback')}
                </NavLink>
                <NavLink
                    to='/notifications'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.notifications')}
                </NavLink>
                {roleId != 3 &&
                    <NavLink
                        to='/support'
                        exact
                        activeStyle={{
                            fontWeight: "bold",
                            color: "#fff",
                            opacity: 1
                        }}
                        className={classes.menu}
                    >
                        {t('side.support')}
                    </NavLink>
                }
            </>
            }    
            {roleId != 3 &&    
                <NavLink
                    to='/privacy-policy'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.privacypolicy')}
                </NavLink>
            }
            {!id &&
            <NavLink
                to='/terms'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('footer.termsofservice')}
            </NavLink>
            }
            {id && id > 0 &&
                <a 
                    href="#" 
                    className={classes.logout}
                    onClick={handleLogout}
                >
                    {t('side.logout')}
                </a>
            }
        </div>
    );
}

export default SideMenu;

