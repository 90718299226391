import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL, PLACES_API_KEY, PUSHER_APP_KEY } from '../constants';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from "@fullcalendar/timegrid";
import CheckIcon from '@material-ui/icons/Check';
import interactionPlugin from "@fullcalendar/interaction"; 
import washimage from '../images/wash.png'; 
import calendarimage from '../images/calendar.png'; 
import pinimage from '../images/pin.png'; 
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Calendar from 'react-calendar-multiday';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Geocode from "react-geocode";
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pusher from 'pusher-js';
import useSound from 'use-sound';
import soundFile from '../assets/alert.mp3';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ShareIcon from '@material-ui/icons/Share';
import { Animated } from "react-animated-css";
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ConsoleView } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    toggleACWButton: {
      backgroundColor: '#30acdc',
      color: '#fff',
      borderColor: '#9f0000',
      borderWidth: 1
    },
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    pageHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingRight: 40,
      color: '#a7a7a7',
      textDecoration: 'none'
    },
    activePageHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingRight: 40,
      color: '#000',
      textDecoration: 'none'
    },
    userContainer: {
      display: 'flex',
      marginBottom: 10
    },
    customer: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    customerDetails: {
      position: 'absolute',
      marginLeft: 50,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold',
      wordBreak: 'break-word',
      width: 162
    },
    carContainer: {
      display: 'flex'
    },
    car: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    carDetails: {
      position: 'absolute',
      marginLeft: 50,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold'
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    card: {
      backgroundColor: '#f7f7f7',
      borderRadius: 20,
      width: 290,
      marginRight: 10,
      marginBottom: 20
    },
    top: {
      borderBottomWidth: 1,
      borderBottomColor: '#d3d3d3',
      borderBottom: 'solid',
      padding: 20
    },
    bottom: {
      padding: 20
    },
    serviceContainer: {
      display: 'flex'
    },
    locationContainer: {
      display: 'flex'
    },
    calendarContainer: {
      display: 'flex'
    },
    progressContainer: {
      display: 'flex',
      position: 'absolute',
      float: 'right',
      marginLeft: 185,
      border: 'solid 1px #1d7846',
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 8
    },
    wash: {
      backgroundColor: '#fff',
      width: 25,
      height: 25,
      borderRadius: 10
    },
    washImage: {
      width: 18,
      height: 18
    },
    wash: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    washImage: {
      width: 18,
      height: 18,
      margin: 5
    },
    pin: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    pinImage: {
      width: 17,
      height: 20,
      margin: '4px 5px'
    },
    calendar: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    calendarImage: {
      width: 18,
      height: 18,
      margin: 5
    },
    service: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15,
      width: 140,
      wordBreak: 'break-word'
    },
    address: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    next: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    progress: {
      fontSize: 14,
      marginTop: 2,
      color: '#1d7846'
    },
    checkIcon: {
      color: '#1d7846',
      fontSize: 16
    },
    check: {
      marginTop: 2,
      marginRight: 7
    },
    statusContainer: {
      textAlign: 'center',
      height: 36,
      backgroundColor: '#9F0000',
      borderRadius: 12,
      paddingTop: 6,
      marginTop: 10,
      "&:hover": {
          cursor: "pointer"
      }
    },
    status: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 14
    },
    customerNameLink: {
      color: '#000',
      textDecoration: 'none'
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 7
    },
    dialogTitle: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    close: {
      fontSize: 24,
      "&:hover": {
          cursor: "pointer"
      }
    },
    dialogContent: {
      width: 480,
      marginTop: 50,
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    serviceInfoDate: {
      fontSize: 14,
      color: '#000',
      opacity: 0.25,
      marginTop: -10
    },
    dialogUserContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    dialogUserImage: {
      width: 80,
      height: 80,
      borderRadius: 18,
      objectFit: 'cover',
      marginBottom: 25
    },
    dialogCarImage: {
      width: 80,
      height: 80,
      borderRadius: 18,
      objectFit: 'cover',
      marginBottom: 25
    },
    dialogUser: {
      textAlign: 'center'
    },
    dialogCar: {
      textAlign: 'center'
    },
    dialogFlexContainer: {
      display: 'flex'
    },
    dialogRow: {
      marginTop: 60
    },
    modalIconImage: {
      width: 36,
      height: 36
    },
    dialogRightText: {
      marginLeft: 20,
      paddingTop: 6,
      marginLeft: 20,
      paddingTop: 6,
      width: '85%'
    },
    dialogFlexEndContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    dialogRight: {
      position: 'absolute',
      right: 64,
      fontWeight: 'bold'
    },
    dialogRightLast: {
      position: 'absolute',
      right: 64,
      marginTop: 25,
      fontWeight: 'bold'
    },
    dialogBottomRow: {
      marginTop: 25
    },
    mapContainer: {
      height: 319, 
      width: '100%', 
      borderRadius: 40,
      marginTop: 20
    },
    editIcon: {
      marginRight: 10,
      color: '#9f0000',
      fontSize: 18,
      "&:hover": {
          cursor: "pointer"
      }
    },
    editButton: {
      marginTop: 20,
      width: 158,
      color: '#9f0000',
      backgroundColor: '#fff',
      borderRadius: 5,
      marginLeft: -15,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: '#fff',
        color: '#9f0000'
      },
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    cancelIcon: {
      marginRight: 10,
      color: '#fff',
      fontSize: 18,
      "&:hover": {
          cursor: "pointer"
      }
    },
    cancelButton: {
      marginTop: 20,
      width: 175,
      color: '#fff',
      backgroundColor: '#9f0000',
      borderRadius: 5,
      marginLeft: -15,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: '#9f0000',
        color: '#fff'
      }
    },
    input: {
      width: '100%',
      fontSize: 16
    },
    input3rd: {
      width: '30%',
      fontSize: 16,
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    saveContainer: {
      float: 'right',
      marginRight: 0,
      marginTop: 12,
      marginLeft: 58
    },
    saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -45,
      width: 44
    },
    saveBtnContainer: {
      width: 36,
      height: 36,
      borderRadius: 12,
      backgroundColor: '#9F0000',
      display: 'inline-block',
      marginLeft: 10,
      "&:hover": {
          cursor: 'pointer'
      },
    },
    saveBtn: {
      width: 21,
      height: 21,
      paddingLeft: 3,
      position: 'absolute',
      marginLeft: 7,
      marginTop: 6
    },
    buttonContainerFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      ['@media (max-width:959px)']: { 
        justifyContent: 'center',
        flexWrap: 'wrap'
      }
    },
    timeslot: {
      marginTop: -35
    },
    mapIcon: {
      color: '#9F0000',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    whatsappIcon: {
      color: '#075e54',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    emailIcon: {
      color: '#9F0000',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    share: {
      "&:hover": {
          cursor: "pointer"
      }
    },
}));

const Dashboard = () => {
  const classes = useStyles();
  const id = localStorage.getItem('id');
  const roleId = localStorage.getItem('roleId');
  let [business, setBusiness] = useState('');
  let [role, setRole] = useState('');
  let [services, setServices] = useState('');
  let [dashboardevents, setDashboardevents] = useState('');
  let [blockTimes, setBlockTimes] = useState('');
  let [events, setEvents] = useState('');
  let [eventCount, setEventCount] = useState('');
  let [selecteddayevents, setSelecteddayevents] = useState('');
  let [selectedday, setSelectedday] = useState('');
  let [servicedays, setServicedays] = useState('');
  let [nextservicedays, setNextservicedays] = useState('');
  let [center, setCenter] = useState('');
  let [zoom, setZoom] = useState('');
  let [google, setGoogle] = useState('');
  let [directions, setDirections] = useState('');
  let [drivetime, setDrivetime] = useState('');
  let [edit, setEdit] = useState(false);
  let [selectedevent, setSelectedevent] = useState([]);
  let [location, setLocation] = useState('');
  let [houseBuilding, setHouseBuilding] = useState('');
  let [road, setRoad] = useState('');
  let [block, setBlock] = useState('');
  let [timeslot, setTimeslot] = useState('');
  let [customerlatitude, setCustomerlatitude] = useState('');
  let [customerlongitude, setCustomerlongitude] = useState('');
  let [customerlocation, setCustomerlocation] = useState('');
  let [whatsapplink, setWhatsapplink] = useState('');
  let [mailto, setMailto] = useState('');
  let [selectedBlockDateTimeSlot, setSelectedBlockDateTimeSlot] = useState('');
  let [selectedBlockMode, setSelectedBlockMode] = useState('');
  let [selectedBlockItem, setSelectedBlockItem] = useState([]);
  let [washCenterHours, setWashCenterHours] = useState([]);
  let [breakTimeHours, setBreakTimeHours] = useState([]);
  const descriptionElementRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [openBlockTime, setOpenBlockTime] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const [calendarView, setCalendarView] = React.useState(true);
  const [cardView, setCardView] = React.useState(false);
  const count = useRef(0);
  let [editselecteddate, setEditselecteddate] = useState([]);
  let [editnextselecteddate, setEditnextselecteddate] = useState([]);
  const { t, i18n } = useTranslation();
  const [play] = useSound(soundFile);
  const inputRef = React.useRef(null);
  const [blockStartTime, setBlockStartTime] = useState('');
  const [blockEndTime, setBlockEndTime] = useState('');
  const [filters, setFilters] = React.useState(() => ['ACW', 'ECW', 'MAD', 'PAC', 'SMC', 'SMR', 'TDL']);
  let [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    setRole(roleId);
    const language = localStorage.getItem('language');
    if(language) {
        i18n.changeLanguage(language);
    }

    // Initialize map
    setCenter({lat: 26.258085, lng: 50.616755});
    setZoom(13);

    axios.get(API_URL + `/business/${id}`)
    .then(res => {
      let business = res.data.business;
      setBusiness(business);

      if(business) {
        const pusher = new Pusher(PUSHER_APP_KEY, {
          cluster: 'ap2',
          encrypted: true
        });
        var channel = pusher.subscribe('business-' + business.id);
        channel.bind('notifications', function(data) {
          inputRef.current.click();
          toast.dark((language == 'en' ? 'Updating dashboard...' : 'جارٍ تحديث لوحة المعلومات ...'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
  
          // Reload data
          loadData(business.id)
        });
  
        loadData(business.id);
      }
    })
  }, [])
  
  if (!roleId) {
    return <Redirect to={'/login'} />;
  }

  function loadData(businessId) {
    axios.get(API_URL + `/business/dashboard/services/${businessId}`)
    .then(response => {
      setServices(response.data.dashboard);

      // Get events
      const roleId = localStorage.getItem('roleId');
      let url = `/business/dashboard/events/${businessId}`
      if(roleId == 3) {
        url = `/wash-center/dashboard/events`
      }

      axios.get(API_URL + url)
      .then(resp => {
        let dbevts = resp.data.events;
        let evts = [];
        setDashboardevents(dbevts);
        
        if(dbevts) {
          dbevts.forEach(e => {
            let title = e.first_name + ' ' + e.last_name;
            if(roleId == 3) {
              title = e.initials + ' - ' + title
            }
            let dt = moment(e.dt).utc().format("YYYY-MM-DD HH:mm:ss").toString();
            let end_dt;
            if(e.end_dt) {
              let dt_end = e.end_dt.replace(" ", "T").replace(":00:00", ":00:00.000Z");
              end_dt = moment(dt_end).utc().format("YYYY-MM-DD HH:mm:ss").toString();
            }
            if(end_dt) {
              evts.push({
                title: title,
                date: e.date,
                id: e.random_id,
                start: dt,
                end: end_dt
              })
            } else {
              evts.push({
                title: title,
                date: e.date,
                id: e.random_id,
                start: dt
              })
            }
          });
        
          axios.get(API_URL + `/business/block-times/${businessId}`)
          .then(res => {
            let block_times = res.data.block_times;
            setBlockTimes(block_times)
            if(block_times) {
              block_times.forEach(bt => {
                evts.push({
                  title: t('dashboard.blocked'),
                  date: bt.blocked_date_time.substring(0, 10),
                  id: 0,
                  start: bt.blocked_date_time.replace("T", " ").substring(0, 19),
                  display: 'background'
                })
              })
            }

            axios.get(API_URL + `/wash-center/calendar/hours/${businessId}`)
            .then(rsp => {
              let calendar_hours = rsp.data.calendar_hours
              if(calendar_hours) {
                setWashCenterHours(calendar_hours.washCenterHours)
                setBreakTimeHours(calendar_hours.breakTimeHours)
              }
  
              if(evts) {
                setEvents(evts);
    
                // Load today
                let timezoneOffset = new Date().getTimezoneOffset() * -1;
                let tz = moment(new Date()).utc().utcOffset(timezoneOffset);
                let today = moment(new Date()).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD").toString();
                if(dbevts) {
                  let db = dbevts.filter(e => {
                    return e.date == today
                  })
                  setSelectedday('Today');
                  setSelecteddayevents(db);
                  setEventCount(db.length)
                }
              }

            })
          })
        }
      })
    })  
  }

  function handleDateClick(arg) {
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    console.log('Safari? : ' + isSafari)
    var n = new Date().getTimezoneOffset()
    console.log('Offset: ' + n)
    let suffix_tz = n / 60 * -1
    let suffix_oper = ''
    let timezone = ''
    if(suffix_tz < 0) {
      suffix_oper = '-'
    } else {
      suffix_oper = '+'
    }
    if(suffix_tz < 10) {
      timezone = suffix_oper + '0' + suffix_tz + ':00'
    } else {
      timezone = suffix_oper + suffix_tz + ':00'
    }

    let selecteddatetime = arg.dateStr.replace("T", " ").replace("30:00", "00:00").substring(0, 19);
    let selected_date_time = arg.dateStr.replace("30:00", "00:00").substring(0, 19);
    if(isSafari) {
      selected_date_time += timezone;
    }

    axios.get(API_URL + `/business/block-time-item/${business.id}/${selecteddatetime}`)
    .then(res => {
      let block_time = res.data.block_time;
      if(block_time) {
        setSelectedBlockItem(block_time);
        setSelectedBlockMode('unblock');
      } else {
        setSelectedBlockMode('block');
      }
      localStorage.setItem('selectedDateTime', selected_date_time);
      console.log(selected_date_time)
      setBlockStartTime(selected_date_time);
      setSelectedBlockDateTimeSlot(selected_date_time);
      setOpenBlockTime(true);
    })

    // let selected_date_time = arg.dateStr.replace("T", " ").replace("30:00", "00:00").substring(0, 19);
    // console.log('Selected date/time: ' + selected_date_time)

    // let selected_date = arg.dateStr
    // console.log('Selected date: ' + selected_date)

    // if(selected_date_time) {
    //   let day_of_week = new Date(selected_date).getDay()
    //   console.log('Day of week: ' + day_of_week)
    //   let time = selected_date_time.substring(11, 16);
    //   let match = breakTimeHours.some(a => a.dayOfWeek == day_of_week && a.startTime == time);
    //   let closed = false
    //   let off = false
    //   match = false
      
    //   if(washCenterHours && !match) {
    //     let wash = washCenterHours.filter(w => {
    //       return w.daysOfWeek[0] == day_of_week
    //     })
    //     console.log(wash)

    //     if(wash && wash.length > 1) {
    //       // opening
    //       if(wash && wash[0]) {
    //         let dayOfWeek = wash[0].daysOfWeek[0]
    
    //         if(wash[0].startTime) {
    //           let selected_time = parseInt(time.replace(':00', ''))
    //           let start_time = parseInt(wash[0].startTime.replace(':00', ''))

    //           if(day_of_week == dayOfWeek) {
    //             if(selected_time < start_time) {
    //               closed = true
    //             }
    //           }
    //         }
    //       }

    //       // closing
    //       if(wash && wash[1]) {
    //         let dayOfWeek = wash[1].daysOfWeek[0]

    //         if(time && wash[1].endTime) {
    //           let selected_time = parseInt(time.replace(':00', ''))
    //           let end_time = parseInt(wash[1].endTime.replace(':00', ''))

    //           if(day_of_week == dayOfWeek) {
    //             if(selected_time >= end_time) {
    //               closed = true
    //             }
    //           }
    //         }
    //       }
    //     } else if(wash && wash.length == 1) { 
    //       if(wash && wash[0]) {
    //         let dayOfWeek = wash[0].daysOfWeek[0]
    
    //         if(wash[0].startTime && wash[0].endTime) {
    //           let selected_time = parseInt(time.replace(':00', ''))
    //           let start_time = parseInt(wash[0].startTime.replace(':00', ''))
    //           let end_time = parseInt(wash[0].endTime.replace(':00', ''))

    //           if(day_of_week == dayOfWeek) {
    //             console.log('Selected: ' + selected_time + ' Start: ' + start_time + ' End: ' + end_time)
    //             if(selected_time < start_time || selected_time >= end_time) {
    //               closed = true
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       if(wash && wash[0] && wash[1]) {
    //         let dayOfWeek = wash[0].daysOfWeek[0]
    
    //         if(time && wash[0].startTime && wash[1].endTime) {
    //           let selected_time = parseInt(time.replace(':00', ''))
    //           let start_time = parseInt(wash[0].startTime.replace(':00', ''))
    //           let end_time = parseInt(wash[1].endTime.replace(':00', ''))

    //           if(day_of_week == dayOfWeek) {
    //             if(selected_time < start_time || selected_time >= end_time) {
    //               closed = true
    //             }
    //           }
    //         }
    //       }
    //     }

    //     let matchDays = washCenterHours.filter(w => {
    //       return w.daysOfWeek[0] == day_of_week
    //     })
    //     if((matchDays && matchDays.length == 0) || !matchDays) {
    //       off = true
    //     }
    //   }

    //   if(match || closed || off) {
    //     // Break, do nothing
    //   } else {
    //     axios.get(API_URL + `/business/block-time-item/${business.id}/${selected_date_time}`)
    //     .then(res => {
    //       let block_time = res.data.block_time;
    //       if(block_time) {
    //         setSelectedBlockItem(block_time);
    //         setSelectedBlockMode('unblock');
    //       } else {
    //         setSelectedBlockMode('block');
    //       }
    //       localStorage.setItem('selectedDateTime', selected_date_time);
    //       setBlockStartTime(selected_date_time);
    //       setSelectedBlockDateTimeSlot(selected_date_time);
    //       setOpenBlockTime(true);
    //     })
    //   }
    // }
  }

  function handleEventClick(event) {
    let id = event.event.id;
    
    if(id == 0) {
      let selected_date_time = event.event.start.toString().replace("T", " ").substring(0, 19);
      axios.get(API_URL + `/business/block-time-item/${business.id}/${selected_date_time}`)
      .then(res => {
        let block_time = res.data.block_time;
        if(block_time) {
          setSelectedBlockItem(block_time);
          setSelectedBlockMode('unblock');
        } else {
          setSelectedBlockMode('block');
        }
        setSelectedBlockDateTimeSlot(selected_date_time);
        setOpenBlockTime(true);
      })
    } else {
      let timezoneOffset = new Date().getTimezoneOffset() * -1;
      let tz = moment(new Date()).utc().utcOffset(timezoneOffset);
      let today = moment(new Date()).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD").toString();

      let date;
      let include = false;
      let db = dashboardevents.filter(e => {
        let dt = moment(e.dt).utc().format("YYYY-MM-DD HH:mm:ss").toString();
        let selecteddatetime = moment(event.event.start).format("YYYY-MM-DD HH:mm:ss").toString();
        if(e.random_id == id && dt == selecteddatetime) {
          date = e.date;
          include = true;
        } else {
          include = false;
        }
        
        return include;
      })
      if(today == date) {
        setSelectedday('Today');
      } else {
        setSelectedday(moment(date).format("MMM D"));
      }
      setSelecteddayevents(db);
      setCardView(true);
      setCalendarView(false);
    }
  }

  function handleUpdateStatus(event) {
    let _status = event.status.indexOf('On the way') >= 0 ? 'On the way' : 'Complete';
    axios.post(API_URL + `/business/service/status/edit`, { 
      id: event.id,
      request_date_id: event.request_date_id,
      status: _status
    })
    .then(res => {
        if(res.status == 200) {
            let evts = []
            if(selecteddayevents) {
              selecteddayevents.forEach(e => {
                if(e.random_id == event.random_id) {
                  if(event.status.indexOf('On the way') >= 0) {
                    e.status = e.status.replace('On the way', 'Complete');
                  } else if(event.status.indexOf('Complete') >= 0) {
                    e.status = '';
                    if(e.progress && e.progress.indexOf("/") > 0) {
                      let idx = e.progress.indexOf("/");
                      if(idx) {
                        let oldprogress = e.progress;
                        let cnt = e.progress.substr(0, idx);
                        if(parseInt(cnt) > 0) {
                          let newcnt = parseInt(cnt) + 1;
                          let newprogress = e.progress.replace(cnt + "/", newcnt + "/");
                          let statuscnt = parseInt(newcnt) + 1;
                          e.progress = newprogress;
                          if(dashboardevents) {
                            dashboardevents.forEach(de => {
                              if(de.progress) {
                                de.progress = newprogress;
                              }
                              if(de.status) {
                                de.status = de.status.replace(newcnt + "/", statuscnt + "/");
                              }
                            })
                          }
                        }
                      }
                    }
                  }
                }
                evts.push(e);
              })
              setSelecteddayevents(evts);
            }
        }
    })
  }

  const handleClose = () => {
    setSelectedevent([]);
    setOpen(false);
  };

  const handleServiceDayClick = (ob) => {
    if(ob.current) {
      localStorage.setItem('selecteddate', ob.current);
      setEditselecteddate(ob.current);
    }
  }

  const handleNextServiceDayClick = (ob) => {
    if(ob.current) {
      localStorage.setItem('selecteddate', ob.current);
      setEditnextselecteddate(ob.current);
    }
  }

  const handleClickOpen = (scrollType, event) => () => {
    selectEvent(scrollType, event);
  };

  const handleShareClick = (event) => () => {
    let message = event.dt.replace("T", " ").replace(":00.000Z", "") + '\r\n';
    message += event.outcall + '\r\n';
    if(event.plate_number) {
      message += event.plate_number + '\r\n';
    }
    message += event.payment + '\r\n';
    message += event.phone_number + '\r\n';
    message += event.location + '\r\n';

    let additional_address = '';
    if(event.building) {
      additional_address += 'House/Building ' + event.building + ' ';
    }
    if(event.road) {
      additional_address += 'Road ' + event.road + ' ';
    }
    if(event.block) {
      additional_address += 'Block ' + event.block + ' ';
    }

    if(additional_address) {
      message += additional_address + '\r\n';
    }
    
    if(event.location) {
      if(event.location_link && event.location_link != 'null') {
        message += event.location_link + '\r\n'
      } else {
        if(event.location) {
          let address;
          if(event.location.indexOf("block") >= 0 && event.location.indexOf("building") >= 0) {
              let add = event.location.split(',');
              if(add) {
                  address = "block " + event.block + add[1];
              } else {
                  address = event.location;
              }
          } else {
              address = event.location;
          }
          let latitude = event.location_latitude;
          let longitude = event.location_longitude;
          let mapLink = `https://www.google.com.sa/maps/place/${encodeURI(address)}`;
          if(latitude && longitude) {
            mapLink = `http://maps.google.com/?q=${latitude},${longitude}`;
          }
          message += mapLink + '\r\n'
        }
      }
    }
    message += '\r\nBooked via CarCare App';

    let whatsappLink = 'https://api.whatsapp.com/send?text=' + window.encodeURIComponent(message);
    window.open(
      whatsappLink,
      '_blank'
    );
  };

  function selectEvent(scrollType, event) {
    if(event.id) {
      setSelectedevent(event);
      setEdit(false)

      let utc_time = '';
      let timezoneOffset = new Date().getTimezoneOffset();
      let tz = moment(event.start_time_slot).utc().utcOffset(timezoneOffset);

      let timeslot;
      if(event.start_time_slot) {
        timeslot = (moment(event.start_time_slot).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm").toString() + ":00Z").replace(" ", "T");
        setTimeslot(timeslot);
      }

      axios.get(API_URL + `/car/service/dates/${event.id}`)
      .then(res => {
        let dates = res.data.dates;
        if(dates) {
          setServicedays(dates.currentMonth);
          setNextservicedays(dates.nextMonth);
        }

        // if(!dates.currentMonth && !dates.nextMonth) {
          setServicedays([event.date]);
        // }
        
        setOpen(true);
        setScroll(scrollType);
      })

      Geocode.setApiKey(PLACES_API_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("bh");
      if(event.location) {
        Geocode.fromAddress(event.location).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            setCustomerlatitude(lat)
            setCustomerlongitude(lng);

            // Compute center
            let centerlatitude = (parseFloat(event.latitude) + lat) / 2;
            let centerlongitude = (parseFloat(event.longitude) + lng) / 2;
            setCenter({lat: centerlatitude, lng: centerlongitude});

            let mapLink = `https://www.google.com.sa/maps/place/${encodeURI(event.location)}/@${lat},${lng},16z`;
            let whatsappLink = 'https://api.whatsapp.com/send?text=' + 'Customer Location: ' + event.location + ' ' + encodeURI(mapLink);
            let mailLink = `mailto:?Subject=Customer Location&body=` + 'Customer Location: ' + event.location + ' ' + encodeURI(mapLink);
            setCustomerlocation(mapLink);
            setWhatsapplink(whatsappLink);
            setMailto(mailLink);
          },
          error => {
            console.error(error);
          }
        );
      }
    } else {
      setOpen(true);
      setScroll(scrollType);
    }
  }

  function onLoad() {
    if(window.google && window.google.maps) {
      setGoogle(window.google);
    }
  }

  function directionServiceLoad(directionsService) {
    directionsService.route(
      {
        origin: selectedevent.address,
        destination: selectedevent.location,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);

          // Compute time
          var time = '';
          var myroute = result.routes[0];
          for (var i = 0; i < myroute.legs.length; i++) {
            time += myroute.legs[i].duration.text;
          }

          time = time.replace('hours','hr');
          time = time.replace('mins','min');

          let drive_time = '(' + time + ' drive)';
          drive_time.replace('(0', '(');
          setDrivetime(drive_time);

        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  function handleEdit() {
    if(selectedevent) {
      setLocation(selectedevent.location);
      setHouseBuilding(selectedevent.building);
      setRoad(selectedevent.road);
      setBlock(selectedevent.block);
    }
    setEdit(true);
  }

  const handleFieldChange = (event, type) => {
    switch(type) {
      case 'location': 
        setLocation(event.target.value);
        break;
      case 'houseBuilding': 
        setHouseBuilding(event.target.value);
        break;
      case 'road': 
        setRoad(event.target.value);
        break;
      case 'block': 
        setBlock(event.target.value);
        break;
      case 'searchKeyword': 
        setSearchKeyword(event.target.value);
        break;
    }
  };

  function handleSave(e) {
    e.preventDefault();

    let request_date = localStorage.getItem('selecteddate');
    if(request_date) {
      request_date = request_date.substr(0, 10) + ' 00:00:00';
    }

    let timezoneOffset = new Date().getTimezoneOffset();
    timezoneOffset = timezoneOffset * -1;

    let start_time_slot = moment(timeslot).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString();
    let time_slot = moment(timeslot).utc().utcOffset(timezoneOffset).format("h A").toString();
    
    axios.post(API_URL + `/car/service/edit/${selectedevent.id}`, {
      start_time_slot: start_time_slot,
      location: location,
      building: houseBuilding,
      road: road,
      block: block,
      request_date_id: selectedevent.request_date_id,
      request_date: request_date
    })
    .then(res => {
      window.location.reload(true)
    })
  }

  function handleCancelBooking() {
    if(selectedevent) {
      setOpenconfirm(true);
    }
  }

  function renderOtherDialog () {
    return (
      <Dialog
        open={openconfirm}
        onRequestClose={() => setOpenconfirm(false)}
        id="confirm-dialog"
      >
        <DialogTitle>{t('dashboard.areyousurecancelbooking')}</DialogTitle>
        <DialogActions id="confirm-buttons-container">
          <Button onClick={() => setOpenconfirm(false)} primary>{t('dashboard.cancel')}</Button>
          <Button onClick={cancelBooking} primary>{t('dashboard.yescancel')}</Button>
        </DialogActions>
      </Dialog>
    )
  }

  function renderBlockTimeDialog () {
    return (
      <Dialog
        open={openBlockTime}
        onRequestClose={() => setOpenBlockTime(false)}
        id="confirm-dialog"
      >
        <DialogTitle>{selectedBlockMode == 'block' ? t('dashboard.areyousureblocktime') : t('dashboard.areyousureunblocktime')}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <div><b>{t('dashboard.date')}: </b>{moment(selectedBlockDateTimeSlot).format("ddd M/D").toString()}</div>
          {selectedBlockMode == 'unblock' &&
            <div><b>{t('dashboard.time')}: </b>{moment(selectedBlockDateTimeSlot).format("hA ").toString()}</div>
          }
          {selectedBlockMode == 'block' &&
            <>
              <div>
                <div>
                  <b>{t('dashboard.starttime')}: </b>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                      margin="normal"
                      id="block-start-time"
                      onChange={(date) => handleBlockTimeChange(date, 'blockStartTime')}
                      KeyboardButtonProps={{
                          'aria-label': 'change time',
                      }}
                      inputVariant="outlined"  
                      keyboardIcon={<KeyboardArrowDownIcon />}
                      value={blockStartTime}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div id="block-end-time-wrapper">
                <div>
                  <b>{t('dashboard.endtime')}: </b>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                      margin="normal"
                      id="block-end-time"
                      onChange={(date) => handleBlockTimeChange(date, 'blockEndTime')}
                      KeyboardButtonProps={{
                          'aria-label': 'change time',
                      }}
                      inputVariant="outlined"  
                      keyboardIcon={<KeyboardArrowDownIcon />}
                      value={blockEndTime}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </>
          }
        </DialogContent>
        <DialogActions id="confirm-buttons-container">
          <Button onClick={() => setOpenBlockTime(false)} primary>{t('dashboard.cancel')}</Button>
          <Button onClick={blockTime} primary>{selectedBlockMode == 'block' ? t('dashboard.yesblock') : t('dashboard.yesunblock')}</Button>
        </DialogActions>
      </Dialog>
    )
  }

  function blockTime() {
    let selected_date_time = selectedBlockDateTimeSlot.replace("T", " ").replace("30:00","00:00").substring(0, 19);

    let start_time;
    let end_time;
    if(blockStartTime) {
      if(selected_date_time) {
        start_time = moment(selected_date_time).format("YYYY-MM-DD") + " " + moment(blockStartTime).format("HH") + ":00:00";
      } else {
        start_time = moment(blockStartTime).format("YYYY-MM-DD HH") + ":00:00";
      }
    }
    if(blockEndTime) {
      let end_date;
      if(selected_date_time) {
        end_date = moment(selected_date_time).format("YYYY-MM-DD");
      } else {
        end_date = moment(blockStartTime).format("YYYY-MM-DD");
      }
      end_time = end_date + ' ' + moment(blockEndTime).format("HH") + ":00:00";
    }

    if(selectedBlockMode == 'block')  {
      axios.post(API_URL + `/business/block-time-new/${business.id}`, { 
        block_start_time: start_time,
        block_end_time: end_time,
        created_by: id
      })
      .then(res => {
        if(res.status == 200) {
          window.location.reload(true);
        }
      })
    } else if(selectedBlockMode == 'unblock') {
      if(selectedBlockItem) {
        axios.post(API_URL + `/business/unblock-time-new/${selectedBlockItem.id}`, {})
        .then(res => {
          if(res.status == 200) {
            window.location.reload(true);
          }
        })
      }
    }
  }

  function cancelBooking() {
    axios.post(API_URL + `/car/request/cancel/${selectedevent.id}`, {})
    .then(res => {
      window.location.reload(true);
    })
  }

  const handleDateChange = (date, type) => {
    switch(type) {
      case 'timeslot': 
        setTimeslot(date)
        break;
    }
  };

  function clickNotification(requestId, requestDateId) {
    let event;
    if(selecteddayevents) {
      selecteddayevents.forEach(e => {
        if(e.id == requestId) {
          if(requestDateId) {
            if(e.request_date_id == requestDateId) {
              event = e;
            }
          } else {
            event = e;
          }
        }
      });
      if(event) {
        selectEvent('body', event);
      }
    }
  }

  function showCalendar() {
    setCalendarView(true);
    setCardView(false);
  }

  function showCards() {
    setCardView(true);
    setCalendarView(false);
  }

  const handleBlockTimeChange = (date, type) => {
    switch(type) {
      case 'blockStartTime': 
        localStorage.setItem('blockStartTime', date);
        setBlockStartTime(date)
        break;
      case 'blockEndTime': 
        localStorage.setItem('blockEndTime', date);
        setBlockEndTime(date)
        break;
    }
  };

  const EventDetail = (eventInfo) => {
    const roleId = localStorage.getItem('roleId');
    if(roleId == 3) {
      if(eventInfo.event.title.indexOf('PAC') >= 0) {
        return (
          <div style={{backgroundColor:'#1b1b1b',height:'100%'}}>
            <b>{eventInfo.timeText}</b>
            <p><i>{eventInfo.event.title}</i></p>
          </div>
        )
      } else if(eventInfo.event.title.indexOf('SMR') >= 0) {
        return (
          <div style={{backgroundColor:'#0884f4',height:'100%'}}>
            <b>{eventInfo.timeText}</b>
            <p><i>{eventInfo.event.title}</i></p>
          </div>
        )
      } else if(eventInfo.event.title.indexOf('MAD') >= 0) {
        return (
          <div style={{backgroundColor:'#c52835',height:'100%'}}>
            <b>{eventInfo.timeText}</b>
            <p><i>{eventInfo.event.title}</i></p>
          </div>
        )
      } else if(eventInfo.event.title.indexOf('SMC') >= 0) {
        return (
          <div style={{backgroundColor:'#ff0404',height:'100%'}}>
            <b>{eventInfo.timeText}</b>
            <p><i>{eventInfo.event.title}</i></p>
          </div>
        )
      } else if(eventInfo.event.title.indexOf('ACW') >= 0) {
        return (
          <div style={{backgroundColor:'#30acdc',height:'100%'}}>
            <b>{eventInfo.timeText}</b>
            <p><i>{eventInfo.event.title}</i></p>
          </div>
        )
      } else if(eventInfo.event.title.indexOf('TDL') >= 0) {
        return (
          <div style={{backgroundColor:'#086c94',height:'100%'}}>
            <b>{eventInfo.timeText}</b>
            <p><i>{eventInfo.event.title}</i></p>
          </div>
        )
      } else if(eventInfo.event.title.indexOf('ECW') >= 0) {
          return (
            <div style={{backgroundColor:'#f08010',height:'100%'}}>
              <b>{eventInfo.timeText}</b>
              <p><i>{eventInfo.event.title}</i></p>
            </div>
          )
      } else {
        return (
          <div>
            <b>{eventInfo.timeText}</b>
            <p><i>{eventInfo.event.title}</i></p>
          </div>
        )
      }
    } else {
      return (
        <div>
          <b>{eventInfo.timeText}</b>
          <p><i>{eventInfo.event.title}</i></p>
        </div>
      )
    }
  };

  const handleFilter = (event, newFilters) => {
    setFilters(newFilters);

    const roleId = localStorage.getItem('roleId');
    let evts = [];
    if(dashboardevents) {
      dashboardevents.forEach(e => {
        let title = e.first_name + ' ' + e.last_name;
        let match = newFilters.some(a => a === e.initials);
        if(roleId == 3) {
          title = e.initials + ' - ' + title
        }
        let dt = moment(e.dt).utc().format("YYYY-MM-DD HH:mm:ss").toString();
        let end_dt;
        if(e.end_dt) {
          let dt_end = e.end_dt.replace(" ", "T").replace(":00:00", ":00:00.000Z");
          end_dt = moment(dt_end).utc().format("YYYY-MM-DD HH:mm:ss").toString();
        }
        if(end_dt) {
          if(match) {
            evts.push({
              title: title,
              date: e.date,
              id: e.random_id,
              start: dt,
              end: end_dt
            })
          }
        } else {
          if(match) {
            evts.push({
              title: title,
              date: e.date,
              id: e.random_id,
              start: dt
            })
          }
        }
      });
    
      if(blockTimes) {
        blockTimes.forEach(bt => {
          evts.push({
            title: t('dashboard.blocked'),
            date: bt.blocked_date_time.substring(0, 10),
            id: 0,
            start: bt.blocked_date_time.replace("T", " ").substring(0, 19),
            display: 'background'
          })
        })
      }

      if(evts) {
        setEvents(evts);

        // Load today
        let timezoneOffset = new Date().getTimezoneOffset() * -1;
        let tz = moment(new Date()).utc().utcOffset(timezoneOffset);
        let today = moment(new Date()).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD").toString();
        if(dashboardevents) {
          let db = dashboardevents.filter(e => {
            return e.date == today
          })
          setSelectedday('Today');
          setSelecteddayevents(db);
          setEventCount(db.length)
        }
      }
    }
  };

  return (
    <div className="inner">
      <LoggedInTopMenu notificationClick={clickNotification} />
      <div ref={inputRef} onClick={play}>CarCare</div>
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
          <Hidden smDown>
            <div className={classes.sideMenu}>
              <SideMenu />
            </div>
          </Hidden>
          <div className={classes.mainContent}>
            <div className={classes.article}>
              <div>
                {services && services.map((service, index) => (
                  <a key={service.id} href="#" className={index == 0 ? classes.activePageHeader : classes.pageHeader}>{service.name} {eventCount && eventCount > 0 ? (' (' + eventCount + ')') : ''}</a>  
                ))}
              </div>
              {role == 3 &&
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                <ToggleButtonGroup value={filters} onChange={handleFilter} aria-label="filters">
                  <ToggleButton id="acw-filter" value="ACW" aria-label="ACW">
                    <div>Alraqi Car Wash</div>
                  </ToggleButton>
                  <ToggleButton id="ecw-filter" value="ECW" aria-label="ECW">
                    <div>Eco Wash</div>
                  </ToggleButton>
                  <ToggleButton id="mad-filter" value="MAD" aria-label="MAD">
                    <div>Magnetic Auto Detailing</div>
                  </ToggleButton>
                  <ToggleButton id="pac-filter" value="PAC" aria-label="PAC">
                    <div>Protouch Auto Care</div>
                  </ToggleButton>
                  <ToggleButton id="smc-filter" value="SMC" aria-label="SMC">
                    <div>Shine Me Car Wash</div>
                  </ToggleButton>
                  <ToggleButton id="smr-filter" value="SMR" aria-label="SMR">
                    <div>Steam My Ride</div>
                  </ToggleButton>
                  <ToggleButton id="tdl-filter" value="TDL" aria-label="TDL">
                    <div>The Detail Lab</div>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              }
              <div id="dashboard-view-container">
                <div
                  class="view-container calendar"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: calendarView ? '#D3D3D0' : '#FFF',
                    fontWeight: calendarView ? 'bold' : ''
                  }}
                  onClick={showCalendar} 
                >
                  <EventNoteIcon 
                    id="calendar-view" 
                    style={{
                      color: calendarView ? '#9F0000' : '#000'
                    }}
                  />
                  <div style={{
                    marginLeft: 8
                  }}>CALENDAR VIEW</div>
                </div>
                <div
                  class="view-container card"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: cardView ? '#D3D3D0' : '#FFF',
                    fontWeight: cardView ? 'bold' : ''
                  }}
                  onClick={showCards}
                >
                  <ViewCarouselIcon 
                    id="card-view" 
                    style={{
                      color: cardView ? '#9F0000' : '#000'
                    }}
                  />
                  <div style={{
                    marginLeft: 8
                  }}>CARD VIEW</div>
                </div>
              </div>
              <div id="calendar-wrapper">
                <Hidden smDown>
                  <div id="calendar"
                    style={{
                      display: cardView ? 'none' : ''
                    }}
                  >
                    <FullCalendar
                      plugins={[ dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin ]}
                      initialView="timeGridWeek"
                      contentHeight="auto"
                      events={events}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      themeSystem='bootstrap'
                      allDaySlot={false}
                      slotMinTime={'07:00:00'}
                      slotMaxTime={'23:00:00'}
                      slotEventOverlap={true}
                      eventContent={EventDetail}
                      businessHours={washCenterHours}
                    />
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div
                    style={{
                      display: cardView ? 'none' : ''
                    }}
                  >
                    <FullCalendar
                      plugins={[ dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin ]}
                      initialView="timeGridWeek"
                      contentHeight="auto"
                      events={events}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      themeSystem='bootstrap'
                      allDaySlot={false}
                      slotMinTime={'07:00:00'}
                      slotMaxTime={'23:00:00'}
                      slotEventOverlap={true}
                      eventContent={EventDetail}
                      businessHours={washCenterHours}
                    />
                  </div>
                </Hidden>
                <Animated animationIn="zoomInUp" animationOut="zoomOutDown" isVisible={cardView}>
                  <div id="events">
                  {selectedday == 'Today' ? t('dashboard.today') : selectedday}
                  {selecteddayevents.length == 0 &&
                    <div>{t('dashboard.noschedule')}</div>
                  }
                  <div className={classes.cardContainer}>
                    {selecteddayevents && selecteddayevents.map((event, index) => (
                      <div key={index} className={classes.card}>
                        <div className={classes.top}>
                          <div className={classes.userContainer}>
                            <a 
                              href="#"
                              onClick={handleClickOpen('body', event)}
                            >
                              <img
                                className={classes.customer}
                                src={API_URL + '/profile/image/' + (event.created_by_image ? event.created_by_image : 'default.png')} 
                              />
                            </a>
                            <div className={classes.customerDetails}>
                              <a 
                                href="#" 
                                className={classes.customerNameLink} 
                                onClick={handleClickOpen('body', event)}
                              >
                                {event.first_name} {event.last_name}
                              </a>
                            </div>
                            <div>
                              <ShareIcon 
                                className={classes.share}
                                style={{
                                  position: 'absolute',
                                  marginLeft: 195,
                                  marginTop: 5
                                }}
                                onClick={handleShareClick(event)}
                              />
                            </div>
                          </div>
                          <div className={classes.carContainer}>
                          <img
                            className={classes.car}
                            src={API_URL + '/car/type/image/' + event.car_type_image} 
                          />
                          <div className={classes.carDetails}>{event.car_type}</div>
                        </div>
                        </div>
                        <div className={classes.bottom}>
                          <div className={classes.serviceContainer}>
                            <div className={classes.wash}>
                              <img 
                                className={classes.washImage} 
                                src={washimage} 
                                alt="Wash" />
                            </div>
                            <div className={classes.service}>{event.outcall}</div>
                            <div className={event?.progress ? classes.progressContainer : ''}>
                              {event.progress && 
                                <div className={classes.check}>
                                  <CheckIcon className={classes.checkIcon} />
                                </div>
                              }  
                              <div className={classes.progress}>{event.progress}</div>
                            </div>
                          </div>
                          <div className={classes.locationContainer}>
                            <div className={classes.pin}>
                              <img 
                                className={classes.pinImage} 
                                src={pinimage} 
                                alt="Location" />
                            </div>
                            <div className={classes.address}>{t('dashboard.housebuilding')} {event.building}, {t('dashboard.road')} {event.road}, {t('dashboard.block')} {event.block}, {event.location}</div>
                          </div>
                          <div className={classes.calendarContainer}>
                            <div className={classes.calendar}>
                              <img 
                                className={classes.calendarImage} 
                                src={calendarimage} 
                                alt="Next" />
                            </div>
                            <div className={classes.next}>{event.next_date ? (t('dashboard.next') + ': ') : ''}{event.next_date ? event.next_date : event.time_slot}</div>
                          </div>
                          <div className={classes.calendarContainer}>
                            <div className={classes.calendar}>
                              <AttachMoneyIcon style={{
                                fontSize: 18,
                                color: '#9F0000',
                                marginLeft: 5,
                                marginTop: 4
                              }} />
                            </div>
                            <div className={classes.next}>{event.currency} {event.amount} {event.payment ? ('(' + event.payment.substr(0, 4) + ')') : ''}</div>
                          </div>
                          <div className={classes.calendarContainer}>
                            <div className={classes.calendar}>
                              <ReceiptIcon style={{
                                fontSize: 18,
                                color: '#9F0000',
                                marginLeft: 5,
                                marginTop: 4
                              }} />
                            </div>
                            <div className={classes.next} style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}>{event.reference_number}</div>
                          </div>
                          {event.status &&
                            <div className={classes.statusContainer} onClick={() => handleUpdateStatus(event)}>
                              {event.status &&
                                <a 
                                  className={classes.status} 
                                  href="#" 
                                >
                                  {
                                    event.status == 'On the way' ? 
                                      t('dashboard.ontheway') : 
                                      (event.status == 'Complete' ? t('dashboard.complete') : 
                                        (
                                          event.status.indexOf('On the way') >= 0 ? 
                                            event.status.replace('On the way', t('dashboard.ontheway')) : 
                                            event.status
                                        )
                                      )
                                  }
                                </a>  
                              }
                            </div>
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                  </div>
                </Animated>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  maxWidth = {'sm'}
                >
                  <DialogTitle id="scroll-dialog-title">
                    <div className={classes.titleContainer}>
                      <div className={classes.dialogTitle}>{t('dashboard.outcallserviceinfo')}</div>
                      <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                    </div>
                    <div className={classes.serviceInfoDate}>
                      {selectedevent.request_date}, {selectedevent.time_slot}
                    </div>
                    <div className={classes.buttonContainerFlex}>
                      {selectedevent.status &&
                        <>
                          <Button 
                            aria-label="edit" 
                            onClick={handleEdit}
                            className={classes.editButton}
                          >
                            <EditIcon className={classes.editIcon} />
                            {t('dashboard.editbooking')}
                          </Button>
                          <Button 
                            aria-label="cancel" 
                            onClick={handleCancelBooking}
                            className={classes.cancelButton}
                          >
                            <CancelIcon className={classes.cancelIcon} />
                            {t('dashboard.cancelbooking')}
                          </Button>
                        </>
                      }
                    </div>
                  </DialogTitle>
                  <DialogContent dividers={scroll === 'paper'}>
                    <div className={classes.dialogContent}>
                      <div className={classes.dialogUserContainer}>
                        <div className={classes.dialogUser}>
                          <div>
                            <img
                              className={classes.dialogUserImage}
                              src={API_URL + '/profile/image/' + (selectedevent.created_by_image ? selectedevent.created_by_image : 'default.png')} 
                            />
                          </div>  
                          <div>
                            {selectedevent.first_name} {selectedevent.last_name}
                          </div>
                          <div>
                            {selectedevent.phone_number}
                          </div>
                        </div>
                        <div className={classes.dialogCar}>
                            <div>
                              <img
                                className={classes.dialogCarImage}
                                src={API_URL + '/car/type/image/' + selectedevent.car_type_image} 
                              />
                            </div>
                            <div>
                              {selectedevent.car_type}
                            </div>
                          </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={washmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            {selectedevent.outcall}
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <div
                            style={{
                              width: 36,
                              height: 36,
                              borderRadius: 10,
                              backgroundColor: '#F7F7F7'
                            }}
                          >
                              <AttachMoneyIcon style={{
                              fontSize: 23,
                              color: '#9F0000',
                              marginLeft: 7,
                              marginTop: 6
                            }} />
                          </div>
                          <div className={classes.dialogRightText}> 
                            {selectedevent.currency} {selectedevent.amount} {selectedevent.payment ? ('(' + selectedevent.payment.substr(0, 4) + ')') : ''} 
                            <div
                              style={{
                                width: '104%',
                                marginTop: 10
                              }}
                            ><b>{t('dashboard.referencenumber')}: </b>{selectedevent.reference_number}</div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={pinmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            {!edit && 
                              <>
                                <span>{t('dashboard.housebuilding')} {selectedevent.building}, {t('dashboard.road')} {selectedevent.road}, {t('dashboard.block')} {selectedevent.block}, {selectedevent.location} {drivetime}</span>
                                <div
                                  style={{
                                    marginTop: 10,
                                    marginLeft: -5
                                  }}
                                >
                                  <a href={customerlocation} target="_blank">
                                    <MapIcon className={classes.mapIcon} />
                                  </a>
                                  <a href={whatsapplink} target="_blank">
                                    <WhatsAppIcon className={classes.whatsappIcon} />
                                  </a>
                                  <a href={mailto}>
                                    <EmailIcon className={classes.emailIcon} />
                                  </a>
                                </div>
                                {selectedevent.location_link && selectedevent.location_link != 'null' &&
                                  <div
                                    style={{
                                      marginTop: 10,
                                      marginLeft: -5
                                    }}
                                  >
                                    <a style={{
                                      width: '100%',
                                      wordBreak: 'break-word'
                                    }} href={selectedevent.location_link}>{selectedevent.location_link}</a>
                                  </div>
                                }
                              </>
                            }
                            {edit &&
                              <>
                                <TextField 
                                  id="location"
                                  className={classes.input}
                                  placeholder="Location"
                                  value={location || ''}
                                  onChange={(event) => handleFieldChange(event, 'location')}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: 15,
                                    marginBottom: 15
                                  }}
                                >
                                  <TextField 
                                    id="housebuilding"
                                    className={classes.input3rd}
                                    placeholder="House/Building"
                                    value={houseBuilding || ''}
                                    onChange={(event) => handleFieldChange(event, 'houseBuilding')}
                                  />
                                  <TextField 
                                    id="road"
                                    className={classes.input3rd}
                                    placeholder="Road"
                                    value={road || ''}
                                    onChange={(event) => handleFieldChange(event, 'road')}
                                  />
                                  <TextField 
                                    id="block"
                                    className={classes.input3rd}
                                    placeholder="Block"
                                    value={block || ''}
                                    onChange={(event) => handleFieldChange(event, 'block')}
                                  />
                                </div>
                              </>
                            }
                          </div>
                        </div>
                        {selectedevent.location &&
                          <div id="service-info-map" className={classes.mapContainer}>
                            <LoadScript
                              googleMapsApiKey={PLACES_API_KEY}>
                                <GoogleMap
                                  mapContainerStyle={{height: 319, width: "100%"}}
                                  zoom={zoom}
                                  center={center}
                                  onLoad={onLoad}
                                >
                                  {google &&
                                    <DirectionsService
                                      options={{
                                        origin: selectedevent.address,
                                        destination: selectedevent.location,
                                        travelMode: google.maps.TravelMode.DRIVING
                                      }}
                                      onLoad={directionServiceLoad}
                                    />
                                  }
                                  {directions && 
                                    <DirectionsRenderer 
                                      directions={directions}
                                      polylineOptions={{
                                        strokeOpacity: 0.5,
                                        strokeColor: '#9F0000',
                                      }}
                                    />
                                  }
                                </GoogleMap>
                            </LoadScript>
                          </div>
                        }     
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={calendarmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            <div className={classes.dialogFlexEndContainer}>
                              <div>{t('dashboard.time')}</div>
                              {!edit &&
                                <div className={classes.dialogRight}>{selectedevent.time_slot}</div>
                              }
                              {edit &&
                                <div className={classes.timeslot}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <KeyboardTimePicker
                                          margin="normal"
                                          id="timeslot"
                                          onChange={(date) => handleDateChange(date, 'timeslot')}
                                          KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                          }}
                                          inputVariant="outlined"  
                                          keyboardIcon={<KeyboardArrowDownIcon />}
                                          value={timeslot}
                                      />
                                  </MuiPickersUtilsProvider>
                              </div>
                              }
                            </div>
                            {selectedevent.next_date &&
                              <div className={classes.dialogFlexEndContainer}>
                                <div className={classes.dialogBottomRow}>{selectedevent.status ? ('Next date ' + selectedevent.status.replace('On the way', '')) : ''}</div>
                                <div className={classes.dialogRightLast}>{selectedevent.status ? (selectedevent.next_date ? selectedevent.next_date.substring(0, selectedevent.next_date.indexOf(", "))  : '') : ''}</div>
                              </div>
                            }
                          </div>
                        </div>
                        {!edit &&
                        <div id="service-info-current-calendar">
                          <Calendar
                            isMultiple={true}
                            selected={servicedays} 
                            onChange={handleServiceDayClick}
                          />
                        </div>
                        }
                        {edit &&
                        <div id="service-info-edit-current-calendar">
                          <Calendar
                            onChange={handleServiceDayClick}
                          />
                        </div>
                        }
                        {!edit &&
                          <div id="service-info-next-calendar">
                            {nextservicedays && nextservicedays.length > 0 &&
                              <Calendar
                                isMultiple={true}
                                selected={nextservicedays} 
                                month={moment().get('month') + 1}
                                onChange={handleNextServiceDayClick}
                              />
                            }
                          </div>
                        }
                        {edit &&
                          <div id="service-info-edit-next-calendar">
                            {nextservicedays && nextservicedays.length > 0 &&
                              <Calendar
                                month={moment().get('month') + 1}
                                onChange={handleNextServiceDayClick}
                              />
                            }
                          </div>
                        }
                      </div>
                      {edit && 
                        <div className={classes.saveContainer}>
                          <div className={classes.saveText}>{t('dashboard.save')}</div>
                          <div 
                              onClick={handleSave}
                              className={classes.saveBtnContainer} 
                          >
                              <img 
                                  className={classes.saveBtn} 
                                  src={arrowrightwhiteimage} 
                                  alt="Save" />
                          </div>
                        </div>
                      }
                    </div>
                  </DialogContent>
                </Dialog>
                {renderOtherDialog()}
                {renderBlockTimeDialog()}
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
  
export default Dashboard;