import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid } from '@material-ui/core';
import logoimage from '../images/logo-image.png'; 
import logotext from '../images/logo-text.png'; 
import squareimage from '../images/square.png'; 
import Navbar from '../components/Nav/Navbar';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import bahrainimage from '../images/bahrain.png'; 
import usimage from '../images/us.png'; 
import { useTranslation } from 'react-i18next';

const drawerWidth = 0;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: '#fff',
    boxShadow: 'none',
    paddingTop: 30,
    paddingRight: '5%',
    paddingLeft: '5%'
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: '#000'
  },
  logoImage: {
    width: 65
  },
  logoText: {
    width: 132,
    position: 'absolute',
    top: 20,
    left: 70
  },
  menuItem: {
    marginLeft: 40,
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#000',
    "&:hover": {
        cursor: "pointer",
        color: '#9F0000 !important'
    }
  },
  menuContainer: {
    marginTop: 20
  },
  menuItemLogin: {
    marginLeft: 40,
    fontWeight: 'bold',
    textDecoration: 'none',
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: '#9F0000',
    color: '#fff',
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 12,
    zIndex: 2,
    position: 'relative',
    "&:hover": {
        cursor: "pointer",
        color: '#FFF !important'
    }
  },
  squareImage: {
    width: 127,
    position: 'absolute',
    right: '-5%',
    top: 8,
    zIndex: 0
  },
  arabic: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 'solid 1px #9F0000',
    position: 'absolute',
    marginLeft: -46,
    marginTop: -1,
    "&:hover": {
        cursor: "pointer"
    }
  },
  english: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 'solid 1px #9F0000',
    position: 'absolute',
    marginLeft: -14,
    marginTop: -1,
    "&:hover": {
        cursor: "pointer"
    }
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function WashCenterTopMenu(props) {
  const classes = useStyles();
  const id = localStorage.getItem('id');
  const userId = localStorage.getItem('userId');
  const roleId = localStorage.getItem('roleId');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
        i18n.changeLanguage(language);
    }
  }, [])

  const changeLanguage = code => {
    i18n.changeLanguage(code);
    localStorage.setItem('language', code);
  };

  return (
      <HideOnScroll {...props}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid
            justify="space-between"
            container 
            spacing={24}
          >
            <Grid item>
              <img 
                className={classes.logoImage} 
                src={logoimage} 
                alt="Carcare" />
              <img 
                className={classes.logoText} 
                src={logotext} 
                alt="Carcare" />
            </Grid>
            <Grid item>
              <Hidden mdUp>
                <Navbar />
              </Hidden>
              <Hidden smDown>
                <div className={classes.menuContainer}>
                  <img 
                    className={classes.arabic} 
                    src={bahrainimage} 
                    alt="Arabic" 
                    onClick={() => changeLanguage('ar')}
                  />
                  <img 
                    className={classes.english} 
                    src={usimage} 
                    alt="English" 
                    onClick={() => changeLanguage('en')}
                  />
                  <a
                    className={classes.menuItem} 
                    href="/"
                  >
                    {t('landing.home')}
                  </a>
                  <a
                    className={classes.menuItem} 
                    href="/wash-centers"
                  >
                    {t('landing.allwashcenters')}
                  </a>
                  {!roleId &&
                    <a
                      id="login"
                      className={classes.menuItemLogin}  
                      href="/login"
                    >
                      {t('landing.login')}
                    </a>
                  }
                  {roleId && roleId == 2 &&
                    <a
                      id="dashboard"
                      className={classes.menuItemLogin}  
                      href="/dashboard"
                    >
                      {t('side.dashboard')}
                    </a>
                  }
                  {roleId && roleId == 3 && 
                    <a
                      id="admin"
                      className={classes.menuItemLogin}  
                      href="/admin"
                    >
                      {t('side.admin')}
                    </a>
                  }
                  <img 
                    className={classes.squareImage} 
                    src={squareimage} 
                    alt="Carcare" />
                </div>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      </HideOnScroll>
  );
}

export default WashCenterTopMenu;