import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { I18nContext, useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import starImage from '../images/star.png'; 
import buildingImage from '../images/building.png'; 
import electricityImage from '../images/electricity.png'; 
import vatImage from '../images/vat.jpeg'; 
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    card: {
      backgroundColor: '#f7f7f7',
      borderRadius: 20,
      width: 290,
      marginRight: 10,
      marginBottom: 20
    },
    top: {
      borderBottomWidth: 1,
      borderBottomColor: '#d3d3d3',
      borderBottom: 'solid',
      padding: 20
    },
    bottom: {
      padding: 20
    },
    washContainer: {
      display: 'flex',
      marginBottom: 0
    },
    center: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    centerDetails: {
      position: 'absolute',
      marginLeft: 50,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold',
      width: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    centerNameLink: {
      color: '#000',
      textDecoration: 'none'
    },
    flexContainer: {
      display: 'flex'
    },
    iconWrapper: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    detail: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    statusContainer: {
      textAlign: 'center',
      height: 36,
      backgroundColor: '#9F0000',
      borderRadius: 12,
      paddingTop: 6,
      marginTop: 10,
      "&:hover": {
          cursor: "pointer"
      }
    },
    status: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 14,
      "&:hover": {
          cursor: "pointer",
          color: '#FFF !important'
      }
    },
    formControl: {
      width: 250,
      marginBottom: 20,
      "&:focus": {
        boxShadow: "none",
        outline: "none"
      },
      "&:active": {
        boxShadow: "none",
        outline: "none"
      }
    },
    applyContainer: {
      display: 'inline-block',
      marginTop: 0,
      marginLeft: 80
    },
    ratingsContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      justifyContent: 'flex-start',
      ['@media (max-width:768px)']: { 
        justifyContent: 'flex-start'
      }
    },
    saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -62,
      width: 44
  },
  saveBtnContainer: {
    width: 36,
    height: 36,
    borderRadius: 12,
    backgroundColor: '#9F0000',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  saveBtn: {
    width: 21,
    height: 21,
    paddingLeft: 3,
    position: 'absolute',
    marginLeft: 7,
    marginTop: 6
  },
  deleteIcon: {
    fontSize: 23,
    color: '#9F0000',
    "&:hover": {
        cursor: "pointer"
    }
  },

  activateBuilding: {
    textAlign: 'center',
    padding: `8px 12px`,
    borderRadius: 12,
    backgroundColor: '#F9D2D2',
    color: '#9F0000',
    "&:hover": {
        cursor: "pointer"
    }
  },
  deactivateBuilding: {
    textAlign: 'center',
    padding: `8px 12px`,
    borderRadius: 12,
    backgroundColor: '#FFF',
    border: 'solid 1px #F9D2D2',
    color: '#A7A7A7',
    "&:hover": {
        cursor: "pointer"
    }
  },
  activeStatus: {
    color: '#9F0000',
    marginRight: 10,
  },
  inactiveStatus: {
    color: '#A7A7A7',
    marginRight: 10,
  },
  input: {
    width: '100%',
    fontSize: 16
  },
  buildingInput: {

  },
  saveContainer: {
    marginLeft: 65,
    marginTop: 10
  },
  saveText: {
    color: '#9F0000',
    fontSize: 16,
    fontWeight: 500,
    display: 'inline-block',
    position: 'absolute',
    marginTop: 7,
    marginLeft: -62,
    width: 44
  },
  saveBtnContainer: {
    width: 36,
    height: 36,
    borderRadius: 12,
    backgroundColor: '#9F0000',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  saveBtn: {
    width: 21,
    height: 21,
    paddingLeft: 3,
    position: 'absolute',
    marginLeft: 7,
    marginTop: 6
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  activeBuildingSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  editBuilding: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#f9d2d2',
    textAlign: 'center',
    padding: 8,
    borderRadius: 8,
    color: '#9F0000',
    "&:hover": {
      cursor: 'pointer'
    },
  },
}));

const Admin = () => {
  const classes = useStyles();
  const userId = localStorage.getItem('userId');
  const history = useHistory();
  let [washCenters, updateWashCenters] = useState('');
  const { t, i18n } = useTranslation();
  let [sort, setSort] = useState('');
  let [switchState, setSwitchState] = useState({});
  let [showBuildingSettings, setShowBuildingSettings] = useState(false);
  let [showActiveBuildingSettings, setShowActiveBuildingSettings] = useState(false);
  let [showVatSettings, setShowVatSettings] = useState(false);
  let [showActiveVatSettings, setShowActiveVatSettings] = useState(false);
  let [selectedWashCenter, setSelectedWashCenter] = useState('');
  let [building, setBuilding] = useState('');
  let [vatPercent, setVatPercent] = useState('');
  let [road, setRoad] = useState('');
  let [block, setBlock] = useState('');
  let [switchElectricityState, setSwitchElectricityState] = useState({});
  let [promoText, setPromoText] = useState('');
  let [showPromoText, setShowPromoText] = useState(false);
  let [switchNewCheckoutExperienceState, setSwitchNewCheckoutExperienceState] = useState(false);
  let [switchDashboardGridState, setSwitchDashboardGridState] = useState(false);
  let [switchDashboardNewUIState, setSwitchDashboardNewUIState] = useState(false);
  let [switchVendorConfirmationState, setSwitchVendorConfirmationState] = useState(false);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
        i18n.changeLanguage(language);
    }

    axios.get(API_URL + `/admin-dashboard/wash-centers`)
    .then(response => {
      let wash_centers = response.data.wash_centers;

      if(wash_centers) {
        // Set first as the default id
        let index = 0;
        wash_centers.forEach(wc => {
          if(index == 0) {
            localStorage.setItem('id', wc.user_id);
          }
          index++;
        })
      }

      updateWashCenters(wash_centers);

      axios.get(API_URL + `/business/service-centers/sort`)
      .then(resp => {
        if(resp.data.sort) {
          setSort(resp.data.sort.value);
        }
      })

      setDynamicSwitchState(wash_centers);
      setDynamicElectricitySwitchState(wash_centers);
    })

    axios.get(API_URL + `/app-settings`)
      .then(rspns => {
        let app_settings = rspns.data.app_settings
        if(app_settings && app_settings[0]) {
          setSwitchNewCheckoutExperienceState(app_settings[0].new_checkout_experience == 1 ? true : false);
          setSwitchDashboardGridState(app_settings[0].customer_dashboard_mode == 'grid' ? true : false);
          setSwitchDashboardNewUIState(app_settings[0].customer_dashboard_mode == 'new' ? true : false);
          setSwitchVendorConfirmationState(app_settings[0].vendor_confirmation == 1 ? true : false);
        }
      })
  }, [])
  
  if (!userId) {
    return <Redirect to={'/login'} />;
  } 

  const setDynamicSwitchState = (list) => {
    if (!list) {
      return
    }

    const switchstate = {};

    list.forEach((item, index) => {
        switchstate['switch-' + index] = item.status == 1 ? true : false;
    });

    setSwitchState(switchstate);
  }

  const setDynamicElectricitySwitchState = (list) => {
    if (!list) {
      return
    }

    const switchstate = {};

    list.forEach((item, index) => {
        switchstate['switch-' + index] = item.electricity == 1 ? true : false;
    });

    setSwitchElectricityState(switchstate);
  }

  const openWashCenterDashboard = (businessId) => () => {
    localStorage.setItem('id', businessId);
    history.push("/dashboard");
  };

  function viewDashboard(businessId) {
    localStorage.setItem('id', businessId);
    history.push("/dashboard");
  }

  function renderStarRatings(feedback) {
    var ratings = [];
    if(feedback) {
      for(let i = 0; i < parseInt(feedback); i++){
        ratings.push(<div>
          <img
            src={starImage}
            className="rating-star"
          />
        </div>)
      }
    }
    return ratings;
  }

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  function handleSortClick(e) {
    e.preventDefault();

    let params;
    if(sort == 'rating') {
      params = {
        value: sort
      }
    } else if(sort == 'custom') {
      params = {
        value: sort,
        servicecenters: washCenters
      }
    }

    axios.post(API_URL + `/business/service-center/sort/update`, params)
    .then(res => {
      if(res.data) {
        toast.success(t('services.sortsaved'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(true);
      } else {
        toast.error(t('services.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(washCenters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateWashCenters(items);
  }

  const handleChange = (index, event, checked, centerId) => {
    const list = Object.assign({}, switchState);

    list['switch-' + index] = checked;

    let message = '';
    let post_url = '';
    if(checked) {
        message = t("center.activated");
        post_url = '/business/activate/';
    } else {
        message = t("center.deactivated");
        post_url = '/business/deactivate/';
    }

    axios.post(API_URL + post_url + `${centerId}`, {})
    .then(res => {
        if(res.data && res.data.message) {
            toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setSwitchState(list);
        }
    });
  }

  function handleDelete(id) {
    if(id > 0) {
      axios.post(API_URL + `/business/delete/${id}`, {})
      .then(res => {
          if(res.data && res.data.message) {
            window.location.reload(true);
          }
      });
    }
  }

  function manageBuilding(businessId, status) {
    if(status == 0) {
      let params = {
        id: businessId,
        status: status
      }
      axios.post(API_URL + `/wash-center/building/status`, params)
        .then(res => {
            if(res.data && res.data.message) {
              window.location.reload(true)
            }
        });
    } else {
      setSelectedWashCenter(businessId)
      setShowBuildingSettings(true)
    }
  }

  function manageVat(businessId, status, vat_percent) {
    if(status == 0) {
      let params = {
        id: businessId,
        status: status,
        vat_percent: vat_percent ? vat_percent : 0
      }
      axios.post(API_URL + `/wash-center/vat/status`, params)
        .then(res => {
            if(res.data && res.data.message) {
              window.location.reload(true)
            }
        });
    } else {
      setSelectedWashCenter(businessId)
      setShowVatSettings(true)
    }
  }

  function manageConfirmation(businessId, status) {
    let params = {
      id: businessId,
      status: status
    }
    axios.post(API_URL + `/wash-center/confirmation/status`, params)
      .then(res => {
          if(res.data && res.data.message) {
            window.location.reload(true)
          }
      });
  }

  function toggleBuildingSettings(businessId) {
    let show_status = showActiveBuildingSettings
    setSelectedWashCenter(businessId)
    setShowActiveBuildingSettings(!show_status)
  }

  function toggleVatSettings(businessId) {
    let show_status = showActiveVatSettings
    setSelectedWashCenter(businessId)
    setShowActiveVatSettings(!show_status)
  }

  const handleFieldChange = (event, type) => {
    if(type == 'building') {
      setBuilding(event.target.value);
    } else if(type == 'road') {
      setRoad(event.target.value);
    } else if(type == 'block') {
      setBlock(event.target.value);
    } else if(type == 'text') {
      setPromoText(event.target.value);
    } else if(type == 'vatpercent') {
      setVatPercent(event.target.value);
    }
  };

  function editActiveBuildingSettings(id, building, road, block) {
    setSelectedWashCenter(id)
    setShowActiveBuildingSettings(false)
    setBuilding(building)
    setRoad(road)
    setBlock(block)
    setShowBuildingSettings(true)
  }

  function editActiveVatSettings(id, vat_percent) {
    setSelectedWashCenter(id)
    setShowActiveVatSettings(false)
    setVatPercent(vat_percent)
    setShowVatSettings(true)
  }

  function handleBuildingSettingsClick(e) {
    e.preventDefault();

    if(building && road && block) {
      let payload = {
        id: selectedWashCenter,
        status: 1
      }
      axios.post(API_URL + `/wash-center/building/status`, payload)
        .then(resp => {
            if(resp.data && resp.data.message) {
              let post_url = '/wash-center/building-settings/edit/'
              let message = t("services.enabled")

              let params = {
                enabled: 1,
                building: building,
                road: road,
                block: block
              }
              axios.post(API_URL + post_url + `${selectedWashCenter}`, params)
              .then(res => {
                if(res.data && res.data.message) {
                  toast.success(message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  window.location.reload(true)
                }
              });
            }
        });
    } else {
      toast.error(t('services.error'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleVatSettingsClick(e) {
    e.preventDefault();

    if(vatPercent) {
      let payload = {
        id: selectedWashCenter,
        vat_percent: vatPercent ? vatPercent : 0,
        status: 1
      }
      axios.post(API_URL + `/wash-center/vat/status`, payload)
        .then(resp => {
            if(resp.data && resp.data.message) {
              let message = t("services.enabled")
              toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              window.location.reload(true)
            }
        });
    } else {
      toast.error(t('services.error'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleTextSaveClick(e) {
    let params = {
      text: promoText
    }

    axios.post(API_URL + `/wash-center/text/edit/` + `${selectedWashCenter}`, params)
    .then(res => {
      if(res.data && res.data.message) {
        window.location.reload(true)
      }
    });

    setPromoText('')
    setShowPromoText(false)
  }

  const addEditText = (center) => () => {
    let show_status = showPromoText
    setSelectedWashCenter(center.id)
    setPromoText(center.promo_text)
    setShowPromoText(!show_status)
  };

  const handleElectricityChange = (index, event, checked, centerId) => {
    const list = Object.assign({}, switchElectricityState);

    list['switch-' + index] = checked;

    let message = '';
    if(checked) {
        message = t("center.activated");
    } else {
        message = t("center.deactivated");
    }

    let params = {
      id: centerId,
      status: checked ? 1 : 0
    }

    axios.post(API_URL + `/wash-center/electricity/edit`, params)
    .then(res => {
        if(res.data && res.data.message) {
            toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setSwitchElectricityState(list);
        }
    });
  }

  const handleNewCheckoutExperienceChange = (event, checked) => {
    let post_url = '/app-settings/edit';
    let message = checked ? t("dashboard.settingactivated") : t("dashboard.settingdeactivated");
    let params = {
      mode: 'new_checkout_experience',
      setting: checked ? 1 : 0
    }
    axios.post(API_URL + post_url, params)
    .then(res => {
      if(res.data && res.data.message) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(true)
      } else {
        toast.error(t('services.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  const handleDashboardGridChange = (event, checked) => {
    let post_url = '/app-settings/edit';
    let message = checked ? t("dashboard.settingactivated") : t("dashboard.settingdeactivated");
    let params = {
      mode: 'customer_dashboard_mode',
      setting: checked ? 'grid' : 'new'
    }
    axios.post(API_URL + post_url, params)
    .then(res => {
      if(res.data && res.data.message) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(true)
      } else {
        toast.error(t('services.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  const handleDashboardNewUIChange = (event, checked) => {
    let post_url = '/app-settings/edit';
    let message = checked ? t("dashboard.settingactivated") : t("dashboard.settingdeactivated");
    let params = {
      mode: 'customer_dashboard_mode',
      setting: checked ? 'new' : 'grid'
    }
    axios.post(API_URL + post_url, params)
    .then(res => {
      if(res.data && res.data.message) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(true)
      } else {
        toast.error(t('services.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  const handleVendorConfirmationChange = (event, checked) => {
    let post_url = '/app-settings/edit';
    let message = checked ? t("dashboard.settingactivated") : t("dashboard.settingdeactivated");
    let params = {
      mode: 'vendor_confirmation',
      setting: checked ? 1 : 0
    }
    axios.post(API_URL + post_url, params)
    .then(res => {
      if(res.data && res.data.message) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(true)
      } else {
        toast.error(t('services.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  return (
    <div className="inner">
      <LoggedInTopMenu />
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                    <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                  <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        labelId="sort-select-outlined-label"
                        id="sort-select-outlined"
                        value={sort}
                        onChange={handleSortChange}
                        className={classes.selectSort}
                        displayEmpty
                      >
                        <MenuItem value="">{t('services.selectsorting')}</MenuItem>
                        <MenuItem value={'rating'}>{t('services.sortbyrating')}</MenuItem>
                        <MenuItem value={'custom'}>{t('services.sortcustom')}</MenuItem>
                      </Select>
                    </FormControl>
                    <div className={classes.applyContainer}>
                      <div className={classes.saveText}>{t('services.apply')}</div>
                      <div 
                        onClick={handleSortClick}
                        className={classes.saveBtnContainer} 
                      >
                        <img 
                          className={classes.saveBtn} 
                          src={arrowrightwhiteimage} 
                          alt="Carcare" />
                      </div>
                    </div>
                  </div>
                  <div 
                    style={{
                      marginBottom: 20,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <div style={{marginTop: 10, marginRight: 10}}><strong>{t('dashboard.appsettings')}:</strong></div>
                    <div style={{marginRight: 10}}>
                        <FormControlLabel
                        value="newcheckoutexperience"
                        control={<Switch 
                          key={1}
                          checked={switchNewCheckoutExperienceState || false}
                          onChange={(event, checked) => handleNewCheckoutExperienceChange(event, checked)}
                        />}
                        labelPlacement="start"
                        style={{
                          marginLeft: 0,
                          fontWeight: 'bold',
                          color: '#9F0000'
                        }}
                        label={
                          <Box component="div" fontSize={16}>
                            {t('dashboard.newcheckoutexperience')}
                          </Box>
                        }
                      />
                    </div>
                    <div style={{marginRight: 10}}>
                        <FormControlLabel
                        value="vendorconfirmation"
                        control={<Switch 
                          key={1}
                          checked={switchVendorConfirmationState || false}
                          onChange={(event, checked) => handleVendorConfirmationChange(event, checked)}
                        />}
                        labelPlacement="start"
                        style={{
                          marginLeft: 0,
                          fontWeight: 'bold',
                          color: '#9F0000'
                        }}
                        label={
                          <Box component="div" fontSize={16}>
                            {t('dashboard.vendorconfirmation')}
                          </Box>
                        }
                      />
                    </div>
                    <div style={{marginRight: 10}}>
                        <FormControlLabel
                        value="dashboardgrid"
                        control={<Switch 
                          key={1}
                          checked={switchDashboardGridState || false}
                          onChange={(event, checked) => handleDashboardGridChange(event, checked)}
                        />}
                        labelPlacement="start"
                        style={{
                          marginLeft: 0,
                          fontWeight: 'bold',
                          color: '#9F0000'
                        }}
                        label={
                          <Box component="div" fontSize={16}>
                            {t('dashboard.dashboardgrid')}
                          </Box>
                        }
                      />
                    </div>
                    <div>
                        <FormControlLabel
                        value="dashboardnewui"
                        control={<Switch 
                          key={1}
                          checked={switchDashboardNewUIState || false}
                          onChange={(event, checked) => handleDashboardNewUIChange(event, checked)}
                        />}
                        labelPlacement="start"
                        style={{
                          marginLeft: 0,
                          fontWeight: 'bold',
                          color: '#9F0000'
                        }}
                        label={
                          <Box component="div" fontSize={16}>
                            {t('dashboard.dashboardnew')}
                          </Box>
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.washCentersContainer}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="servicecenters">
                        {(provided) => (
                          <div className={classes.cardContainer} {...provided.droppableProps} ref={provided.innerRef}>
                            {washCenters && washCenters.map((center, index) => (
                              <Draggable key={center.id} draggableId={center.id.toString()} index={index}>
                                {(provided) => (
                                  <div className={classes.card} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <>
                                      <div className={classes.top}>
                                        <div className={classes.washContainer}>
                                          <a 
                                            href="#"
                                            onClick={openWashCenterDashboard(center.user_id)}
                                          >
                                            <img
                                              className={classes.center}
                                              src={API_URL + `/business/image/${center.image}`} 
                                            />
                                          </a>
                                          <div className={classes.centerDetails}>
                                            <a 
                                              href="#" 
                                              className={classes.centerNameLink} 
                                              onClick={openWashCenterDashboard(center.user_id)}
                                            >
                                              {center.business_name}
                                            </a>
                                            <div className={classes.ratingsContainer}>{renderStarRatings(center.rating)}</div>
                                          </div>
                                          <div style={{
                                            position: 'absolute',
                                            marginLeft: 205,
                                            marginTop: -3
                                          }}>
                                            <Switch key={index}
                                              checked={switchState['switch-' + index] || false}
                                              onChange={(event, checked) => handleChange(index, event, checked, center.id)}
                                              value={center.status}
                                            />
                                          </div>
                                          <div 
                                            onClick={() => handleDelete(center.id)}
                                            style={{
                                              position: 'absolute',
                                              marginLeft: 190,
                                              marginTop: 4
                                            }}
                                          >
                                            <DeleteIcon
                                              className={classes.deleteIcon}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className={classes.bottom}>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <EmailIcon style={{
                                              fontSize: 18,
                                              color: '#9F0000',
                                              marginLeft: 5,
                                              marginTop: 4
                                            }} />
                                        </div>
                                        <div className={classes.detail}>
                                          {center.email}
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <PhoneIcon style={{
                                              fontSize: 18,
                                              color: '#9F0000',
                                              marginLeft: 5,
                                              marginTop: 4
                                            }} />
                                        </div>
                                        <div className={classes.detail}>
                                          {center.phone_number}
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <LocationOnIcon style={{
                                              fontSize: 18,
                                              color: '#9F0000',
                                              marginLeft: 5,
                                              marginTop: 4
                                            }} />
                                        </div>
                                        <div className={classes.detail}>
                                          {center.address}
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <LocalCarWashIcon style={{
                                              fontSize: 18,
                                              color: '#9F0000',
                                              marginLeft: 5,
                                              marginTop: 4
                                            }} />
                                        </div>
                                        <div className={classes.detail}>
                                          {center.bookings > 0 ? center.bookings : '-'} {center.bookings > 0 ? t('dashboard.bookings') : ''}
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <AttachMoneyIcon style={{
                                              fontSize: 18,
                                              color: '#9F0000',
                                              marginLeft: 5,
                                              marginTop: 4
                                            }} />
                                        </div>
                                        <div className={classes.detail}>
                                          {center.revenue > 0 ? 'BD ' + parseFloat(center.revenue).toFixed(2) : '-'}
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <img 
                                            style={{
                                              width: 23,
                                              paddingLeft: 5,
                                              paddingTop: 5
                                            }} 
                                            src={electricityImage} 
                                            alt="Carcare" />
                                        </div>
                                        <div style={{
                                          marginTop: -5,
                                          marginLeft: 5
                                        }}>
                                          <Switch key={index}
                                            checked={switchElectricityState['switch-' + index] || false}
                                            onChange={(event, checked) => handleElectricityChange(index, event, checked, center.id)}
                                            value={center.electricity}
                                          />
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <DescriptionIcon style={{
                                            fontSize: 18,
                                            color: '#9F0000',
                                            marginLeft: 5,
                                            marginTop: 4
                                          }} />
                                        </div>
                                        <div 
                                          style={{
                                            fontSize: 14,
                                            marginTop: 5,
                                            marginLeft: 15,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textDecoration: 'underline',
                                            color: '#9f0000'
                                          }}
                                          onClick={addEditText(center)}
                                        >
                                          {center.promo_text ? center.promo_text : t('services.addtext')}
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <ConfirmationNumberIcon style={{
                                            fontSize: 18,
                                            color: '#9F0000',
                                            marginLeft: 5,
                                            marginTop: 4
                                          }} />
                                        </div>
                                        <div className={classes.detail}>
                                          {center.confirmation == 1 &&
                                            <>
                                              <span href="javascript:;" className={classes.activeStatus}>
                                                {t('dashboard.active')}
                                              </span>
                                              <span className={classes.deactivateBuilding} onClick={() => manageConfirmation(center.id, 0)}>
                                                {t('dashboard.deactivate')}
                                              </span>
                                            </>
                                          }
                                          {center.confirmation != 1 &&
                                            <>
                                              <span className={classes.inactiveStatus}>
                                                {t('dashboard.inactive')}
                                              </span>
                                              <span className={classes.activateBuilding} onClick={() => manageConfirmation(center.id, 1)}>
                                                {t('dashboard.activate')}
                                              </span>
                                            </>
                                          }
                                        </div>
                                      </div>
                                      <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <img 
                                            style={{
                                              width: 25,
                                              paddingLeft: 2,
                                              paddingTop: 7
                                            }} 
                                            src={vatImage} 
                                            alt="Carcare" />
                                        </div>
                                        <div className={classes.detail}>
                                          {center.vat == 1 &&
                                            <>
                                              <a href="javascript:;" className={classes.activeStatus} onClick={() => toggleVatSettings(center.id)}>
                                                {t('dashboard.active')} - {center.vat_percent}%
                                              </a>
                                              <span className={classes.deactivateBuilding} onClick={() => manageVat(center.id, 0, center.vat_percent)}>
                                                {t('dashboard.deactivate')}
                                              </span>
                                            </>
                                          }
                                          {center.vat != 1 &&
                                            <>
                                              <span className={classes.inactiveStatus}>
                                                {t('dashboard.inactive')}
                                              </span>
                                              <span className={classes.activateBuilding} onClick={() => manageVat(center.id, 1, center.vat_percent)}>
                                                {t('dashboard.activate')}
                                              </span>
                                            </>
                                          }
                                        </div>
                                      </div>
                                      {center.id == selectedWashCenter && showActiveVatSettings &&
                                        <>
                                          <div className={classes.activeBuildingSettings}><b>{t('dashboard.vatpercent')}:</b> {center.vat_percent}</div>
                                          <div className={classes.editBuilding} onClick={() => editActiveVatSettings(center.id, center.vat_percent)}>
                                            {t('dashboard.edit')}
                                          </div>
                                        </>
                                      }
                                      {center.id == selectedWashCenter && showVatSettings &&
                                        <div>
                                          <div className={classes.buildingInput}>
                                            <TextField 
                                              id="vatpercent"
                                              label={t('dashboard.vatpercent')} 
                                              className={classes.input}
                                              placeholder={t('dashboard.vatpercent')}
                                              value={vatPercent || ''}
                                              onChange={(event) => handleFieldChange(event, 'vatpercent')}
                                            />
                                          </div>
                                          <div className={classes.flexEnd}>
                                            <div className={classes.saveContainer}>
                                              <div className={classes.saveText}>{t('services.apply')}</div>
                                              <div 
                                                  onClick={handleVatSettingsClick}
                                                  className={classes.saveBtnContainer} 
                                              >
                                                <img 
                                                  className={classes.saveBtn} 
                                                  src={arrowrightwhiteimage} 
                                                  alt="Carcare" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      {/* <div className={classes.flexContainer}>
                                        <div className={classes.iconWrapper}>
                                          <img 
                                            style={{
                                              width: 23,
                                              paddingLeft: 5,
                                              paddingTop: 5
                                            }} 
                                            src={buildingImage} 
                                            alt="Carcare" />
                                          </div>
                                          <div className={classes.detail}>
                                            {center.building == 1 &&
                                              <>
                                                <a href="javascript:;" className={classes.activeStatus} onClick={() => toggleBuildingSettings(center.id)}>
                                                  {t('dashboard.active')}
                                                </a>
                                                <span className={classes.deactivateBuilding} onClick={() => manageBuilding(center.id, 0)}>
                                                  {t('dashboard.deactivatebuilding')}
                                                </span>
                                              </>
                                            }
                                            {center.building != 1 &&
                                              <>
                                                <span className={classes.inactiveStatus}>
                                                  {t('dashboard.inactive')}
                                                </span>
                                                <span className={classes.activateBuilding} onClick={() => manageBuilding(center.id, 1)}>
                                                  {t('dashboard.activatebuilding')}
                                                </span>
                                              </>
                                            }
                                          </div>
                                      </div>
                                      {center.id == selectedWashCenter && showActiveBuildingSettings &&
                                        <>
                                          <div className={classes.activeBuildingSettings}><b>{t('dashboard.housebuilding')}:</b> {center.bldg}</div>
                                          <div className={classes.activeBuildingSettings}><b>{t('dashboard.road')}:</b> {center.road}</div>
                                          <div className={classes.activeBuildingSettings}><b>{t('dashboard.block')}:</b> {center.block}</div>
                                          <div className={classes.editBuilding} onClick={() => editActiveBuildingSettings(center.id, center.bldg, center.road, center.block)}>
                                            {t('dashboard.edit')}
                                          </div>
                                        </>
                                      }
                                      {center.id == selectedWashCenter && showBuildingSettings &&
                                        <div>
                                          <div className={classes.buildingInput}>
                                            <TextField 
                                              id="building"
                                              label={t('services.buildingname')} 
                                              className={classes.input}
                                              placeholder={t('services.buildingname')}
                                              value={building || ''}
                                              onChange={(event) => handleFieldChange(event, 'building')}
                                            />
                                          </div>
                                          <div className={classes.buildingInput}>
                                            <TextField 
                                              id="road"
                                              label={t('services.road')} 
                                              className={classes.input}
                                              placeholder={t('services.road')}
                                              value={road || ''}
                                              onChange={(event) => handleFieldChange(event, 'road')}
                                            />
                                          </div>
                                          <div className={classes.buildingInput}>
                                            <TextField 
                                              id="block"
                                              label={t('services.block')} 
                                              className={classes.input}
                                              placeholder={t('services.block')}
                                              value={block || ''}
                                              onChange={(event) => handleFieldChange(event, 'block')}
                                            />
                                          </div>
                                          <div className={classes.flexEnd}>
                                            <div className={classes.saveContainer}>
                                              <div className={classes.saveText}>{t('services.apply')}</div>
                                              <div 
                                                  onClick={handleBuildingSettingsClick}
                                                  className={classes.saveBtnContainer} 
                                              >
                                                <img 
                                                  className={classes.saveBtn} 
                                                  src={arrowrightwhiteimage} 
                                                  alt="Carcare" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      } */}
                                      {center.id == selectedWashCenter && showPromoText &&
                                        <div style={{
                                          display: 'flex',
                                          flexDirection: 'row'
                                        }}>
                                          <div style={{
                                            flex: 0.8
                                          }}>
                                            <TextField 
                                              id="text"
                                              label={t('services.text')} 
                                              style={{
                                                width: '100%',
                                                fontSize: 14
                                              }}
                                              placeholder={t('services.text')}
                                              value={promoText || ''}
                                              onChange={(event) => handleFieldChange(event, 'text')}
                                            />
                                          </div>
                                          <div style={{
                                            flex: 0.2
                                          }}>
                                            <div 
                                              onClick={handleTextSaveClick}
                                              style={{
                                                width: 28,
                                                height: 28,
                                                borderRadius: 8,
                                                backgroundColor: '#9F0000',
                                                display: 'inline-block',
                                                "&:hover": {
                                                  cursor: 'pointer'
                                                },
                                                marginTop: 17,
                                                marginLeft: 20
                                              }} 
                                            >
                                              <SaveIcon style={{
                                                fontSize: 18,
                                                color: '#FFF',
                                                marginLeft: 5,
                                                marginTop: 4
                                              }} />
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      <div className={classes.statusContainer}>
                                        <div className={classes.status} onClick={() => viewDashboard(center.user_id)}>
                                          {t('dashboard.viewdashboard')}
                                        </div>
                                      </div>
                                    </div>
                                    </>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
            </div>
        </Grid>
      </div>
    </div>
  );
};
  
export default Admin;