import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopMenu from '../components/TopMenu';
import MainContent from '../components/MainContent';
import BackToTop from "react-back-to-top-button";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pusher from 'pusher-js';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { PUSHER_APP_KEY } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  scrollUp: {
    backgroundColor: '#9F0000'
  }
}));

function Home(props) {
  const classes = useStyles();
  const search = useLocation().search;
  const history = useHistory();

  useEffect(() => {
    const tap_id = new URLSearchParams(search).get("tap_id");
    if(tap_id){
      history.push("/tap-response?tap_id=" + tap_id)
    }

    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: 'ap2',
      encrypted: true
    });
    var channel = pusher.subscribe('my-channel-1');
    channel.bind('my-event', function(data) {
      console.log(JSON.stringify(data));
    });
  }, [])

  return (
    <div className={classes.root}>
      <TopMenu />
      <MainContent />
      <BackToTop
        showOnScrollUp
        showAt={100}
        speed={1500}
        easing="easeInOutQuint"
      >
        <Fab className={classes.scrollUp} color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </BackToTop>
    </div>
  );
}

export default Home;