import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import axios from 'axios';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:768px)']: { 
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    businessName: {
        fontSize: 30,
        fontWeight: 'bold'
    },
    logoContainer: {
        paddingTop: 24,
        paddingBottom: 24
    },
    businessImage: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        borderRadius: 12
    },
    fieldsContainer: {
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingRight: 48,
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    inputContainer: {
        width: '100%',
        height: 65
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    leftBusinessPanel: {
        ['@media (max-width:768px)']: { 
          width: '90%'
        }
    },
    rightBusinessPanel: {
        textAlign: 'left'
    },
    openingHoursContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: -8,
        ['@media (max-width:768px)']: { 
            width: '100%',
            display: 'block'
        }
    },
    day: {
        position: 'relative',
        marginTop: 10,
        marginRight: 15,
        width: '20%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    startTime: {
        width: '35%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    endTime: {
        width: '35%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    opening: {
        marginTop: 38
    },
    changeLogo: {
        backgroundColor: 'transparent',
        border: 'none',
        fontFamily: 'Nunito',
        fontSize: 16,
        position: 'absolute',
        marginTop: 30,
        marginLeft: 20,
        "&:focus": {
            boxShadow: "none",
            outline: "none"
        },
        "&:active": {
            boxShadow: "none",
            outline: "none"
        },
        "&:hover": {
            cursor: "pointer"
        }
    },
    saveContainer: {
        float: 'right',
        marginRight: 42
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: 7,
        marginTop: 6
    }
}));

const AdminProfile = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const { t, i18n } = useTranslation();

    const inputFile = useRef(null);
    const file = useRef(null);

    let [user, setUser] = useState('');
    let [firstName, setFirstName] = useState('');
    let [lastName, setLastName] = useState('');
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    let [phone, setPhone] = useState('');
    let [address, setAddress] = useState('');

    const [state, setState] = useState({
        file: null
    });

    useEffect(() => {
        axios.get(API_URL + `/user/${userId}`)
            .then(res => {
                let user = res.data.user;
                setUser(user);
                setFirstName(user.first_name);
                setLastName(user.last_name);
                setEmail(user.email);
                setPhone(user.phone_number);
                setAddress(user.home_address);
            })
    }, [])

    if (!userId) {
        return <Redirect to={'/login'} />;
    }

    const onButtonClick = () => {
       inputFile.current.click();
    };

    const handleFileChange = (event) => {
        var inputfile = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(inputfile);

        reader.onloadend = function (e) {
            setState({
                file: [reader.result],
                inputFile: inputfile
            })
        }.bind(this);
    };

    function handleClick(e) {
        e.preventDefault();

        let timezoneOffset = new Date().getTimezoneOffset();
        timezoneOffset = timezoneOffset * -1;

        const formData = new FormData();

        if(state.file) {
            let random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6);
            formData.append("image", state.inputFile);
        }

        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('phone_number', phone);
        formData.append('email', email);
        formData.append('address', address);
        if(password) {
            formData.append('password', password);
        }

        axios.post(API_URL + `/admin-profile/update/${userId}`, formData)
        .then(res => {
            if(res.data && res.data.user) {
                toast.success(t("profile.success"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if(state.file) {
                    localStorage.setItem('image', state.inputFile.name);
                }
                localStorage.setItem('name', firstName + ' ' + lastName);
                localStorage.setItem('email', email);
                window.location.reload(true);
            } else {
                toast.error(t("profile.error"), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'firstName': 
                setFirstName(event.target.value);
                break;
            case 'lastName':
                setLastName(event.target.value);
                break;
            case 'email': 
                setEmail(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;
            case 'phone':
                setPhone(event.target.value);
                break;
            case 'address': 
                setAddress(event.target.value);
                break;
        }
    };

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                    <ToastContainer />
                    <Grid
                        container
                    >
                        <div className={classes.leftBusinessPanel}>
                            <div className={classes.businessName}>{user.first_name} {user.last_name}</div>
                            <div className={classes.logoContainer}>
                                {!state.file &&
                                    <img
                                        className={classes.businessImage}
                                        src={API_URL + '/profile/image/' + user.image} 
                                    />
                                }
                                {state.file &&
                                    <img
                                        className={classes.businessImage}
                                        src={state.file} 
                                    />
                                }
                                <input type='file' id='file' name="image" ref={inputFile} style={{display: 'none'}} onChange={handleFileChange} />
                                <button className={classes.changeLogo} onClick={onButtonClick}>{t('profile.changelogo')}</button>
                            </div>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="firstname"
                                        label={t('profile.firstname')} 
                                        className={classes.input}
                                        placeholder={t('profile.firstname')} 
                                        value={firstName || ''}
                                        onChange={(event) => handleFieldChange(event, 'firstName')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="name"
                                        label={t('profile.lastname')}  
                                        className={classes.input}
                                        placeholder={t('profile.lastname')} 
                                        value={lastName || ''}
                                        onChange={(event) => handleFieldChange(event, 'lastName')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="email"
                                        label={t('profile.emailaddress')}  
                                        className={classes.input}
                                        placeholder={t('profile.emailaddress')} 
                                        value={email || ''}
                                        onChange={(event) => handleFieldChange(event, 'email')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="password"
                                        label={t('profile.password')} 
                                        className={classes.input}
                                        placeholder={t('profile.password')} 
                                        type="password"
                                        value={password || ''}
                                        onChange={(event) => handleFieldChange(event, 'password')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="phoneNumber"
                                        label={t('profile.telephonenumber')} 
                                        className={classes.input}
                                        placeholder={t('profile.telephonenumber')} 
                                        value={phone || ''}
                                        onChange={(event) => handleFieldChange(event, 'phone')}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextField 
                                        id="address"
                                        label={t('profile.address')}  
                                        className={classes.input}
                                        placeholder={t('profile.address')} 
                                        value={address || ''}
                                        onChange={(event) => handleFieldChange(event, 'address')}
                                    />
                                </div>
                            </div>
                            <div className={classes.saveContainer}>
                                <div className={classes.saveText}>{t('profile.save')} </div>
                                <div 
                                    onClick={handleClick}
                                    className={classes.saveBtnContainer} 
                                >
                                    <img 
                                        className={classes.saveBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Carcare" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </div>
            </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default AdminProfile;