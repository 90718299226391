import React, { useRef, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { API_URL, PLACES_API_KEY } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Title from "../components/Title";
import OtherTopMenu from '../components/OtherTopMenu';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';

import TextField from '@material-ui/core/TextField';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import washingcenterimage from '../images/washing-center.png'; 
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Geocode from "react-geocode";
import axios from 'axios';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Radio from '@material-ui/core/Radio';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import Calendar from 'react-calendar-multiday';

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: 130,
        marginLeft: '-6%'
    },
    sideMenu: {
        backgroundColor: '#9F0000',
        width: 256,
        height: `calc(100vh - 130px)`,
        borderTopRightRadius: 75,
        color: '#fff',
        paddingTop: 70
    },
    mainContent: {
        width: `calc(100% - 256px)`,
        textAlign: 'left',
        ['@media (max-width:959)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    article: {
        paddingTop: 20,
        paddingLeft: 50
    },
    pageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: 20
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,
        "&:hover": {
            cursor: 'pointer'
        },
    },
    addBookingContainer: {
        paddingTop: 30,
        float: 'right'
    },
    addBookingText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -105,
        width: 100
    },
    addBookingBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    addBookingBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: 7,
        marginTop: 6
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -44,
        width: 55
    },
    arrowIcon: {
        fontSize: 18,
        color: '#9F0000',
        marginLeft: 5,
        marginTop: 4,
        "&:hover": {
            cursor: 'pointer'
        },
    },
    sectionTitle: {
        fontSize: 20,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    sectionArrowContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    fieldsContainer: {
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%'
    },
    selectService: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    timeContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '85%',
        margin: `0 auto`,
        marginTop: 30,
        paddingLeft: 40
    },
    amountFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,
        borderTop: `solid 1px #d3d3d3`,
        paddingTop: 10,
        "&:hover": {
            cursor: 'pointer'
        },
    },
    totalSectionTitle: {
        fontSize: 20,
        textTransform: 'uppercase'
    },
}));

const AddBooking = () => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const history = useHistory()
    let [language, setLanguage] = useState('')
    let [customerView, setCustomerView] = useState(false)
    let [serviceView, setServiceView] = useState(false)
    let [dateTimeView, setDateTimeView] = useState(false)
    let [discountView, setDiscountView] = useState(false)
    let [paymentStatusView, setPaymentStatusView] = useState(false)
    let [notesView, setNotesView] = useState(false)
    let [summaryView, setSummaryView] = useState(true)
    let [sectionViewTitle, setSectionViewTitle] = useState('')
    let [customer, setCustomer] = useState({})
    let [service, setService] = useState({})
    let [dateTime, setDateTime] = useState({})
    let [discount, setDiscount] = useState({})
    let [discountAmount, setDiscountAmount] = useState('')
    let [selectedDiscount, setSelectedDiscount] = useState('')
    let [discountTypes, setDiscountTypes] = useState([])
    let [paymentStatusValues, setPaymentStatusValues] = useState([])
    let [paymentStatus, setPaymentStatus] = useState({})
    let [selectedPaymentStatus, setSelectedPaymentStatus] = useState('')
    let [guestName, setGuestName] = useState('')
    let [email, setEmail] = useState('')
    let [phone, setPhone] = useState('')
    let [area, setArea] = useState('')
    let [building, setBuilding] = useState('')
    let [road, setRoad] = useState('')
    let [block, setBlock] = useState('')
    let [locationLink, setLocationLink] = useState('')
    let [notes, setNotes] = useState('')
    let [roleId, setRoleId] = useState('')
    let [services, setServices] = useState('')
    let [svc, setSvc] = useState('')
    let [washCenters, setWashCenters] = useState('')
    let [washCenter, setWashCenter] = useState('')
    let [carTypes, setCarTypes] = useState('')
    let [carType, setCarType] = useState('')
    let [carTypeId, setCarTypeId] = useState('')
    let [totalAmount, setTotalAmount] = useState('')
    let [selectedServiceDetails, setSelectedServiceDetails] = useState('')
    let [selectedDate, setSelectedDate] = useState('')
    let [times, setTimes] = useState('')
    let [bookingDate, setBookingDate] = useState('')
    let [bookingDateValue, setBookingDateValue] = useState('')
    let [bookingDateDisplay, setBookingDateDisplay] = useState('')
    let [bookingTime, setBookingTime] = useState('')
    let [bookingTimeDisplay, setBookingTimeDisplay] = useState('')
    let [bookingTimeValue, setBookingTimeValue] = useState('')
    let [serviceTimes, updateServiceTimes] = useState('')
    let [serviceTimes1, updateServiceTimes1] = useState('')

    const notesRef = useRef('')

    useEffect(() => {
        const language = localStorage.getItem('language')
        if(language) {
            i18n.changeLanguage(language)
        }
        setLanguage(language)

        let paymentStatusValues = [
            {
                'type_en': 'Paid',
                'type_ar': 'مدفوع',
                'type_value': 'paid'
            },
            {
                'type_en': 'Not paid',
                'type_ar': 'غير مدفوع',
                'type_value': 'not_paid'
            }
        ]
        setPaymentStatusValues(paymentStatusValues)

        let discountTypes = [
            {
                'type_en': 'Amount',
                'type_ar': 'كمية',
                'type_value': 'amount'
            },
            {
                'type_en': 'Percent',
                'type_ar': 'كمية',
                'type_value': 'percent'
            }
        ]
        setDiscountTypes(discountTypes)

        const role = localStorage.getItem('roleId')
        setRoleId(role)

        axios.get(API_URL + `/car/types`)
        .then(response => {
            let car_types = response.data.car_types
            setCarTypes(car_types)
        })

        if(role == 3) {
            axios.get(API_URL + `/admin-dashboard/wash-centers`)
            .then(response => {
                let wash_centers = response.data.wash_centers
                setWashCenters(wash_centers)
            })
        } else {
            const id = localStorage.getItem('id')
            axios.get(API_URL + `/business-details/${id}`)
            .then(res => {
                let business = res.data.business ? res.data.business[0] : []
                setWashCenter(business.id)

                // Get services
                axios.get(API_URL + `/wash-center/services/all/${business.id}`)
                .then(response => {
                    setServices(response.data.services[0].services)
                })
            })
        }
        
    }, [])

    async function addBooking(e) {
        e.preventDefault()

        if(
            washCenter 
            && customer && customer?.guestname
            && service && service.service
            && bookingDate && bookingTime
            && selectedPaymentStatus
        ) {
            let crt = []
            let event_date
            let event_name
            let selected_date = bookingDateValue
            let selected_time = bookingTimeValue
            let selected_current = []
            let selected_next = []

            let start_time_slot
            if(selectedServiceDetails && selectedServiceDetails.times > 0) {
                start_time_slot = moment(new Date()).format("YYYY-MM-DD") + " " + selected_time + ":00"
            } else {
                start_time_slot = moment(selected_date).format("YYYY-MM-DD") + " " + selected_time + ":00"
            }

            let current = selected_current
            let next = selected_next

            crt.push({
                service: service.service,
                object_id: service.serviceid,
                start_time_slot: start_time_slot,
                amount: totalAmount,
                current_dates: current ? current.map( (data) => { return data.date }).join() : '',
                next_dates: next ? next.map( (data) => { return data.date }).join() : '',
                quantity: 1,
                car_type_id: carTypeId,
                car_type: carType
            })

            event_name = service.service
            event_date = start_time_slot

            let params = {
                business_id: washCenter,
                car_type_id: carTypeId,
                total_amount: totalAmount,
                location: area,
                latitude: 0,
                longitude: 0,
                road: road,
                block: block,
                building: building,
                locationlink: locationLink,
                object_type: 'outcall',
                currency: service.currency,
                guest_name: guestName,
                email: email,
                phone_number: phone,
                cart: crt,
                manual_booking: 1,
                paid: paymentStatus.type_value == 'paid' ? 1 : 0,
                discount_type: discount && discount.type_value ? discount.type_value : '',
                discount_amount: discountAmount ? discountAmount : 0,
                notes: notes,
            }
            let response = await axios.post(API_URL + `/booking/manual`, params)
            if(response.data && response.data.message == 'success') {
                history.push("/statement")
            }
        }
    }

    function showCustomer(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.customer'))
        setSummaryView(false)
        setCustomerView(true)
        setServiceView(false)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(false)
    }

    function showService(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.service'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(true)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(false)
    }

    function showDateTime(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.dateandtime'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(true)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(false)
    }

    function showDiscount(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.discount'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(false)
        setDiscountView(true)
        setPaymentStatusView(false)
        setNotesView(false)
    }

    function showPaymentStatus(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.paymentstatus'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(true)
        setNotesView(false)
    }

    function showNotes(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.notes'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(true)
    }

    function resetView() {
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(false)
        setSummaryView(true)
    }

    function handleClose(e) {
        resetView()
    }

    function saveSectionView(e) {
        if(sectionViewTitle == 'Customer') {
            if(guestName && phone) {
                let cust = {
                    guestname: guestName,
                    email: email,
                    phonenumber: phone,
                    area: area,
                    building: building,
                    road: road,
                    block: block,
                    locationlink: locationLink
                }
                setCustomer(cust)
            } else {
                let cust = {}
                setCustomer(cust)
            }
        }

        if(sectionViewTitle == 'Service') {
            if(selectedServiceDetails && totalAmount && parseFloat(totalAmount) > 0 && washCenter && carTypeId) {
                let wc_service = {
                    washcenterid: washCenter,
                    currency: 'BD',
                    serviceid: svc,
                    service: selectedServiceDetails.name,
                    cartypeid: carTypeId,
                    totalamount: totalAmount
                }
                setService(wc_service)
            } else {
                let wc_service = {}
                setService(wc_service)
            }
        }

        if(sectionViewTitle == 'Date & Time') {
            if(selectedDate && bookingTime) {
                let date_value = moment(selectedDate).format("YYYY-MM-DD")
                let date_display = moment(selectedDate).format("ddd MMM D")
                setBookingDate(date_value)
                setBookingDateValue(date_value)
                setBookingDateDisplay(date_display)
            }
        }

        resetView()
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'guestname': 
                setGuestName(event.target.value)
                break
            case 'email': 
                setEmail(event.target.value)
                break
            case 'phone': 
                setPhone(event.target.value)
                break
            case 'area': 
                setArea(event.target.value)
                break
            case 'building': 
                setBuilding(event.target.value)
                break
            case 'road': 
                setRoad(event.target.value)
                break
            case 'block': 
                setBlock(event.target.value)
                break
            case 'locationlink': 
                setLocationLink(event.target.value)
                break
            case 'notes': 
                setNotes(event.target.value)
                break
            case 'discount': 
                let amount = event.target.value
                let disc = ''
                let total_amount = service.totalamount
                if(amount && discount.type_value == 'amount') {
                    disc = 'BD ' + amount
                    total_amount = total_amount - amount
                    total_amount = parseFloat(total_amount).toFixed(2)
                } else if(amount && discount.type_value == 'percent') {
                    disc = amount + '%'
                    total_amount = total_amount - (total_amount * (amount / 100))
                    total_amount = parseFloat(total_amount).toFixed(2)
                }
                setTotalAmount(total_amount)
                setDiscountAmount(amount)
                setSelectedDiscount(disc)
        }
    }

    const handlePaymentStatusChange = (event) => {
        if(paymentStatusValues) {
            paymentStatusValues.forEach(p => {
                if(p.type_value == event.target.value) {
                    setPaymentStatus(p)
                    let translation_key = 'addbooking.' + event.target.value.replace('_', '')
                    setSelectedPaymentStatus(t(translation_key))
                }
            })
        }
    }

    const handleDiscountChange = (event) => {
        if(discountTypes) {
            discountTypes.forEach(d => {
                if(d.type_value == event.target.value) {
                    setDiscount(d)
                    let total_amount = service.totalamount
                    if(discountAmount) {
                        let disc = ''
                        if(event.target.value == 'amount') {
                            disc = 'BD ' + discountAmount
                            total_amount = total_amount - discountAmount
                            total_amount = parseFloat(total_amount).toFixed(2)
                        } else {
                            disc = discountAmount + '%'
                            total_amount = totalAmount - (total_amount * (discountAmount / 100))
                            total_amount = parseFloat(total_amount).toFixed(2)
                        }
                        setSelectedDiscount(disc)
                        setTotalAmount(total_amount)
                    } else {
                        setSelectedDiscount('')
                    }
                }
            })
        }
    }

    const handleServiceChange = (event) => {
        let service_id = event.target.value
        let car_type_id = 1
        let car_type = 'Small'

        // Get services
        axios.get(API_URL + `/wash-center/service/${service_id}/2`)
        .then(response => {
            let details = response.data.service
            let total_amount = details.small_price
            setTotalAmount(total_amount)
            setSelectedServiceDetails(details)
        })

        setCarTypeId(1)
        setCarType(car_type)
        setSvc(service_id)
    }

    const handleWashCenterChange = (event) => {
        setWashCenter(event.target.value)

        // Get services
        axios.get(API_URL + `/wash-center/services/all/${event.target.value}`)
        .then(response => {
            setServices(response.data.services[0].services)
        })
    }

    const handleCarTypeChange = (event) => {
        setCarTypeId = event.target.value
        if(carTypes) {
            carTypes.forEach(ct => {
                if(ct.id == event.target.value) {
                    setCarType(ct.type)

                    if(selectedServiceDetails)  {
                        let total_amount 
                        if(ct.type == 'Small') {
                            total_amount = selectedServiceDetails.small_price
                        } else if(ct.type == 'Medium') {
                            total_amount = selectedServiceDetails.medium_price
                        } else if(ct.type == 'Large') {
                            total_amount = selectedServiceDetails.large_price
                        }
                        setTotalAmount(total_amount)
                    }
                }
            })
        }
    }

    const handleDateClick = async(ob) => {
        console.log(ob.current)
        if(ob.current) {
            setSelectedDate(ob.current)

            let date = ob.current
            let date_value = moment(date).format("YYYY-MM-DD")
            let date_display = moment(date).format("ddd MMM D")
            var dayOfWeek = new Date(date_value).getDay()
            if(dayOfWeek == 0) {
                dayOfWeek = 7;
            }   

            let res =  await axios.get(API_URL + `/business/available/hours/new/${washCenter}/${dayOfWeek}`)
            let bhs = res.data.available_hours

            let resp = await axios.get(API_URL + `/wash-center/calendar/hours/${washCenter}`)
            let breakTimeHours = []
            if(resp.data) {
                breakTimeHours = resp.data.calendar_hours.breakTimeHours
            }

            let bh = [];
            if(bhs) {
                let current_time = moment(new Date()).utcOffset('+0300').add(1, 'hours').format('HH:mm');
                let current_date = moment(new Date()).utcOffset('+0300').format('YYYY-MM-DD');
                if(current_date == date_value) {
                    bhs.forEach(ah => {
                        if(ah.time_value >= current_time) {
                            bh.push({
                                "id": ah.id,
                                "time": ah.time,
                                "time_value": ah.time_value,
                                "time_display": ah.time_display,
                                "status": ah.status
                            })
                        }
                    })
                    bhs = bh;
                }
            }
            
            let respon = await axios.get(API_URL + `/business/service-slots/${washCenter}`)
            let serviceSlots = respon.data.service_slots
            if(date_value && bhs) {
                let business_hours = bhs;
                bhs = [];
                business_hours.forEach(bh => {
                    let match = serviceSlots.some(a => a.date === date_value && a.time === (bh.time_value + ':00') && a.status == 'full');
                    let status = bh.status;
                    if(match) {
                        status = 'full';
                    }

                    let breakMatch = breakTimeHours.some(a => a.dayOfWeek == dayOfWeek && a.startTime == bh.time_value)
                    if(breakMatch) {
                        status = 'full';
                    }

                    bhs.push({
                        id: bh.id,
                        time: bh.time,
                        time_value: bh.time_value,
                        time_display: bh.time_display,
                        status: status
                    });
                })
            }

            let rsp = await axios.get(API_URL + `/business/disabled/dates/${washCenter}`)
            let disabledDates = rsp.data.disabled_days
            let disabledDate = false;
            if(disabledDates) {
                if(disabledDates.indexOf(date_value) >= 0) {
                    disabledDate = true
                    bhs = []
                }
            }

            // Check if no available time
            let busy = false
            if(bhs) {
                let avail = bhs.filter(h => {
                    return h.status != 'full'
                })
                if(!avail || (avail && avail.length == 0)) {
                    busy = true
                }
            }

            updateServiceTimes(bhs)
        }
    }

    function onSingleTimeChange(time) {
        if(time.status != 'full') {
            setBookingTimeDisplay(time.time_display)
            setBookingTime(time.time)
            setBookingTimeValue(time.time_value)
        }
    }

    return (
        <div className='inner'>
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.pageHeader}>{t('side.addbooking')}</div>
                            <div className={classes.center}>
                                {summaryView &&
                                    <>
                                        <div className={classes.flexContainer} onClick={showCustomer}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.customer')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: customer && customer?.guestname ? 1 : 0.25
                                                    }}
                                                >
                                                    {customer && customer?.guestname ? customer?.guestname : t('addbooking.enter')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showService}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.service')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: service && service?.service ? 1 : 0.25
                                                    }}
                                                >
                                                    {service && service.service ? service.service : t('addbooking.select')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showDateTime}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.dateandtime')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: bookingDate && bookingTime ? 1 : 0.25
                                                    }}
                                                >
                                                    {bookingDate && bookingTime ? (bookingDateDisplay + ', ' + bookingTimeDisplay) : t('addbooking.pick')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showDiscount}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.discount')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: selectedDiscount ? 1 : 0.25
                                                    }}
                                                >
                                                    {selectedDiscount ? selectedDiscount : t('addbooking.apply')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showPaymentStatus}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.paymentstatus')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: selectedPaymentStatus ? 1 : 0.25
                                                    }}
                                                >
                                                    {selectedPaymentStatus ? selectedPaymentStatus : t('addbooking.pick')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showNotes}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.notes')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: notes ? 1 : 0.25
                                                    }}
                                                >
                                                    {notes ? notes : t('addbooking.enter')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        {totalAmount &&
                                            <div className={classes.amountFlexContainer}>
                                                <div className={classes.totalSectionTitle}>
                                                    {t('addbooking.total')}
                                                </div>
                                                <div className={classes.sectionArrowContainer}>
                                                    <div 
                                                        style={{
                                                            paddingTop: 2,
                                                            opacity: 1,
                                                            fontWeight: 'bold',
                                                            fontSize: 18
                                                        }}
                                                    >
                                                        BD {totalAmount}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className={classes.addBookingContainer}>
                                            <div className={classes.addBookingText}>{t('side.addbooking')}</div>
                                            <div 
                                                onClick={addBooking}
                                                className={classes.addBookingBtnContainer} 
                                            >
                                                <img 
                                                    className={classes.addBookingBtn} 
                                                    src={arrowrightwhiteimage} 
                                                    alt="CarCare" />
                                            </div>
                                        </div>
                                    </>
                                }
                                {!summaryView &&
                                    <>
                                        <div className={classes.header}>
                                            <div className={classes.title}>{sectionViewTitle}</div>
                                            <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                        </div>
                                        <div className={classes.fieldsContainer}>
                                            <form>
                                                {sectionViewTitle == 'Customer' &&
                                                    <>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="name"
                                                                label={t('addbooking.name')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.name')}
                                                                value={guestName || ''}
                                                                onChange={(event) => handleFieldChange(event, 'guestname')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="email"
                                                                label={t('addbooking.email')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.email')}
                                                                value={email || ''}
                                                                onChange={(event) => handleFieldChange(event, 'email')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="phone"
                                                                label={t('addbooking.phone')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.phone')}
                                                                value={phone || ''}
                                                                onChange={(event) => handleFieldChange(event, 'phone')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="area"
                                                                label={t('addbooking.area')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.area')}
                                                                value={area || ''}
                                                                onChange={(event) => handleFieldChange(event, 'area')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="building"
                                                                label={t('addbooking.housebuilding')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.housebuilding')}
                                                                value={building || ''}
                                                                onChange={(event) => handleFieldChange(event, 'building')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="road"
                                                                label={t('addbooking.road')}
                                                                multiline
                                                                className={classes.input}
                                                                placeholder={t('addbooking.road')}
                                                                value={road || ''}
                                                                onChange={(event) => handleFieldChange(event, 'road')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="area"
                                                                label={t('addbooking.block')}
                                                                multiline
                                                                className={classes.input}
                                                                placeholder={t('addbooking.block')}
                                                                value={block || ''}
                                                                onChange={(event) => handleFieldChange(event, 'block')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="locationlink"
                                                                label={t('addbooking.locationlink')}
                                                                multiline
                                                                className={classes.input}
                                                                placeholder={t('addbooking.locationlink')}
                                                                value={locationLink || ''}
                                                                onChange={(event) => handleFieldChange(event, 'locationlink')}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {sectionViewTitle == 'Service' &&
                                                    <>
                                                        {roleId == 3 &&
                                                            <div id="washcenter" className={classes.inputContainerDesc}>
                                                                <FormControl variant="outlined" className={classes.formControl}>
                                                                    <NativeSelect
                                                                        value={washCenter}
                                                                        onChange={handleWashCenterChange}
                                                                        className={classes.selectService}
                                                                        input={<BootstrapInput />}
                                                                    >
                                                                        <option aria-label="None" value="">{t('addbooking.selectwashcenter')}</option>
                                                                        {washCenters && washCenters.map((item) => (
                                                                            <option value={item.id}>{item.business_name}</option>
                                                                        ))}
                                                                    </NativeSelect>
                                                                </FormControl>
                                                            </div>
                                                        }
                                                        <div id="service" className={classes.inputContainerDesc}>
                                                            <FormControl variant="outlined" className={classes.formControl}>
                                                                <NativeSelect
                                                                    value={svc}
                                                                    onChange={handleServiceChange}
                                                                    className={classes.selectService}
                                                                    input={<BootstrapInput />}
                                                                >
                                                                    <option aria-label="None" value="">{t('services.selectservice')}</option>
                                                                    {services && services.map((item) => (
                                                                        <option value={item.id}>{item.service_name}</option>
                                                                    ))}
                                                                </NativeSelect>
                                                            </FormControl>
                                                        </div>
                                                        <div className={styles.flexContainer}>
                                                            {carTypes && carTypes.map((ct) => (
                                                                <div>
                                                                    <FormControlLabel
                                                                        value="end" 
                                                                        control={<Radio 
                                                                            checked={carType == ct.type}
                                                                            onChange={handleCarTypeChange}
                                                                            value={ct.id}
                                                                            name="radio-button-demo"
                                                                            inputProps={{ 'aria-label': ct.type }} />} 
                                                                            label={ct.type} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="amount"
                                                                label={t('addbooking.amount')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.amount')}
                                                                value={totalAmount || ''}
                                                                readonly
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {sectionViewTitle == 'Date & Time' &&
                                                    <>
                                                        <div id="service-info-edit-current-calendar">
                                                            <Calendar
                                                                isMultiple={false}
                                                                selected={selectedDate} 
                                                                onChange={handleDateClick}
                                                            />
                                                        </div>
                                                        <div className={classes.timeContainer}>
                                                            {serviceTimes && serviceTimes.map((time, i) => (
                                                                // <div
                                                                //     onClick={onSingleTimeChange(time)}
                                                                // >
                                                                <div
                                                                    onClick={() => onSingleTimeChange(time)}
                                                                    style={{
                                                                        border: `solid 1px rgb(159, 0, 0)`,
                                                                        borderRadius: 5,
                                                                        padding: 10,
                                                                        marginRight: 10,
                                                                        width: 100,
                                                                        textAlign: 'center',
                                                                        alignItems: 'center',
                                                                        marginBottom: 10,
                                                                        backgroundColor: bookingTime == time.time ? '#9F0000' : '#FFFFFF'
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            fontSize: 14,
                                                                            fontFamily: 'Avenir-Roman',
                                                                            color: time?.status == 'full' ? '#a7a7a7' : (bookingTime == time.time ? '#FFFFFF' : '#9F0000')
                                                                        }}
                                                                    >
                                                                        {time.time}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                                {sectionViewTitle == 'Discount' &&
                                                    <div>
                                                        {discountTypes && discountTypes.map((d) => (
                                                            <div>
                                                                <FormControlLabel 
                                                                    value="end" 
                                                                    control={<Radio 
                                                                        checked={discount.type_value == d.type_value}
                                                                        onChange={handleDiscountChange}
                                                                        value={d.type_value}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': d.type_en }} />} 
                                                                    label={localStorage.getItem('language') == 'ar' ? (d.type_ar ? d.type_ar : d.type_en) : d.type_en} />
                                                            </div>
                                                        ))}
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="discount"
                                                                label={t('addbooking.discount')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.discount')}
                                                                value={discountAmount || ''}
                                                                onChange={(event) => handleFieldChange(event, 'discount')}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {sectionViewTitle == 'Payment Status' &&
                                                    <div>
                                                        {paymentStatusValues && paymentStatusValues.map((ps) => (
                                                            <div>
                                                                <FormControlLabel
                                                                    value="end" 
                                                                    control={<Radio 
                                                                        checked={paymentStatus.type_value == ps.type_value}
                                                                        onChange={handlePaymentStatusChange}
                                                                        value={ps.type_value}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': ps.type_en }} />} 
                                                                    label={localStorage.getItem('language') == 'ar' ? (ps.type_ar ? ps.type_ar : ps.type_en) : ps.type_en} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {sectionViewTitle == 'Notes' &&
                                                    <div className={classes.inputContainerDesc}>
                                                        <TextField 
                                                            id="notes"
                                                            label={t('addbooking.notes')}
                                                            multiline
                                                            className={classes.input}
                                                            placeholder={t('addbooking.notes')}
                                                            value={notes || ''}
                                                            onChange={(event) => handleFieldChange(event, 'notes')}
                                                        />
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                        <div className={classes.addBookingContainer}>
                                            <div className={classes.saveText}>{t('addbooking.save')}</div>
                                            <div 
                                                onClick={saveSectionView}
                                                className={classes.addBookingBtnContainer} 
                                            >
                                                <img 
                                                    className={classes.addBookingBtn} 
                                                    src={arrowrightwhiteimage} 
                                                    alt="CarCare" />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default AddBooking;

