import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 959px) {
    flex-flow: column nowrap;
    background-color: #9F0000;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 7.5rem;
    margin-top: 0px;
    padding-left: 0px;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;

const RightNav = ({ open }) => {
  const id = localStorage.getItem('id');
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const language = localStorage.getItem('language');
      if(language) {
          i18n.changeLanguage(language);
      }
  }, [])

  function handleLogout(e) {
    localStorage.clear()
    history.push("/login")
  }

  return (
    <Ul open={open}>
      <li><a href="#howitworks">{t('landing.howitworks')}</a></li>
      <li><a href="#ourservices">{t('landing.ourservices')}</a></li>
      <li><a href="/wash-centers">{t('landing.allwashcenters')}</a></li>
      <li><a href="#partnerwithus">{t('landing.partnerwithus')}</a></li>
      <li><a href="#contact">{t('landing.contact')}</a></li>
      {id > 0 &&
        <li><a href="#" onClick={handleLogout}>{t('side.logout')}</a></li>
      }
      {!id && 
        <li><a href="/login">{t('landing.login')}</a></li>
      }
    </Ul>
  )
}

export default RightNav