import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { API_URL, PLACES_API_KEY } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Title from "../components/Title";
import OtherTopMenu from '../components/OtherTopMenu';
import TextField from '@material-ui/core/TextField';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import washingcenterimage from '../images/washing-center.png'; 
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Geocode from "react-geocode";
import axios from 'axios';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 48,
        paddingRight: 48,
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    terms: {
        color: '#a7a7a7',
        fontSize: 14,
        textAlign: 'center',
        marginTop: 120
    },
    registerContainer: {
        paddingTop: 60,
        float: 'right'
    },
    registerText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -70,
        width: 44
    },
    registerBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    registerBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: -11,
        marginTop: 6
    },
    errorMessage: {
        color: '#fff',
        fontSize: 14,
        marginTop: 9,
        backgroundColor: '#cc0000',
        padding: 10,
        textAlign: 'center',
        fontWeight:'bold'
    },
    stepNo: {
        marginTop: 115,
        color: '#9F0000',
        fontWeight: 'bold',
        fontSize: 18
    },
    washingCenter: {
        width: 80,
        height: 80,
        borderRadius: 18,
        backgroundColor: '#f9d2d2',
        margin: `0 auto`,
        paddingTop: 22
    },
    washingCenterImage: {
        width: 30,
        height: 30,
        objectFit: 'cover'
    },
    dot: {
        width: 10,
        height: 10,
        margin: `0 auto`,
        marginTop: 70,
        marginBottom: 12,
        backgroundColor: '#9F0000',
        borderRadius: '50%'
    },
    washingCenterText: {
        marginTop: 24,
        color: '#b20000',
        fontSize: 16
    },
    nextContainer: {
        paddingTop: 180,
        float: 'right'
    },
    nextText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -50,
        width: 44
    },
    nextBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    nextBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: -11,
        marginTop: 6
    },
    goBackContainer: {
        float: 'left',
        paddingTop: 185,
        "&:hover": {
            cursor: "pointer"
        }
    },
    nextContainer1: {
        paddingTop: 60,
        float: 'right'
    },
    goBackContainer1: {
        float: 'left',
        paddingTop: 65,
        "&:hover": {
            cursor: "pointer"
        }
    },
    rightForward: {
        position: 'relative',
        float: 'right',
        marginTop: -30
    },
    rightForwardIcon: {
        color: '#9F0000',
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 205,
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    businessLogoContainer: {
        width: 80,
        height: 80,
        borderRadius: 18,
        backgroundColor: '#f9d2d2',
        margin: `0 auto`,
        marginTop: 60
    },
    addLogoContainer: {
        width: 80,
        height: 80,
        borderRadius: 18,
        backgroundColor: '#f9d2d2',
        margin: `0 auto`,
        paddingTop: 20,
        marginTop: 60
    },
    addLogoIcon: {
        fontSize: 40,
        color: '#9F0000',
        "&:hover": {
            cursor: "pointer"
        }
    },
    businessImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 18
    },
    openingHoursContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: -8,
        ['@media (max-width:768px)']: { 
            width: '100%',
            display: 'block'
        }
    },
    day: {
        position: 'relative',
        marginTop: 10,
        marginRight: 15,
        width: '23%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    startTime: {
        width: '35%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    endTime: {
        width: '35%',
        ['@media (max-width:768px)']: { 
            width: '100%'
        }
    },
    nextContainer2: {
        paddingTop: 60,
        float: 'right'
    },
}));

const Register = () => {
    const classes = useStyles();
    const inputFile = useRef(null);
    const file = useRef(null);
    let [step, setStep] = useState(false);
    let [businessname, setBusinessname] = useState('');
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    let [phone, setPhone] = useState('');
    let [address, setAddress] = useState('');
    let [typeaddress, setTypeaddress] = useState('');
    let [latitude, setLatitude] = useState('');
    let [longitude, setLongitude] = useState('');
    let [center, setCenter] = useState('');
    let [zoom, setZoom] = useState('');
    let [google, setGoogle] = useState('');
    let [openinghours, setOpeninghours] = useState('');
    const history = useHistory();

    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);

    const [mondayStartTime, setMondayStartTime] = useState('');
    const [tuesdayStartTime, setTuesdayStartTime] = useState('');
    const [wednesdayStartTime, setWednesdayStartTime] = useState('');
    const [thursdayStartTime, setThursdayStartTime] = useState('');
    const [fridayStartTime, setFridayStartTime] = useState('');
    const [saturdayStartTime, setSaturdayStartTime] = useState('');
    const [sundayStartTime, setSundayStartTime] = useState('');

    const [mondayEndTime, setMondayEndTime] = useState('');
    const [tuesdayEndTime, setTuesdayEndTime] = useState('');
    const [wednesdayEndTime, setWednesdayEndTime] = useState('');
    const [thursdayEndTime, setThursdayEndTime] = useState('');
    const [fridayEndTime, setFridayEndTime] = useState('');
    const [saturdayEndTime, setSaturdayEndTime] = useState('');
    const [sundayEndTime, setSundayEndTime] = useState('');
    const [state, setState] = useState({
        file: null
    });

    useEffect(() => {
        setStep(0);
        setCenter({lat: 26.258085, lng: 50.616755});
        setZoom(13);

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');

        if(key) {
            axios.get(API_URL + `/register/key/${key}`)
            .then(res => {
                let result = res.data.key;
                if(result) {
                    
                } else {
                    history.push('/not-found')
                }
            })
        } else {
            history.push('/not-found');
        }
    }, [])

    function handleStep0(e) {
        e.preventDefault();

        if(businessname && name && email && password) {
            setStep(1);
        } else {
            toast.error("Please fill up required fields", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function handleStep1(e) {
        setStep(2);
    }

    function handleStep2(e) {
        // if(phone && address && openinghours) {
        if(phone && openinghours) {
            setStep(5);
        } else {
            toast.error("Please fill up required fields", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function handleGoBackStep0(e) {
        setStep(0);
    }

    function handleGoBackStep1(e) {
        setStep(1);
    }

    function handleGoBackStep2(e) {
        setStep(2);
    }

    function handleAddress(e) {
        setStep(3);
    }

    function handleOpeningHours(e) {
        setStep(4);
    }

    function handleSaveOpeningHours(e) {
        let timezoneOffset = new Date().getTimezoneOffset();
        timezoneOffset = timezoneOffset * -1;
        let start_day = '';
        let end_day = '';
        let time = '';
        if(monday) {
            start_day = 'Monday';

            let start_time = moment(mondayStartTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
            let end_time = moment(mondayEndTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
            time = start_time + ' - ' + end_time
        }
        if(tuesday) {
            if(start_day) {
                end_day = 'Tuesday';
            } else {
                start_day = 'Tuesday';
             
                let start_time = moment(tuesdayStartTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                let end_time = moment(tuesdayEndTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                time = start_time + ' - ' + end_time
            }
        }   
        if(wednesday) {
            if(start_day) {
                end_day = 'Wednesday';
            } else {
                start_day = 'Wednesday';
             
                let start_time = moment(wednesdayStartTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                let end_time = moment(wednesdayEndTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                time = start_time + ' - ' + end_time
            }
        }  
        if(thursday) {
            if(start_day) {
                end_day = 'Thursday';
            } else {
                start_day = 'Thursday';
             
                let start_time = moment(thursdayStartTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                let end_time = moment(thursdayEndTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                time = start_time + ' - ' + end_time
            }
        }  
        if(friday) {
            if(start_day) {
                end_day = 'Friday';
            } else {
                start_day = 'Friday';
             
                let start_time = moment(fridayStartTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                let end_time = moment(fridayEndTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                time = start_time + ' - ' + end_time
            }
        }  
        if(saturday) {
            if(start_day) {
                end_day = 'Saturday';
            } else {
                start_day = 'Saturday';
             
                let start_time = moment(saturdayStartTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                let end_time = moment(saturdayEndTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                time = start_time + ' - ' + end_time
            }
        }  
        if(sunday) {
            if(start_day) {
                end_day = 'Sunday';
            } else {
                start_day = 'Sunday';
             
                let start_time = moment(sundayStartTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                let end_time = moment(sundayEndTime).utc().utcOffset(timezoneOffset).format("h:mm A").toString();
                time = start_time + ' - ' + end_time
            }
        }  

        setOpeninghours(start_day + ' - ' + end_day + ', ' + time);
        setStep(2);
    }

    function handleSaveAddress(e) {
        setAddress(typeaddress);
        setStep(2);
    }

    function handleClose(e) {
        setStep(2);
    }

    function handleAddLogo(e) {
        inputFile.current.click();
    }

    const handleFileChange = (event) => {
        var inputfile = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(inputfile);

        reader.onloadend = function (e) {
            setState({
                file: [reader.result],
                inputFile: inputfile
            })
        }.bind(this);
    };

    const handleDayChange = (event) => {
        switch(event.target.name) {
            case 'monday': 
                setMonday(event.target.checked)
                break;
            case 'tuesday': 
                setTuesday(event.target.checked)
                break;  
            case 'wednesday': 
                setWednesday(event.target.checked)
                break; 
            case 'thursday': 
                setThursday(event.target.checked)
                break; 
            case 'friday': 
                setFriday(event.target.checked)
                break;  
            case 'saturday': 
                setSaturday(event.target.checked)
                break;
            case 'sunday': 
                setSunday(event.target.checked)
                break;  
        }
    };

    const handleDateChange = (date, type) => {
        switch(type) {
            case 'mondayStartTime': 
                setMondayStartTime(date)
                break;
            case 'mondayEndTime': 
                setMondayEndTime(date)
                break;
            case 'tuesdayStartTime': 
                setTuesdayStartTime(date)
                break;
            case 'tuesdayEndTime': 
                setTuesdayEndTime(date)
                break;  
            case 'wednesdayStartTime': 
                setWednesdayStartTime(date)
                break; 
            case 'wednesdayEndTime': 
                setWednesdayEndTime(date)
                break; 
            case 'thursdayStartTime': 
                setThursdayStartTime(date)
                break; 
            case 'thursdayEndTime': 
                setThursdayEndTime(date)
                break; 
            case 'fridayStartTime': 
                setFridayStartTime(date)
                break;  
            case 'fridayEndTime': 
                setFridayEndTime(date)
                break; 
            case 'saturdayStartTime': 
                setSaturdayStartTime(date)
                break;
            case 'saturdayEndTime': 
                setSaturdayEndTime(date)
                break;
            case 'sundayStartTime': 
                setSundayStartTime(date)
                break;  
            case 'sundayEndTime': 
                setSundayEndTime(date)
                break;  
        }
    };

    const handleTypeAddressChange = (event, type) => {
        if(event.target.value.length > 2) {
            // Get coordinates from address
            // setLatitude(26.258085);
            // setLongitude(50.616755);

            Geocode.setApiKey(PLACES_API_KEY);
            Geocode.setLanguage("en");
            Geocode.setRegion("bh");
            Geocode.fromAddress(event.target.value).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setLatitude(lat)
                    setLongitude(lng);
                    setCenter({lat: lat, lng: lng});
                },
                error => {
                  console.error(error);
                }
            );
        }
        setTypeaddress(event.target.value);
    }

    function onLoad() {
        if(window.google && window.google.maps) {
          setGoogle(window.google);
        }
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'businessname': 
                setBusinessname(event.target.value);
                break;
            case 'name':
                setName(event.target.value);
                break;
            case 'email': 
                setEmail(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;
            case 'phone':
                setPhone(event.target.value);
                break;
        }
    };

    function handleRegister(e) {
        e.preventDefault();
        if(state.file) {
            // Register 
            let timezoneOffset = new Date().getTimezoneOffset();
            timezoneOffset = timezoneOffset * -1;

            const userformData = new FormData();
            const businessformData = new FormData();

            if(state.file) {
                let random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 6);
                userformData.append("image", state.inputFile);
                businessformData.append("image", state.inputFile);
            }

            userformData.append('phone_number', phone);
            userformData.append('email', email);
            userformData.append('password', password);

            let first_name = '';
            let last_name = '';
            if(name) {
                let n = name.split(' ');
                console.log('n: ' + n.length);
                if(n && n.length > 1) {
                    first_name = n[0];
                    last_name = n[1];
                } else {
                    first_name = n;
                    last_name = ' ';
                }
                userformData.append('first_name', first_name);
                userformData.append('last_name', last_name);
            }

            businessformData.append('business_name', businessname);
            businessformData.append('name', name);
            businessformData.append('email', email);
            businessformData.append('password', password);
            businessformData.append('business_type_id', 2);
            businessformData.append('phone_number', phone);
            businessformData.append('address', address);
            businessformData.append('latitude', latitude);
            businessformData.append('longitude', longitude);
            businessformData.append('monday_start_time', !mondayStartTime ? '' : moment(mondayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('monday_end_time', !mondayEndTime ? '' : moment(mondayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('tuesday_start_time', !tuesdayStartTime ? '' : moment(tuesdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('tuesday_end_time', !tuesdayEndTime ? '' : moment(tuesdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('wednesday_start_time', !wednesdayStartTime ? '' : moment(wednesdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('wednesday_end_time', !wednesdayEndTime ? '' : moment(wednesdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('thursday_start_time', !thursdayStartTime ? '' : moment(thursdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('thursday_end_time', !thursdayEndTime ? '' : moment(thursdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('friday_start_time', !fridayStartTime ? '' : moment(fridayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('friday_end_time', !fridayEndTime ? '' : moment(fridayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('saturday_start_time', !saturdayStartTime ? '' : moment(saturdayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('saturday_end_time', !saturdayEndTime ? '' : moment(saturdayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('sunday_start_time', !sundayStartTime ? '' : moment(sundayStartTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());
            businessformData.append('sunday_end_time', !sundayEndTime ? '' : moment(sundayEndTime).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm:ss").toString());

            axios.post(API_URL + `/user/profile/create`, userformData)
            .then(response => {
                axios.post(API_URL + `/business/register`, businessformData)
                .then(res => {
                    if(res.data && res.data.business) {
                        toast.success("Your business profile has been created", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(function () {
                            history.push("/login")
                        }, 3000);
                    } else {
                        toast.error("There was an error processing your request", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch(error => {
                    toast.error("There was an error processing your request", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }).catch(error => {
                axios.post(API_URL + `/business/register`, businessformData)
                .then(res => {
                    if(res.data && res.data.business) {
                        setTimeout(function () {
                            history.push("/login")
                        }, 5000);
                    } else {
                        toast.error("There was an error processing your request", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            });
        } else {
            toast.error("Please fill up required fields", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
      <div className='inner'>
            <OtherTopMenu />
            {step == 0 && 
                <Title lineContent='Register' lineContent2='Please fill in fields below' />
            }
            {step == 1 && 
                <div className="register-step">
                    <div className={classes.stepNo}>1/3</div>
                    <Title lineContent='Type of business' lineContent2='Are you a Washing Center?' />
                </div>
            }
            {step == 2 && 
                <div className="register-step step-2">
                    <div className={classes.stepNo}>2/3</div>
                    <Title lineContent='Let your customers find your business' lineContent2='Add telephone number, address and opening hours' />
                </div>
            }
            {step == 5 && 
                <div className="register-step">
                    <div className={classes.stepNo}>3/3</div>
                    <Title lineContent='Add logo' lineContent2='Upload a photo of your logo' />
                </div>
            }
            <div className={classes.fieldsContainer}>
                <ToastContainer />
                <form>
                    {step == 0 &&
                        <div>
                            <div className={classes.inputContainer}>
                                <TextField 
                                    id="businessname"
                                    label="Name of the business" 
                                    className={classes.input}
                                    placeholder="Name of the business"
                                    value={businessname || ''}
                                    onChange={(event) => handleFieldChange(event, 'businessname')}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <TextField 
                                    id="name"
                                    label="Name & Last Name" 
                                    className={classes.input}
                                    placeholder="Name & Last Name"
                                    value={name || ''}
                                    onChange={(event) => handleFieldChange(event, 'name')}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <TextField 
                                    id="email"
                                    label="Email address" 
                                    className={classes.input}
                                    placeholder="Email address"
                                    type="email"
                                    value={email || ''}
                                    onChange={(event) => handleFieldChange(event, 'email')}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <TextField 
                                    id="password" 
                                    label="Password" 
                                    type="password"
                                    className={classes.input}
                                    placeholder="Password"
                                    value={password || ''}
                                    onChange={(event) => handleFieldChange(event, 'password')}
                                />
                                <div className={classes.registerContainer}>
                                    <div className={classes.registerText}>Register</div>
                                    <div 
                                        onClick={handleStep0}
                                        className={classes.registerBtnContainer} 
                                    >
                                        <img 
                                            className={classes.registerBtn} 
                                            src={arrowrightwhiteimage} 
                                            alt="Register" />
                                    </div>
                                </div>
                                <div className={classes.terms}>
                                    By clicking “Register” you agree to the Terms and Conditions
                                </div>
                            </div>
                        </div>
                    }
                    {step == 1 && 
                        <div>
                            <div className={classes.dot}></div>
                            <div className={classes.washingCenter}>
                                <img 
                                    className={classes.washingCenterImage} 
                                    src={washingcenterimage} 
                                    alt="Washing Center" />
                            </div>    
                            <div className={classes.washingCenterText}>Washing Center</div>
                            <div className={classes.nextContainer}>
                                <div className={classes.nextText}>Next</div>
                                <div 
                                    onClick={handleStep1}
                                    className={classes.nextBtnContainer} 
                                >
                                    <img 
                                        className={classes.nextBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Next" />
                                </div>
                            </div>
                            <div 
                                className={classes.goBackContainer}
                                onClick={handleGoBackStep0}
                            >
                                Go back
                            </div>
                        </div>
                    }
                    {step == 2 &&
                        <div>
                            <div className={classes.inputContainer}>
                                <TextField 
                                    id="phone"
                                    label="Telephone number" 
                                    className={classes.input}
                                    placeholder="Telephone number"
                                    value={phone || ''}
                                    onChange={(event) => handleFieldChange(event, 'phone')}
                                />
                            </div>
                            {/* <div className={classes.inputContainer}>
                                <TextField 
                                    id="address"
                                    label="Address" 
                                    className={classes.input}
                                    placeholder="Address"
                                    value={address || ''}
                                />
                                <div 
                                    onClick={handleAddress}
                                    className={classes.rightForward}
                                >
                                    <ChevronRightIcon className={classes.rightForwardIcon} />
                                </div>
                            </div> */}
                            <div className={classes.inputContainer}>
                                <TextField 
                                    id="openinghours"
                                    label="Opening hours" 
                                    className={classes.input}
                                    placeholder="Opening hours"
                                    value={openinghours || ''}
                                />
                                <div 
                                    onClick={handleOpeningHours}
                                    className={classes.rightForward}
                                >
                                    <ChevronRightIcon className={classes.rightForwardIcon} />
                                </div>
                            </div>
                            <div className={classes.nextContainer1}>
                                <div className={classes.nextText}>Next</div>
                                <div 
                                    onClick={handleStep2}
                                    className={classes.nextBtnContainer} 
                                >
                                    <img 
                                        className={classes.nextBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Next" />
                                </div>
                            </div>
                            <div 
                                className={classes.goBackContainer1}
                                onClick={handleGoBackStep1}
                            >
                                Go back
                            </div>
                        </div>
                    }
                    {step == 3 &&
                        <div>
                            <div className={classes.header}>
                                <div className={classes.title}>Add your business address</div>
                                <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                            </div>
                            <div className={classes.inputContainer}>
                                <TextField 
                                    id="typeaddress"
                                    label="Start typing address" 
                                    className={classes.input}
                                    placeholder="Start typing address"
                                    value={typeaddress || ''}
                                    onChange={(event) => handleTypeAddressChange(event, 'address')}
                                />
                            </div>
                            {latitude &&
                                <>
                                    <LoadScript googleMapsApiKey={PLACES_API_KEY}>
                                        <GoogleMap
                                            mapContainerStyle={{height: 319, width: "100%"}}
                                            zoom={zoom}
                                            center={center}
                                            onLoad={onLoad}
                                        >
                                            <Marker
                                                position={{
                                                    lat: latitude,
                                                    lng: longitude
                                                }}
                                            />
                                        </GoogleMap>
                                    </LoadScript>
                                    <div className={classes.nextContainer2}>
                                        <div className={classes.nextText}>Save</div>
                                        <div 
                                            onClick={handleSaveAddress}
                                            className={classes.nextBtnContainer} 
                                        >
                                            <img 
                                                className={classes.nextBtn} 
                                                src={arrowrightwhiteimage} 
                                                alt="Save" />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                    {step == 4 &&
                        <div>
                            <div className={classes.header}>
                                <div className={classes.title}>Opening hours</div>
                                <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                            </div>
                            <div id="step-opening" className={classes.opening}>
                                <div className={classes.openingHoursContainer}>
                                    <div id="monday" className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={monday} 
                                                    onChange={handleDayChange} 
                                                    name="monday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Monday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="monday-start-time"
                                                onChange={(date) => handleDateChange(date, 'mondayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={mondayStartTime}
                                                disabled={!monday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="monday-end-time"
                                                onChange={(date) => handleDateChange(date, 'mondayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={mondayEndTime}
                                                disabled={!monday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div id="tuesday" className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={tuesday} 
                                                    onChange={handleDayChange} 
                                                    name="tuesday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Tuesday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="tuesday-start-time"
                                                onChange={(date) => handleDateChange(date, 'tuesdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={tuesdayStartTime}
                                                disabled={!tuesday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="tuesday-end-time"
                                            onChange={(date) => handleDateChange(date, 'tuesdayEndTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            inputVariant="outlined"  
                                            keyboardIcon={<KeyboardArrowDownIcon />}
                                            value={tuesdayEndTime}
                                            disabled={!tuesday}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={wednesday} 
                                                    onChange={handleDayChange} 
                                                    name="wednesday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Wednesday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="wednesday-start-time"
                                                onChange={(date) => handleDateChange(date, 'wednesdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={wednesdayStartTime}
                                                disabled={!wednesday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="wednesday-end-time"
                                            onChange={(date) => handleDateChange(date, 'wednesdayEndTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            inputVariant="outlined"  
                                            keyboardIcon={<KeyboardArrowDownIcon />}
                                            value={wednesdayEndTime}
                                            disabled={!wednesday}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={thursday} 
                                                    onChange={handleDayChange} 
                                                    name="thursday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Thursday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="thursday-start-time"
                                                onChange={(date) => handleDateChange(date, 'thursdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={thursdayStartTime}
                                                disabled={!thursday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            margin="normal"
                                            id="thursday-end-time"
                                            onChange={(date) => handleDateChange(date, 'thursdayEndTime')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            inputVariant="outlined"  
                                            keyboardIcon={<KeyboardArrowDownIcon />}
                                            value={thursdayEndTime}
                                            disabled={!thursday}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div id="friday" className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={friday} 
                                                    onChange={handleDayChange} 
                                                    name="friday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Friday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="friday-start-time"
                                                onChange={(date) => handleDateChange(date, 'fridayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={fridayStartTime}
                                                disabled={!friday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="friday-end-time"
                                                onChange={(date) => handleDateChange(date, 'fridayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={fridayEndTime}
                                                disabled={!friday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={saturday} 
                                                    onChange={handleDayChange} 
                                                    name="saturday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Saturday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="saturday-start-time"
                                                onChange={(date) => handleDateChange(date, 'saturdayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={saturdayStartTime}
                                                disabled={!saturday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="saturday-end-time"
                                                onChange={(date) => handleDateChange(date, 'saturdayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={saturdayEndTime}
                                                disabled={!saturday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className={classes.openingHoursContainer}>
                                    <div id="sunday" className={classes.day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={sunday} 
                                                    onChange={handleDayChange} 
                                                    name="sunday" 
                                                    style ={{
                                                        color: "#9F0000",
                                                    }}
                                                />
                                            }
                                            label="Sunday"
                                        />
                                    </div>
                                    <div className={classes.startTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="sunday-start-time"
                                                onChange={(date) => handleDateChange(date, 'sundayStartTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={sundayStartTime}
                                                disabled={!sunday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className={classes.endTime}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="sunday-end-time"
                                                onChange={(date) => handleDateChange(date, 'sundayEndTime')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                inputVariant="outlined"  
                                                keyboardIcon={<KeyboardArrowDownIcon />}
                                                value={sundayEndTime}
                                                disabled={!sunday}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.nextContainer2}>
                                <div className={classes.nextText}>Save</div>
                                <div 
                                    onClick={handleSaveOpeningHours}
                                    className={classes.nextBtnContainer} 
                                >
                                    <img 
                                        className={classes.nextBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Next" />
                                </div>
                            </div>
                        </div>
                    }
                    {step == 5 &&
                        <div>
                            <input type='file' id='file' name="image" ref={inputFile} style={{display: 'none'}} onChange={handleFileChange} />
                            {!state.file &&
                                <div 
                                    onClick={handleAddLogo}
                                    className={classes.addLogoContainer}
                                >
                                    <AddIcon className={classes.addLogoIcon} />
                                </div>
                            }
                            {state.file &&
                                <div 
                                    onClick={handleAddLogo}
                                    className={classes.businessLogoContainer}
                                >
                                    <img
                                        className={classes.businessImage}
                                        src={state.file} 
                                    />
                                </div>
                            }
                            <div className={classes.nextContainer}>
                                <div className={classes.nextText}>Log in</div>
                                <div 
                                    onClick={handleRegister}
                                    className={classes.nextBtnContainer} 
                                >
                                    <img 
                                        className={classes.nextBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Log in" />
                                </div>
                            </div>
                            <div 
                                className={classes.goBackContainer}
                                onClick={handleGoBackStep2}
                            >
                                Go back
                            </div>
                        </div>
                    }
                </form>
            </div>
      </div>
    );
};
  
export default Register;