import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Masonry } from "masonic";
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import WashCenterTopMenu from '../components/WashCenterTopMenu';
import BackToTop from "react-back-to-top-button";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    scrollUp: {
      backgroundColor: '#9F0000'
    },
    container: {
        minHeight: '100vh',
        width: '100%'
    },
    masonic: {
        padding: 8,
        width: '100%',
        maxWidth: 800,
        margin: `163px auto 0`,
        // boxSizing: 'border-box'
    }
}));

const WashCenters = () => {
    const classes = useStyles();
    let [washCenters, setWashCenters] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }

        axios.get(API_URL + `/admin-dashboard/wash-centers`)
        .then(response => {
            let wash_centers = response.data.wash_centers;
            wash_centers = wash_centers.filter(wc => {
                return wc.status == 1
            })
            setWashCenters(wash_centers);
        })
      }, [])

    return (
        <div className={classes.root}>
            <WashCenterTopMenu />
            <main className={classes.container}>
                <div className={classes.masonic}>
                    <Masonry
                        items={washCenters}
                        columnGutter={8}
                        columnWidth={200}
                        overscanBy={3}
                        render={WashCenterCard}
                    />
                </div>
            </main>
            <BackToTop
                showOnScrollUp
                showAt={100}
                speed={1500}
                easing="easeInOutQuint"
            >
                <Fab className={classes.scrollUp} color="secondary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
                </Fab>
            </BackToTop>
        </div>
    );
}

const WashCenterCard = ({ data: { id, name, image, link } }) => (
    <div className={'wash-center-card'}>
        <img 
            style={{
                width: '100%',
                display: 'block',
                borderTopLeftRadius: `0.5rem`,
                borderTopRightRadius: `0.5rem`,
                display: 'block'
            }} 
            alt="carcare" 
            src={API_URL + `/business/image/${image}`} 
            onClick={() => openWashCenter(id, link)}
        />
      <span children={name} />
    </div>
);

const openWashCenter = (id, link) => {
    let url = `/wash-center/${id}`;
    if(isMobile) {
        if(link) {
            url = link;
        } 
    }
    window.location.href = url;
};

export default WashCenters;