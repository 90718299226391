import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL, PLACES_API_KEY } from '../constants';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import moment from 'moment';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import starImage from '../images/star.png'; 
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Geocode from "react-geocode";
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Calendar from 'react-calendar-multiday';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    history: {
        width: '100%'
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        paddingBottom: 39,
        textAlign: 'center'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    amount: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    complete: {
        fontSize: 12,
        color: '#48D1B5',
        border: 'solid 1px #48D1B5',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    car: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        opacity: 0.25
    },
    customer: {
        width: 36,
        height: 36,
        borderRadius: 10,
        objectFit: 'cover',
        marginRight: 12
    },
    client: {
        display: 'flex',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    progress: {
        fontSize: 12,
        color: '#f1a542',
        border: 'solid 1px #f1a542',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    rank: {
        marginTop: 3
    },
    star: {
        width: 16,
        height: 16
    },
    ratingsContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 auto',
        justifyContent: 'center',
        ['@media (max-width:768px)']: { 
            justifyContent: 'flex-start'
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    dialogTitle: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    dialogContent: {
        width: 480,
        marginTop: 50,
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    serviceInfoDate: {
        fontSize: 14,
        color: '#000',
        opacity: 0.25,
        marginTop: -10
    },
    dialogUserContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    dialogUserImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogCarImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogUser: {
        textAlign: 'center'
    },
    dialogCar: {
        textAlign: 'center'
    },
    dialogFlexContainer: {
        display: 'flex'
    },
    dialogRow: {
        marginTop: 60
    },
    modalIconImage: {
        width: 36,
        height: 36
    },
    dialogRightText: {
        marginLeft: 20,
        paddingTop: 6,
        marginLeft: 20,
        paddingTop: 6,
        width: '85%'
    },
    dialogFlexEndContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogRight: {
        position: 'absolute',
        right: 64,
        fontWeight: 'bold'
    },
    dialogRightLast: {
        position: 'absolute',
        right: 64,
        marginTop: 25,
        fontWeight: 'bold'
    },
    dialogBottomRow: {
        marginTop: 25
    },
    mapContainer: {
        height: 319, 
        width: '100%', 
        borderRadius: 40,
        marginTop: 20
    },
    mapIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    whatsappIcon: {
        color: '#075e54',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    emailIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
}));

const Notifications = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('body');
    let [notifs, setNotifs] = useState('');
    let [feedbacks, setFeedbacks] = useState('');
    let [selectedRating, setSelectedRating] = useState([]);
    let [center, setCenter] = useState('');
    let [zoom, setZoom] = useState('');
    let [google, setGoogle] = useState('');
    let [directions, setDirections] = useState('');
    let [drivetime, setDrivetime] = useState('');
    let [location, setLocation] = useState('');
    let [timeslot, setTimeslot] = useState('');
    let [customerlatitude, setCustomerlatitude] = useState('');
    let [customerlongitude, setCustomerlongitude] = useState('');
    let [customerlocation, setCustomerlocation] = useState('');
    let [whatsapplink, setWhatsapplink] = useState('');
    let [mailto, setMailto] = useState('');
    let [servicedays, setServicedays] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        
        axios.get(API_URL + `/business/${id}`)
        .then(res => {
            let business = res.data.business;

            axios.get(API_URL + `/business/all-notifications/${business.id}`)
            .then(response => {
                setNotifs(response.data.notifications);
            })
        })
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                    <Table className={classes.history}>
                        <Thead>
                            <Tr>
                                <Th className={classes.header}>{t('notifications.client')}</Th>
                                <Th className={classes.header}>{t('notifications.referencenumber')}</Th>
                                <Th className={classes.header}>{t('notifications.message')}</Th>
                                <Th className={classes.header}>{t('notifications.date')}</Th>
                                <Th className={classes.header}>{t('notifications.status')}</Th>
                                <Th className={classes.header}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {notifs && notifs.map((feedback) => (
                                <Tr>
                                    <Td className={classes.left}>
                                        <div className={classes.client}>
                                            <div>
                                                <img
                                                    className={classes.customer}
                                                    src={API_URL + '/profile/image/' + (feedback.image ? feedback.image : 'default.png')} 
                                                />
                                            </div>
                                            <div>
                                                <div className={classes.name}>{feedback.first_name} {feedback.last_name}</div>
                                                <div className={classes.car}>{feedback.car_type} </div>
                                            </div>
                                        </div>
                                    </Td>
                                    <Td className={classes.center}>
                                        {feedback.reference_number}
                                    </Td>
                                    <Td className={classes.center}>
                                        {feedback.action == 'new' ? t('notifications.new') : (feedback.action == 'edit' ? t('notifications.edit') : (feedback.action == 'cancel' ? t('notifications.cancel') : ''))}
                                    </Td>
                                    <Td className={classes.center}>
                                        {feedback.notification_date}
                                    </Td>
                                    <Td className={classes.center}>
                                        {feedback.read == 1 ? t('notifications.read') : t('notifications.unread')}
                                    </Td>
                                    <Td className={classes.center}>
                                        <div className={classes.progress}>
                                            <a 
                                                style={{
                                                    color: '#f1a542'
                                                }}
                                                href={'/notification/' + feedback.id}
                                            >
                                                {t('feedback.viewdetails')}
                                            </a>
                                        </div>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default Notifications;