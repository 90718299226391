import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL, PLACES_API_KEY } from '../constants';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Geocode from "react-geocode";
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import moment from 'moment';
import Calendar from 'react-calendar-multiday';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '40%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-25%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    dialogTitle: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    serviceInfoDate: {
        fontSize: 14,
        color: '#000',
        opacity: 0.25,
        marginTop: -10
    },
    dialogContent: {
        width: 480,
        marginTop: 50,
        ['@media (max-width:959px)']: { 
          width: '100%'
        }
    },
      serviceInfoDate: {
        fontSize: 14,
        color: '#000',
        opacity: 0.25,
        marginTop: -10
    },
    dialogUserContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '100%'
        }
    },
    dialogUserImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogCarImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogUser: {
        textAlign: 'center'
    },
    dialogCar: {
        textAlign: 'center'
    },
    dialogFlexContainer: {
        display: 'flex'
    },
    dialogRow: {
        marginTop: 20
    },
    modalIconImage: {
        width: 36,
        height: 36
    },
    dialogRightText: {
        marginLeft: 20,
        paddingTop: 6,
        marginLeft: 20,
        paddingTop: 6,
        width: '85%'
    },
    dialogFlexEndContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogRight: {
        position: 'absolute',
        right: 64,
        fontWeight: 'bold'
    },
    dialogRightLast: {
        position: 'absolute',
        right: 64,
        marginTop: 25,
        fontWeight: 'bold'
    },
    dialogBottomRow: {
        marginTop: 25
    },
    mapContainer: {
        height: 319, 
        width: '100%', 
        borderRadius: 40,
        marginTop: 20
    },
    buttonContainerFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
          justifyContent: 'center',
          flexWrap: 'wrap'
        }
    },
    timeslot: {
        marginTop: -35
    },
    mapIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    whatsappIcon: {
        color: '#075e54',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    emailIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
}));

const Notification = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const history = useHistory();
    const params = useParams();
    const { t, i18n } = useTranslation();
    let [business, setBusiness] = useState('');
    let [selecteddayevents, setSelecteddayevents] = useState('');
    let [selectedday, setSelectedday] = useState('');
    let [servicedays, setServicedays] = useState('');
    let [nextservicedays, setNextservicedays] = useState('');
    let [center, setCenter] = useState('');
    let [zoom, setZoom] = useState('');
    let [google, setGoogle] = useState('');
    let [directions, setDirections] = useState('');
    let [drivetime, setDrivetime] = useState('');
    let [edit, setEdit] = useState(false);
    let [selectedevent, setSelectedevent] = useState([]);
    let [location, setLocation] = useState('');
    let [timeslot, setTimeslot] = useState('');
    let [customerlatitude, setCustomerlatitude] = useState('');
    let [customerlongitude, setCustomerlongitude] = useState('');
    let [customerlocation, setCustomerlocation] = useState('');
    let [whatsapplink, setWhatsapplink] = useState('');
    let [mailto, setMailto] = useState('');
  
    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }

        axios.get(API_URL + `/business/${id}`)
        .then(res => {
            let business = res.data.business;
            setBusiness(business);

            axios.get(API_URL + `/business/notification/${business.id}/${params.id}`)
            .then(response => {
                setSelectedevent(response.data.notification[0]);
                loadData(response.data.notification[0])
            })
        });
    }, [])
  
    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    function loadData(event) {
        let utc_time = '';
        let timezoneOffset = new Date().getTimezoneOffset();
        let tz = moment(event.start_time_slot).utc().utcOffset(timezoneOffset);

        let timeslot;
        if(event.start_time_slot) {
            timeslot = (moment(event.start_time_slot).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD HH:mm").toString() + ":00Z").replace(" ", "T");
            setTimeslot(timeslot);
        }

        axios.get(API_URL + `/car/service/dates/${event.id}`)
        .then(res => {
            let dates = res.data.dates;
            if(dates) {
                setServicedays(dates.currentMonth);
                setNextservicedays(dates.nextMonth);
            }

            console.log(event.date)
            setServicedays([event.odate.substring(0, 10)]);
        })

        Geocode.setApiKey(PLACES_API_KEY);
        Geocode.setLanguage("en");
        Geocode.setRegion("bh");
        if(event.location) {
            Geocode.fromAddress(event.location).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                setCustomerlatitude(lat)
                setCustomerlongitude(lng);

                // Compute center
                let centerlatitude = (parseFloat(event.latitude) + lat) / 2;
                let centerlongitude = (parseFloat(event.longitude) + lng) / 2;
                setCenter({lat: centerlatitude, lng: centerlongitude});

                let mapLink = `https://www.google.com.sa/maps/place/${encodeURI(event.location)}/@${lat},${lng},16z`;
                let whatsappLink = 'https://api.whatsapp.com/send?text=' + 'Customer Location: ' + event.location + ' ' + encodeURI(mapLink);
                let mailLink = `mailto:?Subject=Customer Location&body=` + 'Customer Location: ' + event.location + ' ' + encodeURI(mapLink);
                setCustomerlocation(mapLink);
                setWhatsapplink(whatsappLink);
                setMailto(mailLink);
            },
            error => {
                console.error(error);
            }
            );
        }
    }

    function onLoad() {
        if(window.google && window.google.maps) {
            setGoogle(window.google);
        }
    }
    
    function directionServiceLoad(directionsService) {
        directionsService.route(
          {
            origin: selectedevent.address,
            destination: selectedevent.location,
            travelMode: google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              setDirections(result);
    
              // Compute time
              var time = '';
              var myroute = result.routes[0];
              for (var i = 0; i < myroute.legs.length; i++) {
                time += myroute.legs[i].duration.text;
              }
    
              time = time.replace('hours','hr');
              time = time.replace('mins','min');
    
              let drive_time = '(' + time + ' drive)';
              drive_time.replace('(0', '(');
              setDrivetime(drive_time);
    
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
    }

    const handleServiceDayClick = (ob) => {
        console.log(ob);
      }

    function handleClose(e) {
        history.push("/notifications")
    }

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
                <Hidden smDown>
                    <div className={classes.sideMenu}>
                        <SideMenu />
                    </div>
                </Hidden>
                <ToastContainer />
                <div className={classes.mainContent}>
                    <div className={classes.article}>
                        <div className={classes.center}>
                            <div className={classes.header}>
                                <div className={classes.title}>{selectedevent.outcall}</div>
                                <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                            </div>
                            <div>
                                <div className={classes.serviceInfoDate}>
                                    {selectedevent.date}, {selectedevent.time_slot}
                                </div>
                            </div>
                            <div className={classes.dialogContent}>
                                <div className={classes.dialogUserContainer}>
                                <div className={classes.dialogUser}>
                                    <div>
                                        <img
                                            className={classes.dialogUserImage}
                                            src={API_URL + '/profile/image/' + (selectedevent.image ? selectedevent.image : 'default.png')} 
                                        />
                                    </div>  
                                    <div>
                                        {selectedevent.first_name} {selectedevent.last_name}
                                    </div>
                                    <div>
                                        {selectedevent.phone_number}
                                    </div>
                                </div>
                                <div className={classes.dialogCar}>
                                    <div>
                                        <img
                                            className={classes.dialogCarImage}
                                            src={API_URL + '/car/type/image/' + selectedevent.car_type_image} 
                                        />
                                    </div>
                                    <div>
                                        {selectedevent.car_type}
                                    </div>
                                    </div>
                                </div>
                                <div className={classes.dialogRow}>
                                    <div className={classes.dialogFlexContainer}>
                                        <img
                                            className={classes.modalIconImage}
                                            src={washmodalimage} 
                                        />
                                        <div className={classes.dialogRightText}> 
                                            {selectedevent.outcall}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.dialogRow}>
                                    <div className={classes.dialogFlexContainer}>
                                        <div
                                            style={{
                                                width: 36,
                                                height: 36,
                                                borderRadius: 10,
                                                backgroundColor: '#F7F7F7'
                                            }}
                                        >
                                            <AttachMoneyIcon style={{
                                                fontSize: 23,
                                                color: '#9F0000',
                                                marginLeft: 7,
                                                marginTop: 6
                                            }} />
                                        </div>
                                        <div className={classes.dialogRightText}> 
                                            {selectedevent.currency} {selectedevent.amount} {selectedevent.payment ? ('(' + selectedevent.payment.substr(0, 4) + ')') : ''} 
                                        <div
                                            style={{
                                                width: '104%',
                                                marginTop: 10
                                            }}
                                        >
                                            <b>{t('feedback.referencenumber')}: </b>{selectedevent.reference_number}</div>
                                        </div>
                                    </div>
                                    </div>
                                <div className={classes.dialogRow} style={{
                                    marginBottom: 40
                                }}>
                                    <div className={classes.dialogFlexContainer}>
                                        <img
                                            className={classes.modalIconImage}
                                            src={pinmodalimage} 
                                        />
                                        <div className={classes.dialogRightText}> 
                                            <span>{t('feedback.housebuilding')} {selectedevent.building}, {t('feedback.road')} {selectedevent.road}, {t('feedback.block')} {selectedevent.block}, {selectedevent.location} {drivetime}</span>
                                            <div
                                                style={{
                                                marginTop: 10,
                                                marginLeft: -5
                                                }}
                                            >
                                                <a href={customerlocation} target="_blank">
                                                <MapIcon className={classes.mapIcon} />
                                                </a>
                                                <a href={whatsapplink} target="_blank">
                                                <WhatsAppIcon className={classes.whatsappIcon} />
                                                </a>
                                                <a href={mailto}>
                                                <EmailIcon className={classes.emailIcon} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedevent.location &&
                                        <div id="service-info-map" className={classes.mapContainer}>
                                        <LoadScript
                                            googleMapsApiKey={PLACES_API_KEY}>
                                            <GoogleMap
                                                mapContainerStyle={{height: 319, width: "100%"}}
                                                zoom={zoom}
                                                center={center}
                                                onLoad={onLoad}
                                            >
                                                {google &&
                                                <DirectionsService
                                                    options={{
                                                        origin: selectedevent.address,
                                                        destination: selectedevent.location,
                                                        travelMode: google.maps.TravelMode.DRIVING
                                                    }}
                                                    onLoad={directionServiceLoad}
                                                />
                                                }
                                                {directions && 
                                                <DirectionsRenderer 
                                                    directions={directions}
                                                    polylineOptions={{
                                                    strokeOpacity: 0.5,
                                                    strokeColor: '#9F0000',
                                                    }}
                                                />
                                                }
                                            </GoogleMap>
                                        </LoadScript>
                                        </div>
                                    }     
                                </div>
                                {/* <div className={classes.dialogRow}>
                                    <div className={classes.dialogFlexContainer}>
                                        <img
                                            className={classes.modalIconImage}
                                            src={calendarmodalimage} 
                                        />
                                        <div className={classes.dialogRightText}> 
                                            <div className={classes.dialogFlexEndContainer}>
                                                <div>{t('feedback.time')}</div>
                                                <div className={classes.dialogRight}>{selectedevent.time_slot}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="service-info-current-calendar">
                                        <Calendar
                                            selected={servicedays} 
                                            onChange={handleServiceDayClick}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default Notification;