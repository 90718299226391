import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none'
        }
    },
    message: {
        height: 240,
        borderRadius: 23,
        borderRadius: 23,
        width: 560,
        padding: 20,
        fontFamily: 'Nunito',
        "&:focus": {
            boxShadow: "none",
            outline: "none"
        },
        "&:active": {
            boxShadow: "none",
            outline: "none"
        },
        ['@media (max-width:959px)']: { 
          width: '100%'
        },
        [`& fieldset`]: {
            borderRadius: 23
        }
    },
    sendContainer: {
        paddingTop: 16,
        float: 'right',
        marginRight: '3%',
        marginTop: '5%',
        ['@media (max-width:959px)']: { 
            marginRight: '5%',
            marginTop: '5%'
        }
    },
    sendText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    sendBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    sendBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    articleTitle: {
        marginLeft: 20
    }
}));

const Support = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const messageRef = useRef('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
    }, [])
  
    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    function handleClick(e) {
        let message = messageRef.current.value;
        
        if(message) {
            axios.post(API_URL + `/user/feedback`, { 
                feedback: message,
                mode: 'support',
                created_by: id
            })
            .then(res => {
                if(res.status == 200) {
                    messageRef.current.value = '';
                    toast.success(t('support.wereceivedyourmessage'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        } else {
            toast.error(t('support.enteryourmessage'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
                <Hidden smDown>
                    <div className={classes.sideMenu}>
                        <SideMenu />
                    </div>
                </Hidden>
                <ToastContainer />
                <div className={classes.mainContent}>
                    <div className={classes.article}>
                        <div className={classes.center}>
                            <p className={classes.articleTitle}>{t('support.whatcanwehelpyouwithtoday')}</p>
                            <div>
                                <TextField 
                                    id="message"
                                    className={classes.message}
                                    placeholder={t('support.enteryourmessage')}
                                    inputRef={messageRef}
                                    multiline
                                    rowsMax={10}
                                    rows={10}
                                    variant="outlined"
                                />
                            </div>
                            <div className={classes.sendContainer}>
                                <div className={classes.sendText}>{t('support.send')}</div>
                                <div 
                                    onClick={handleClick}
                                    className={classes.sendBtnContainer} 
                                >
                                    <img 
                                        className={classes.sendBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Carcare" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default Support;