import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    history: {
        width: '100%'
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        paddingBottom: 39,
        textAlign: 'center'
    },
    headerLeft: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        paddingBottom: 39,
        textAlign: 'left'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    amount: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    complete: {
        fontSize: 12,
        color: '#48D1B5',
        border: 'solid 1px #48D1B5',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    car: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        opacity: 0.25
    },
    customer: {
        width: 36,
        height: 36,
        borderRadius: 10,
        objectFit: 'cover',
        marginRight: 12
    },
    client: {
        display: 'flex',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    colAdd: {
        paddingLeft: '3%',
        height: 60,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0
        }
    },
    addService: {
        textDecoration: 'none',
        color: '#9F0000'
    },
    addIcon: {
        position: 'absolute'
    },
    addServiceText: {
        marginLeft: 30,
        marginTop: 3
    },
    serviceLink: {
        color: '#9F0000'
    },
}));

const PromoCodes = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const roleId = localStorage.getItem('roleId');
    let [promoCodes, setPromoCodes] = useState('');
    let [switchState, setSwitchState] = useState({});
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        
        axios.get(API_URL + `/business/${id}`)
        .then(res => {
            let business = res.data.business;

            axios.get(API_URL + `/promo-codes/${business.id}`)
            .then(response => {
                let wc_promo_codes = response.data.promo_codes;

                if(roleId == 3) {
                    axios.get(API_URL + `/all-promo-codes`)
                    .then(resp => {
                        let all_promo_codes = resp.data.promo_codes;
                        if(all_promo_codes) {
                            all_promo_codes.forEach(p => {
                                wc_promo_codes.push(p)
                            })
                        }
                        setPromoCodes(wc_promo_codes);
                        setDynamicSwitchState(wc_promo_codes);
                    })
                } else {
                    setPromoCodes(wc_promo_codes);
                    setDynamicSwitchState(wc_promo_codes);
                }
            })
        })
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    const setDynamicSwitchState = (list) => {
        if (!list) {
          return
        }
    
        const switchstate = {};
    
        list.forEach((item, index) => {
            switchstate['switch-' + index] = item.status == 1 ? true : false;
        });
    
        setSwitchState(switchstate);
    }

    const handleChange = (index, event, checked, customerId) => {
        const list = Object.assign({}, switchState);
    
        list['switch-' + index] = checked;

        let message = '';
        let post_url = '';
        if(checked) {
            message = t("promo-codes.activated");
            post_url = '/promo-code/activate/';
        } else {
            message = t("promo-codes.deactivated");
            post_url = '/promo-code/deactivate/';
        }

        axios.post(API_URL + post_url + `${customerId}`, {})
        .then(res => {
            if(res.data && res.data.message) {
                toast.success(message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSwitchState(list);
            }
        });
    }

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                    <ToastContainer />
                    <Table className={classes.history}>
                        <Thead>
                            <Tr>
                                <Th className={classes.header}>{t('promo-codes.promocode')}</Th>
                                <Th className={classes.headerLeft}>{t('promo-codes.description')}</Th>
                                <Th className={classes.headerLeft}>{t('promo-codes.descriptionar')}</Th>
                                <Th className={classes.headerLeft}>{t('promo-codes.service')}</Th>
                                <Th className={classes.header}>{t('promo-codes.discount')}</Th>
                                <Th className={classes.header}>{t('promo-codes.valid')}</Th>
                                <Th className={classes.header}>{t('promo-codes.nooftimesused')}</Th>
                                <Th className={classes.header}>{t('promo-codes.status')}</Th>
                                <Th className={classes.header}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {promoCodes && promoCodes.map((promoCode, i) => (
                                <>
                                    <Tr key={promoCode.id}>
                                        <Td className={classes.center}>
                                        <a className={classes.serviceLink} href={'/edit-promo-code/' + promoCode.id}>{promoCode.promo_code}</a>
                                        </Td>
                                        <Td className={classes.left}>
                                            {promoCode.description}
                                        </Td>
                                        <Td className={classes.left}>
                                            {promoCode.description_ar}
                                        </Td>
                                        <Td className={classes.left}>
                                            {promoCode.service?.name}
                                        </Td>
                                        <Td className={classes.center}>
                                            {promoCode.percentage && parseInt(promoCode.percentage) > 0 ? (promoCode.percentage.replace(".00", "") + '%') : (promoCode.amount ? ('BD ' + promoCode.amount) : '')}
                                        </Td>
                                        <Td className={classes.center}>
                                            {moment(promoCode.valid_from).format("MMM D") + ' - ' + (moment(promoCode.valid_from).format("MMM") == moment(promoCode.valid_to).format("MMM") ? moment(promoCode.valid_to).format("D") : moment(promoCode.valid_to).format("MMM D"))}
                                        </Td>
                                        <Td className={classes.center}>
                                            {promoCode.no_used}
                                        </Td>
                                        <Td className={classes.center}>
                                            <Switch key={i}
                                                checked={switchState['switch-' + i] || false}
                                                onChange={(event, checked) => handleChange(i, event, checked, promoCode.id)}
                                                value={promoCode.status}
                                            />
                                        </Td>
                                    </Tr>
                                </>
                            ))}
                            <Tr>
                                <Td colSpan="7" className={classes.colAdd}>
                                    <a className={classes.addService} href={'/add-promo-code'}>
                                      <AddIcon className={classes.addIcon} />
                                      <div className={classes.addServiceText}>{t('promo-codes.addapromocode')}</div>
                                    </a>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </div>
            </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default PromoCodes;