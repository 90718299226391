import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDurationTo: {
        width: '100%',
        height: 72,
        ['@media (max-width:959px)']: { 
            marginTop: 100
        }
    },
    inputContainerDesc: {
        width: '100%'
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexServiceInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    durationLabel: {
        // paddingTop: 25,
        paddingBottom: 20,
        fontSize: 16,
        marginTop: 20,
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    durationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    selectDuration: {
        width: 280,
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 0,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '55%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    offerFlex: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 10,
        marginTop: 15
    },
    priceFlex: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 25
    },
    flexInput: {
        fontSize: 16,
        width: '33%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '34%'
    },
    typeContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    washTypeLabel: {
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    typeContainer: {
        width: '65%',
        display: 'inline',
        marginTop: 10,
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    monthlyPackagesContainer: {
        width: '100%',
        height: 72,
        width: '30%',
        display: 'inline-block',
        marginLeft: 20,
        position: 'absolute',
        marginTop: -12,
        ['@media (max-width:959px)']: { 
            display: 'block',
            width: '100%',
            position: 'relative',
            marginLeft: 0
        }
    },
    monthlyPackagesContainer2: {
        width: '100%',
        height: 72,
        width: '30%',
        display: 'inline-block',
        marginLeft: 220,
        position: 'absolute',
        marginTop: -12,
        ['@media (max-width:959px)']: { 
            display: 'block',
            width: '100%',
            position: 'relative',
            marginLeft: 0
        }
    }
}));

const AddService = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const serviceNameRef = useRef('');
    const serviceNameArRef = useRef('');
    const priceRef = useRef('');
    const smallPriceRef = useRef('');
    const mediumPriceRef = useRef('');
    const largePriceRef = useRef('');
    const motorbikePriceRef = useRef('');
    const buildingSmallPriceRef = useRef('');
    const buildingMediumPriceRef = useRef('');
    const buildingLargePriceRef = useRef('');
    const descriptionRef = useRef('');
    const descriptionArRef = useRef('');
    const daysPerMonthRef = useRef('');
    const categoryNameRef = useRef('');
    const categoryNameArRef = useRef('');
    const percentOfferRef = useRef('');
    const durationDaysRef = useRef('');
    const durationHoursRef = useRef('');
    const [days, setDays] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [hoursTo, setHoursTo] = useState('');
    const [minutesTo, setMinutesTo] = useState('');
    let [business, setBusiness] = useState('');
    let [buildingSetting, setBuildingSetting] = useState(false);
    const history = useHistory();
    const params = useParams();
    const [selectedTypeValue, setSelectedTypeValue] = React.useState('Wash Types');
    const [otherServices, setOtherServices] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        axios.get(API_URL + `/business/${id}`)
        .then(res => {
            let business = res.data.business;
            setBusiness(business);

            axios.get(API_URL + `/business/other-services/${business.id}/0`)
            .then(resp => {
                setOtherServices(resp.data.other_services)
            })

            axios.get(API_URL + `/wash-center/building-settings/${business.id}`)
            .then(rspns => {
                let settings =rspns.data.wash_center_building_settings
                if(settings && settings[0]) {
                    setBuildingSetting(settings[0].enabled);
                }
            })
        })
    }, [])

    const handleDaysChange = (event) => {
        setDays(event.target.value);
    };

    const handleHoursChange = (event) => {
        setHours(event.target.value);
    };

    const handleMinutesChange = (event) => {
        setMinutes(event.target.value);
    };

    const handleHoursToChange = (event) => {
        setHoursTo(event.target.value);
    };

    const handleMinutesToChange = (event) => {
        setMinutesTo(event.target.value);
    };
  
    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    function handleClick(e) {
        let serviceName = serviceNameRef.current.value;
        let serviceNameAr = serviceNameArRef.current.value;
        let small_price = smallPriceRef.current.value;
        let medium_price = mediumPriceRef.current.value;
        let large_price = largePriceRef.current.value;
        let motorbike_price = motorbikePriceRef.current.value;
        let building_small_price = buildingSmallPriceRef.current.value;
        let building_medium_price = buildingMediumPriceRef.current.value;
        let building_large_price = buildingLargePriceRef.current.value;
        let description = descriptionRef.current.value;
        let descriptionAr = descriptionArRef.current.value;
        let daysPerMonth = daysPerMonthRef.current.value;
        let categoryName = categoryNameRef.current ? categoryNameRef.current.value : (selectedTypeValue != 'Wash Types' && selectedTypeValue != 'Monthly Packages' && selectedTypeValue != 'New Category' ? selectedTypeValue : '');
        let categoryNameAr = categoryNameArRef.current ? categoryNameArRef.current.value : '';
        let percentOffer = percentOfferRef.current.value;
        let durationDays = durationDaysRef.current.value;
        let durationHours = durationHoursRef.current.value;

        let duration;
        let unit;
        let duration_to;
        let unit_to;
        if(days) {
            duration = days;
            unit = 'day';
        } else if(hours) {
            duration = hours;
            unit = 'hr';
        }  else if(minutes) {
            duration = minutes;
            unit = 'min';
        }

        if(hoursTo) {
            duration_to = hoursTo;
            unit_to = 'hr';
        }  else if(minutesTo) {
            duration_to = minutesTo;
            unit_to = 'min';
        }

        if(serviceName && small_price && medium_price && large_price && description && selectedTypeValue && (days || hours || minutes)) {
            axios.post(API_URL + `/wash-center/service/add/${params.type}`, { 
                business_id: business.id,
                name: serviceName,
                name_ar: serviceNameAr,
                description,
                description_ar: descriptionAr,
                small_price,
                medium_price,
                large_price,
                motorbike_price,
                building_small_price,
                building_medium_price,
                building_large_price,
                outcall_type: selectedTypeValue,
                duration,
                unit,
                duration_to,
                unit_to,
                times: daysPerMonth,
                service_type: selectedTypeValue,
                category: categoryName,
                category_ar: categoryNameAr,
                offer_percent: percentOffer,
                offer_duration_days: durationDays,
                offer_duration_hours: durationHours
            })
            .then(res => {
                if(res.status == 200) {
                    history.push("/services")
                }
            })
        } else {
            toast.error(t('services.fillup'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function handleClose(e) {
        history.push("/services")
    }

    const handleTypeChange = (event) => {
        setSelectedTypeValue(event.target.value);
    };

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
                <Hidden smDown>
                    <div className={classes.sideMenu}>
                        <SideMenu />
                    </div>
                </Hidden>
                <ToastContainer />
                <div className={classes.mainContent}>
                    <div className={classes.article}>
                        <div className={classes.center}>
                            <div className={classes.header}>
                                <div className={classes.title}>{t('services.addservice')}</div>
                                <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                            </div>
                            <div className={classes.fieldsContainer}>
                                <form>
                                    <div className={classes.inputFlexContainer}>
                                        <TextField 
                                            id="serviceName"
                                            label={t('services.servicename')}
                                            className={classes.input}
                                            placeholder={t('services.servicename')}
                                            inputRef={serviceNameRef}
                                        />
                                        <TextField 
                                            id="serviceNameAr"
                                            label={t('services.servicenamear')} 
                                            className={classes.flexServiceInput}
                                            placeholder={t('services.servicenamear')}
                                            inputRef={serviceNameArRef}
                                        />
                                    </div>
                                    <div className={classes.priceFlex}>
                                        <TextField 
                                            id="smallprice"
                                            label={t('services.pricesmall')}
                                            className={classes.flexInput}
                                            placeholder={t('services.pricesmall')}
                                            inputRef={smallPriceRef}
                                        />
                                        <TextField 
                                            id="mediumprice"
                                            label={t('services.pricemedium')}
                                            className={classes.flexInput}
                                            placeholder={t('services.pricemedium')}
                                            inputRef={mediumPriceRef}
                                        />
                                        <TextField 
                                            id="largeprice"
                                            label={t('services.pricelarge')}
                                            className={classes.flexInputLast}
                                            placeholder={t('services.pricelarge')}
                                            inputRef={largePriceRef}
                                        />
                                        {/* <TextField 
                                            id="motorbikeprice"
                                            label={t('services.pricemotorbike')}
                                            className={classes.flexInputLast}
                                            placeholder={t('services.pricemotorbike')}
                                            inputRef={motorbikePriceRef}
                                        /> */}
                                    </div>
                                    {buildingSetting == 1 &&
                                    <div className={classes.priceFlex}>
                                        <TextField 
                                            id="buildingsmallprice"
                                            label={t('services.buildingpricesmall')}
                                            className={classes.flexInput}
                                            placeholder={t('services.buildingpricesmall')}
                                            inputRef={buildingSmallPriceRef}
                                        />
                                        <TextField 
                                            id="buildingmediumprice"
                                            label={t('services.buildingpricemedium')}
                                            className={classes.flexInput}
                                            placeholder={t('services.buildingpricemedium')}
                                            inputRef={buildingMediumPriceRef}
                                        />
                                        <TextField 
                                            id="buildinglargeprice"
                                            label={t('services.buildingpricelarge')}
                                            className={classes.flexInputLast}
                                            placeholder={t('services.buildingpricelarge')}
                                            inputRef={buildingLargePriceRef}
                                        />
                                    </div>
                                    }
                                    <div className={classes.inputContainerDesc}>
                                        <TextField 
                                            id="description"
                                            label={t('services.description')}
                                            multiline
                                            className={classes.input}
                                            placeholder={t('services.description')}
                                            inputRef={descriptionRef}
                                        />
                                    </div>
                                    <div className={classes.inputContainerDesc}>
                                        <TextField 
                                            id="description"
                                            label={t('services.descriptionar')}
                                            multiline
                                            className={classes.input}
                                            placeholder={t('services.descriptionar')}
                                            inputRef={descriptionArRef}
                                        />
                                    </div>
                                    <div style={{
                                        marginTop: 20
                                    }}>
                                        <div className={classes.typeContainer}>
                                            <FormControlLabel 
                                                className={classes.washTypeLabel}
                                                value="end" 
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'Wash Types'}
                                                    onChange={handleTypeChange}
                                                    value="Wash Types"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'Wash Types' }} />} 
                                                label={t('services.washtypes')} />
                                            <FormControlLabel 
                                                className={classes.washTypeLabel}
                                                value="end" 
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'Monthly Packages'}
                                                    onChange={handleTypeChange}
                                                    value="Monthly Packages"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'Monthly Packages' }} />} 
                                                label={t('services.monthlypackages')} />
                                            <FormControlLabel 
                                                className={classes.washTypeLabel}
                                                value="end" 
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'Others'}
                                                    onChange={handleTypeChange}
                                                    value="Others"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'Others' }} />} 
                                                label={t('services.others')} />
                                            {otherServices && otherServices.map((otherService) => (
                                                <FormControlLabel 
                                                    className={classes.washTypeLabel}
                                                    value="end" 
                                                    control={<Radio 
                                                        checked={selectedTypeValue == otherService.category}
                                                        onChange={handleTypeChange}
                                                        value={otherService.category}
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': otherService.category }} />} 
                                                    label={localStorage.getItem('language') == 'ar' ? (otherService.category_ar ? otherService.category_ar : otherService.category) : otherService.category} />
                                            ))}
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div className={classes.typeContainer}>
                                            <FormControlLabel value="end" 
                                                control={<Radio 
                                                    checked={selectedTypeValue === 'New Category'}
                                                    onChange={handleTypeChange}
                                                    value="New Category"
                                                    name="radio-button-demo"
                                                    inputProps={{ 'aria-label': 'New Category' }} />} 
                                                label={t('services.newcategory')} />
                                            {selectedTypeValue === 'New Category' &&
                                                <>
                                                    <div className={classes.monthlyPackagesContainer}>
                                                        <TextField 
                                                            id="categoryName"
                                                            label={t('services.categoryname')} 
                                                            className={classes.input}
                                                            placeholder={t('services.categoryname')}
                                                            // value={''}
                                                            // onChange={(event) => handleFieldChange(event, 'categoryName')}
                                                            inputRef={categoryNameRef}
                                                        />
                                                    </div>
                                                    <div className={classes.monthlyPackagesContainer2}>
                                                        <TextField 
                                                            id="categoryNameAr"
                                                            label={t('services.categorynamear')} 
                                                            className={classes.input}
                                                            placeholder={t('services.categorynamear')}
                                                            // value={categoryNameAr || ''}
                                                            // onChange={(event) => handleFieldChange(event, 'categoryNameAr')}
                                                            inputRef={categoryNameArRef}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {selectedTypeValue === 'Monthly Packages' &&
                                        <div className={classes.inputContainer}>
                                            <TextField 
                                                id="daysPerMonth"
                                                label={t('services.dayspermonth')} 
                                                className={classes.input}
                                                placeholder={t('services.dayspermonth')} 
                                                inputRef={daysPerMonthRef}
                                            />
                                        </div>
                                    }
                                    <div className={classes.inputContainer}>
                                        <div className={classes.durationLabel}>{t('services.durationfrom')}</div>
                                        <div className={classes.durationContainer}>
                                            {/* <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <Select
                                                        labelId="days-select-outlined-label"
                                                        id="days-select-outlined"
                                                        value={days}
                                                        onChange={handleDaysChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 day</MenuItem>
                                                        <MenuItem value={1}>1 day</MenuItem>
                                                        <MenuItem value={2}>2 days</MenuItem>
                                                        <MenuItem value={3}>3 days</MenuItem>
                                                        <MenuItem value={4}>4 days</MenuItem>
                                                        <MenuItem value={5}>5 days</MenuItem>
                                                        <MenuItem value={6}>6 days</MenuItem>
                                                        <MenuItem value={7}>7 days</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div> */}
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <Select
                                                        labelId="hours-select-outlined-label"
                                                        id="hours-select-outlined"
                                                        value={hours}
                                                        onChange={handleHoursChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 hour</MenuItem>
                                                        <MenuItem value={1}>1 hour</MenuItem>
                                                        <MenuItem value={2}>2 hours</MenuItem>
                                                        <MenuItem value={3}>3 hours</MenuItem>
                                                        <MenuItem value={4}>4 hours</MenuItem>
                                                        <MenuItem value={5}>5 hours</MenuItem>
                                                        <MenuItem value={6}>6 hours</MenuItem>
                                                        <MenuItem value={7}>7 hours</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <Select
                                                        labelId="minutes-select-outlined-label"
                                                        id="minutes-select-outlined"
                                                        value={minutes}
                                                        onChange={handleMinutesChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 minute</MenuItem>
                                                        <MenuItem value={15}>15 minutes</MenuItem>
                                                        <MenuItem value={30}>30 minutes</MenuItem>
                                                        <MenuItem value={45}>45 minutes</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.inputContainerDurationTo}>
                                        <div className={classes.durationLabel}>{t('services.durationto')}</div>
                                        <div className={classes.durationContainer}>
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <Select
                                                        labelId="hours-select-outlined-label"
                                                        id="hours-select-outlined"
                                                        value={hoursTo}
                                                        onChange={handleHoursToChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 hour</MenuItem>
                                                        <MenuItem value={1}>1 hour</MenuItem>
                                                        <MenuItem value={2}>2 hours</MenuItem>
                                                        <MenuItem value={3}>3 hours</MenuItem>
                                                        <MenuItem value={4}>4 hours</MenuItem>
                                                        <MenuItem value={5}>5 hours</MenuItem>
                                                        <MenuItem value={6}>6 hours</MenuItem>
                                                        <MenuItem value={7}>7 hours</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <Select
                                                        labelId="minutes-select-outlined-label"
                                                        id="minutes-select-outlined"
                                                        value={minutesTo}
                                                        onChange={handleMinutesToChange}
                                                        className={classes.selectDuration}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">0 minute</MenuItem>
                                                        <MenuItem value={15}>15 minutes</MenuItem>
                                                        <MenuItem value={30}>30 minutes</MenuItem>
                                                        <MenuItem value={45}>45 minutes</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.offerFlex}>
                                        <TextField 
                                            id="percentOffer"
                                            label={t('services.percentoffer')}
                                            className={classes.flexInput}
                                            placeholder={t('services.percentoffer')}
                                            inputRef={percentOfferRef}
                                        />
                                        <TextField 
                                            id="durationDays"
                                            label={t('services.durationdays')}
                                            className={classes.flexInput}
                                            placeholder={t('services.durationdays')}
                                            inputRef={durationDaysRef}
                                        />
                                        <TextField 
                                            id="durationHours"
                                            label={t('services.durationhours')}
                                            className={classes.flexInputLast}
                                            placeholder={t('services.durationhours')}
                                            inputRef={durationHoursRef}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className={classes.saveContainer}>
                                <div className={classes.saveText}>{t('services.save')}</div>
                                <div 
                                    onClick={handleClick}
                                    className={classes.saveBtnContainer} 
                                >
                                    <img 
                                        className={classes.saveBtn} 
                                        src={arrowrightwhiteimage} 
                                        alt="Carcare" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default AddService;