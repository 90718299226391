import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import Footer from '../components/Footer';
import squareimage from '../images/square.png'; 
import carservicesimage from '../images/carservices.png'; 
import step1image from '../images/step1.png'; 
import step2image from '../images/step2.png'; 
import step3image from '../images/step3.png'; 
import service1image from '../images/service1.jpg'; 
import service2image from '../images/service2.jpg'; 
import service3image from '../images/service3.jpg'; 
import next1image from '../images/next1.png'; 
import next2image from '../images/next2.png'; 
import partnerimage from '../images/partner.png'; 
import contactimage from '../images/contact.png'; 
import appstoreimage from '../images/ios_app_store_btn.png'; 
import googleplayimage from '../images/google_play_store_btn.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import usimage from '../images/us.png'; 
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  fullWidth: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto', 
    marginRight: 'auto'
  },
  section: {
    paddingTop: 200,
    ['@media (max-width:768px)']: { 
      paddingTop: 100,
      paddingBottom: 100
    }
  },
  squareImage: {
    width: 127,
    position: 'relative',
    right: '-5%',
    top: 8,
    zIndex: 0
  },
  section1Left: {
    width: '40%',
    paddingTop: 30,
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingTop: 0
    }
  },
  section1Right: {
    width: '55%',
    paddingTop: 30,
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingTop: 0
    }
  },
  carServicesImage: {
    width: '100%',
    ['@media (max-width:768px)']: { 
      paddingTop: 150
    }
  },
  section1Header: {
    fontSize: '2.5em',
    fontWeight: 'bolder',
    textAlign: 'left',
    marginTop: 40,
    ['@media (max-width:768px)']: { 
      marginTop: 0,
      fontSize: '2em'
    }
  },
  section1Subtitle: {
    textAlign: 'left',
    color: '#a7a7a7',
    fontSize: 16,
    marginTop: 20,
    width: 372,
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  learnMoreContainer: {
    textAlign: 'left',
    marginTop: 60
  },
  learnMore: {
    color: '#fff',
    zIndex: 2,
    position: 'relative',
    fontWeight: 'bold',
    paddingTop: 16,
    paddingLeft: 30,
    borderRadius: 12,
    paddingRight: 30,
    paddingBottom: 16,
    textDecoration: 'none',
    backgroundColor: '#9F0000',
    "&:hover": {
        cursor: "pointer",
        color: '#FFF !important'
    }
  },
  section2SquareImage: {
    width: 127,
    position: 'absolute',
    left: 0,
    marginTop: 50
  },
  sectionHeader: {
    fontSize: '2.0em',
    fontWeight: 500,
    paddingBottom: 25,
    marginTop: -30
  },  
  sectionDescription: {
    fontSize: 16,
    color: '#a7a7a7',
    paddingBottom: 60
  },
  step: {
    width: '25%',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  stepImage: {
    width: '100%'
  },
  stepDescription: {
    fontSize: 16,
    color: '#a7a7a7',
    paddingLeft: 10,
    paddingRight: 10,
    ['@media (max-width:768px)']: { 
      paddingTop: 40,
      paddingBottom: 40
    }
  },
  whatNextRow: {
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  next: {
    width: '40%',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  nextImage: {
    width: '100%'
  },
  nextDescription: {
    fontSize: 16,
    color: '#a7a7a7',
    paddingLeft: 10,
    paddingRight: 10,
    ['@media (max-width:768px)']: { 
      paddingTop: 40,
      paddingBottom: 40
    }
  },
  section4LeftSquareImage: {
    left: 0,
    width: 127,
    position: 'absolute',
    marginTop: -65,
    marginLeft: 10
  },
  section4RightSquareImage: {
    right: 0,
    width: 127,
    position: 'absolute',
    marginTop: 237
  },
  partnerWithUsContainer: {
    backgroundColor: '#0c2136',
    marginLeft: '-5.6%',
    marginRight: '-5.5%',
    paddingTop: 150,
    paddingBottom: 150
  },
  partner: {
    width: '30%',
    marginRight: '8%',
    marginLeft: '5%',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  partnerImage: {
    width: '100%',
    ['@media (max-width:768px)']: { 
      paddingTop: 150
    }
  },
  section5LeftSquareImage: {
    left: 0,
    width: 127,
    position: 'absolute',
    marginTop: -45,
    marginLeft: -67
  },
  section5RightSquareImage: {
    right: 0,
    width: 127,
    position: 'absolute',
    marginTop: 286,
    ['@media (max-width:768px)']: { 
      marginTop: 713
    }
  },
  section5Header: {
    textAlign: 'left',
    fontSize: '2.5em',
    color: '#fff',
    marginTop: 43
  },
  section5Text1: {
    marginTop: 20,
    textAlign: 'left',
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  section5Text2: {
    textAlign: 'left',
    marginTop: 80,
    color: '#fff',
    fontSize: 14,
    marginRight: 80
  },
  getStartedContainer: {
    textAlign: 'left',
    marginTop: 50
  },
  getStarted: {
    color: '#fff',
    zIndex: 2,
    position: 'relative',
    fontWeight: 'bold',
    paddingTop: 16,
    paddingLeft: 50,
    borderRadius: 12,
    paddingRight: 50,
    paddingBottom: 16,
    textDecoration: 'none',
    backgroundColor: '#9F0000',
    "&:hover": {
        cursor: "pointer",
        color: '#FFF !important'
    }
  },
  section6RightSquareImage: {
    right: 0,
    width: 127,
    position: 'absolute',
    marginTop: 370,
    ['@media (max-width:768px)']: { 
      marginTop: 212
    }
  },
  contact: {
    width: '35%',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  contactRight: {
    width: '42%',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  contactImage: {
    width: '100%',
    marginTop: 50,
    ['@media (max-width:768px)']: { 
      marginTop: 0
    }
  },
  section6Header: {
    textAlign: 'left',
    fontSize: '2.5em'
  },
  section6Description: {
    marginTop: 20,
    textAlign: 'left',
    color: '#a7a7a7',
    fontSize: 16
  },
  section62Col: {
    textAlign: 'left',
    marginTop: 80
  },
  email: {
    width: '45%',
    marginRight: '5%',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  phone: {
    width: '45%',
    ['@media (max-width:768px)']: { 
      width: '100%',
      marginTop: 50
    }
  },
  message: {
    width: '95%',
    textAlign: 'left',
    marginTop: 60,
    marginRight: '13%',
    ['@media (max-width:768px)']: { 
      width: '100%',
      marginRight: 0
    }
  },
  sendContainer: {
    textAlign: 'left',
    marginTop: 80
  },
  send: {
    color: '#fff',
    zIndex: 2,
    position: 'relative',
    fontWeight: 'bold',
    paddingTop: 16,
    paddingLeft: 36.5,
    borderRadius: 12,
    paddingRight: 36.5,
    paddingBottom: 16,
    textDecoration: 'none',
    backgroundColor: '#9F0000',
    "&:hover": {
        cursor: "pointer",
        color: '#FFF !important'
    }
  },
  storeImage: {
    width: 189
  },
  downloadAppContainer: {
    width: 400,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40,
    ['@media (max-width:768px)']: { 
      display: 'block',
      width: '100%'
    }
  }
}));

function MainContent() {
  const classes = useStyles();
  const emailRef = useRef('');
  const phoneRef = useRef('');
  const messageRef = useRef('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
        i18n.changeLanguage(language);
    }
  }, [])

  function handleSend(e) {
    let email = emailRef.current.value;
    let phone = phoneRef.current.value;
    let message = messageRef.current.value;
    
    if(email && phone && message) {
        axios.post(API_URL + `/user/feedback`, { 
            feedback: message,
            mode: 'support',
            created_by: 0,
            email: email,
            phone_number: phone
        })
        .then(res => {
            if(res.status == 200) {
              emailRef.current.value = '';
              phoneRef.current.value = '';
              messageRef.current.value = '';
              toast.success("We received your message, our team will be in contact with you soon.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              setTimeout(function () {
                console.log('show toast')
              }, 2000);
            }
        })
    } else {
        toast.error("Please enter your message", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
  }

  return (
    <main className={classes.fullWidth}>
      <div className={classes.toolbar} />
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container 
          spacing={24}
          className={classes.section}
        >
          <Grid 
            item
            className={classes.section1Left}
          >
            <div className={classes.section1Header}>
              <div
                style={{
                  width: 350,
                  lineHeight: 1
                }}
              >
                {t('landing.yourcarservicesatyourfingertips')}
              </div>
            </div>
            <div className={classes.section1Subtitle}>
              {t('landing.carcare')}
            </div>
            <img 
                className={classes.section2SquareImage} 
                src={squareimage} 
                alt="Carcare" />
            <div className={classes.learnMoreContainer}>
              <a 
                href="#downloadtheapp"
                className={classes.learnMore} 
              >{t('landing.downloadtheapp')}</a>
            </div>
          </Grid>
          <Grid 
            item
            className={classes.section1Right}
          >
            <div>
              <img 
                className={classes.carServicesImage} 
                src={carservicesimage} 
                alt="Carcare" />
                {/* <Parallax bgImage={carservicesimage} strength={500}>
                  <div style={{ width: 802, height: 503 }}>
                  </div>
                </Parallax> */}
            </div>
          </Grid>
        </Grid>
        <div id="howitworks" className={classes.section}>
          <div className={classes.sectionHeader}>
            {t('landing.howitworks')}
          </div>
          <div className={classes.sectionDescription}>
            {/* <div>3-4 steps of the procedure from downloading the app until</div>
            <div>downloading the service</div> */}
          </div> 
          <Grid
            justify="space-between"
            container 
            spacing={24}
            className={classes.row}
          >
            <div className={classes.step}>
              <img 
                className={classes.stepImage} 
                src={step1image} 
                alt="Carcare" />
              <div className={classes.stepDescription}>
                {t('landing.downloadtheappfromappstoreorgoogleplay')}
              </div>
            </div>
            <div className={classes.step}>
              <img 
                className={classes.stepImage} 
                src={step2image} 
                alt="Carcare" />
              <div className={classes.stepDescription}>
                {t('landing.signupbyusingyourcredentials')}
              </div>
            </div>
            <div className={classes.step}>
              <img 
                className={classes.stepImage} 
                src={step3image} 
                alt="Carcare" />
              <div className={classes.stepDescription}>
                {t('landing.choosethetypeofserviceandpayasyouproceed')}
              </div>
            </div>
          </Grid>
        </div>
        <div id="ourservices" className={classes.section}>
          <div className={classes.sectionHeader}>
            {t('landing.ourservices')}
          </div>
          <div className={classes.sectionDescription}>
          </div> 
          <Grid
            justify="space-between"
            container 
            spacing={24}
            className={classes.row}
          >
            <div className={classes.step}>
              <img 
                className={classes.stepImage} 
                src={service1image} 
                alt="Carcare" />
              <div className={classes.stepDescription}>
                {t('landing.weconnectyoudirectly')}
              </div>
            </div>
            <div className={classes.step}>
              <img 
                className={classes.stepImage} 
                src={service2image} 
                alt="Carcare" />
              <div className={classes.stepDescription}>
                {t('landing.enjoyyourcarwash')}
              </div>
            </div>
            <div className={classes.step}>
              <img 
                className={classes.stepImage} 
                src={service3image} 
                alt="Carcare" />
              <div className={classes.stepDescription}>
                {t('landing.ondemandbooking')}
              </div>
            </div>
          </Grid>
          <div 
            style={{
              paddingTop: 40
            }}
            id="downloadtheapp">
            <div
              style={{
                marginTop: 80,
                fontSize: '1.5em'
              }}
            >
              {t('landing.downloadtheapp')}
            </div>
          </div>
          <div className={classes.downloadAppContainer}>
            <a href="https://apps.apple.com/us/app/carcare-app/id1538265753" target="_blank">
              <img 
                className={classes.storeImage} 
                src={appstoreimage} 
                alt="iOS" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.carcareapp" target="_blank">
              <img 
                className={classes.storeImage} 
                src={googleplayimage} 
                alt="Android" />
            </a>
          </div>
        </div>
        <div id="whatnext" className={classes.section}>
          <div className={classes.sectionHeader}>
            {t('landing.whatsnext')}
          </div>
          <img 
            className={classes.section4LeftSquareImage} 
            src={squareimage} 
            alt="Carcare" />
          <img 
            className={classes.section4RightSquareImage} 
            src={squareimage} 
            alt="Carcare" />
          <Grid
            justify="space-around"
            container 
            spacing={24}
            className={classes.whatNextRow}
          >
            <div className={classes.next}>
              <img 
                className={classes.nextImage} 
                src={next1image} 
                alt="Carcare" />
              <div className={classes.nextDescription}>
                {t('landing.oneofourpartnerswillgetintouchwithyou')}
              </div>
            </div>
            <div className={classes.next}>
              <img 
                className={classes.nextImage} 
                src={next2image} 
                alt="Carcare" />
              <div className={classes.nextDescription}>
                {t('landing.enjoyyourservice')}
              </div>
            </div>
          </Grid>
        </div>
        <div id="partnerwithus" className={classes.section}>
          <div className={classes.partnerWithUsContainer}>
            <img 
              className={classes.section5LeftSquareImage} 
              src={squareimage} 
              alt="Carcare" />
            <img 
              className={classes.section5RightSquareImage} 
              src={squareimage} 
              alt="Carcare" />
              <Grid
                justify="space-between"
                container 
                spacing={24}
                className={classes.row}
              >
                <div className={classes.partner}>
                  <div className={classes.section5Header}>{t('landing.partnerwithus')}</div>
                  <div className={classes.section5Text1}>{t('landing.expandyourbusinessbyjoiningourplatformtoday')}</div>
                  <div className={classes.section5Text2}>
                    
                  </div>
                  <div className={classes.getStartedContainer}>
                    <a href="/partner-with-us" className={classes.getStarted}>{t('landing.getstarted')}</a>
                  </div>
                </div>
                <div className={classes.partner}>
                  <img 
                    className={classes.partnerImage} 
                    src={partnerimage} 
                    alt="Carcare" />
                </div>
              </Grid>
          </div>
        </div>
        <div id="contact" className={classes.section}>
          <img 
            className={classes.section6RightSquareImage} 
            src={squareimage} 
            alt="Carcare" />
          <Grid
              justify="space-around"
              container 
              spacing={24}
              className={classes.row}
            >
              <div className={classes.contact}>
                <img 
                  className={classes.contactImage} 
                  src={contactimage} 
                  alt="Carcare" />
              </div>
              <div className={classes.contactRight}>
                <ToastContainer />
                <div className={classes.section6Header}>{t('landing.getintouch')}</div>
                <div className={classes.section6Description}>{t('landing.foranyinquiriesorconcern')}</div>
                <div className={classes.section62Col}>
                  <TextField 
                    id="email" 
                    className={classes.email} 
                    label={t('landing.email')}
                    inputRef={emailRef}
                  />
                  <TextField 
                    id="phone" 
                    className={classes.phone} 
                    label={t('landing.phonenumber')}
                    inputRef={phoneRef}
                  />
                </div>
                <TextField 
                  id="message" 
                  className={classes.message} 
                  label={t('landing.message')}
                  inputRef={messageRef}
                />
                <div className={classes.sendContainer}>
                  <a className={classes.send} href="javascript:;" onClick={handleSend}>{t('landing.send')}</a>
                </div>
              </div>
            </Grid>
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default MainContent;