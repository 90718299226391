import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 959px) {
    flex-flow: column nowrap;
    background-color: #9F0000;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 7.5rem;
    margin-top: 0px;
    padding-left: 0px;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;

const useStyles = makeStyles(theme => ({
  menu: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 18,
      fontWeight: 500,
      display: 'block',
      paddingBottom: 30,
      opacity: 0.25
  },
  logout: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 18,
      fontWeight: 500,
      paddingBottom: 30,
      opacity: 0.25,
      display: 'block'
  }
}));

const RightNav = ({ open }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const roleId = localStorage.getItem('roleId');

  useEffect(() => {
      const language = localStorage.getItem('language');
      if(language) {
          i18n.changeLanguage(language);
      }
  }, [])

  function handleLogout(e) {
    localStorage.clear()
    history.push("/login")
  }

  return (
    <Ul open={open}>
        {roleId == 3 &&
          <>
            <NavLink
              to='/admin'
              exact
              activeStyle={{
                  fontWeight: "bold",
                  color: "#fff",
                  opacity: 1
              }}
              className={classes.menu}
            >
              {t('side.admin')}
            </NavLink>
            <NavLink
              to='/statement'
              exact
              activeStyle={{
                  fontWeight: "bold",
                  color: "#fff",
                  opacity: 1
              }}
              className={classes.menu}
            >
              {t('side.statement')}
            </NavLink>
            <NavLink
              to='/register?key=80lDrO0lQbOY43'
              exact
              activeStyle={{
                  fontWeight: "bold",
                  color: "#fff",
                  opacity: 1
              }}
              className={classes.menu}
            >
              {t('side.addcenter')}
            </NavLink>
            <NavLink
              to='/admin-metrics'
              exact
              activeStyle={{
                  fontWeight: "bold",
                  color: "#fff",
                  opacity: 1
              }}
              className={classes.menu}
            >
              {t('side.adminmetrics')}
            </NavLink>
          </>
        }
        <NavLink
              to='/add-booking'
              exact
              activeStyle={{
                  fontWeight: "bold",
                  color: "#fff",
                  opacity: 1
              }}
              className={classes.menu}
            >
              {t('side.addbooking')}
            </NavLink>
        <NavLink
          to='/dashboard'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.dashboard')}
        </NavLink>
        <NavLink
          to='/metrics'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.metrics')}
        </NavLink>
        <NavLink
          to='/customers'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.customers')}
        </NavLink>
        <NavLink
          to='/services'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.services')}
        </NavLink>
        <NavLink
          to='/promo-codes'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.promocodes')}
        </NavLink>
        <NavLink
            to='/history'
            exact
            activeStyle={{
                fontWeight: "bold",
                color: "#fff",
                opacity: 1
            }}
            className={classes.menu}
        >
          {t('side.history')}
        </NavLink>
        <NavLink
          to='/feedback'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.feedback')}
        </NavLink>
        <NavLink
          to='/notifications'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.notifications')}
        </NavLink>
        {roleId != 3 &&
        <>
          <NavLink
            to='/support'
            exact
            activeStyle={{
                fontWeight: "bold",
                color: "#fff",
                opacity: 1
            }}
            className={classes.menu}
          >
            {t('side.support')}
          </NavLink>
          <NavLink
              to='/privacy-policy'
              exact
              activeStyle={{
                  fontWeight: "bold",
                  color: "#fff",
                  opacity: 1
              }}
              className={classes.menu}
          >
            {t('side.privacypolicy')}
          </NavLink>
        </>
        }
        <li>
          <a 
              href="#" 
              className={classes.logout}
              onClick={handleLogout}
          >
              {t('side.logout')}
          </a>
      </li>
    </Ul>
  )
}

export default RightNav