import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    history: {
        width: '100%'
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        paddingBottom: 39,
        textAlign: 'center'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    amount: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    complete: {
        fontSize: 12,
        color: '#48D1B5',
        border: 'solid 1px #48D1B5',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    car: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        opacity: 0.25
    },
    customer: {
        width: 36,
        height: 36,
        borderRadius: 10,
        objectFit: 'cover',
        marginRight: 12
    },
    client: {
        display: 'flex',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    progress: {
        fontSize: 12,
        color: '#f1a542',
        border: 'solid 1px #f1a542',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    }
}));

const History = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    let [historydata, setHistorydata] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        axios.get(API_URL + `/business/${id}`)
        .then(res => {
            let business = res.data.business;

            axios.get(API_URL + `/business/requests/history/${business.id}`)
            .then(response => {
                setHistorydata(response.data.requests);
            })
        })
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                    <Table className={classes.history}>
                        <Thead>
                            <Tr>
                                <Th className={classes.header}>{t('history.client')}</Th>
                                <Th className={classes.header}>{t('history.date')}</Th>
                                <Th className={classes.header}>{t('history.service')}</Th>
                                <Th className={classes.header}>{t('history.price')}</Th>
                                <Th className={classes.header}>{t('history.status')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {historydata && historydata.map((history) => (
                                <Tr>
                                    <Td className={classes.left}>
                                        <div className={classes.client}>
                                            <div>
                                                <img
                                                    className={classes.customer}
                                                    src={API_URL + '/profile/image/' + (history.created_by_image ? history.created_by_image : 'default.png')} 
                                                />
                                            </div>
                                            <div>
                                                <div className={classes.name}>{history.first_name} {history.last_name}</div>
                                                <div className={classes.car}>{history.car_type} </div>
                                                <div className={classes.car}
                                                    style={{
                                                        opacity: 0.4
                                                    }}
                                                >{history.reference_number}</div>
                                            </div>
                                        </div>
                                    </Td>
                                    <Td className={classes.center}>
                                        {history.completed_date ? history.completed_date : history.request_date}
                                    </Td>
                                    <Td className={classes.center}>
                                        {history.outcall} 
                                        <div>{history.days_selected}</div>
                                    </Td>
                                    <Td className={classes.amount}>
                                        {history.currency} {parseInt(history.amount)}
                                    </Td>
                                    <Td className={classes.center}>
                                        <div className={history.completed_date ? classes.complete : (history.progress ? classes.progress : '')}>
                                            {history.completed_date ? (localStorage.getItem('language') == 'en' ? 'Completed' : 'منجز') : history.progress}
                                        </div>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default History;