import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Countdown from '../components/Countdown';
import axios from 'axios';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import starImage from '../images/star.png'; 
import Switch from '@material-ui/core/Switch';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 130,
    marginLeft: '-6%'
  },
  sideMenu: {
    backgroundColor: '#9F0000',
    width: 256,
    height: `calc(100vh - 130px)`,
    borderTopRightRadius: 75,
    color: '#fff',
    paddingTop: 70
  },
  mainContent: {
    width: `calc(100% - 256px)`,
    textAlign: 'left',
    ['@media (max-width:959)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  article: {
    paddingTop: 20,
    paddingLeft: 50
  },
  pageHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  serviceHeader: {
    paddingTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid'
  },
  indentedHeader: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    textAlign: 'left',
    paddingLeft: '3%',
    ['@media (max-width:959px)']: { 
      paddingLeft: 0
    },
    ['@media (min-width:959px)']: { 
      visibility: 'hidden'
    }
  },
  leftHeader: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    // paddingBottom: 39,
    textAlign: 'left',
    ['@media (min-width:959px)']: { 
      visibility: 'hidden'
    }
  },
  header: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    // paddingBottom: 39,
    textAlign: 'center',
    // ['@media (min-width:959px)']: { 
    //   display: 'none'
    // }
  },
  col1: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '25%',
    paddingLeft: '3%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingLeft: 0
    }
  },
  col2: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '5%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col3: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col4: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col5: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col6: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '25%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%',
      height: 'auto'
    }
  },
  col7: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '15%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  colAdd: {
    paddingLeft: '3%',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingLeft: 0
    }
  },
  addService: {
    textDecoration: 'none',
    color: '#9F0000'
  },
  addIcon: {
    position: 'absolute'
  },
  addServiceText: {
    marginLeft: 30,
    marginTop: 3
  },
  descriptionText: {
    color: '#a7a7a7',
    fontSize: 14,
    whiteSpace: 'pre-wrap'
  },
  serviceLink: {
    color: '#9F0000'
  },
  fieldsContainer: {
    marginTop: 20,
    width: 110,
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  timerContainer: {
    marginTop: 20,
    width: 110,
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  inputContainer: {
    width: '100%',
    height: 65
  },
  input: {
    width: '100%',
    fontSize: 16
  },
  saveContainer: {
    marginLeft: 65,
    marginTop: 10
  },
  saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -62,
      width: 44
  },
  saveBtnContainer: {
    width: 36,
    height: 36,
    borderRadius: 12,
    backgroundColor: '#9F0000',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  saveBtn: {
    width: 21,
    height: 21,
    paddingLeft: 3,
    position: 'absolute',
    marginLeft: 7,
    marginTop: 6
  },
  updateInfo: {
    display: 'flex',
    position: 'absolute',
    right: 200,
    ['@media (max-width:959px)']: { 
      position: 'initial',
      width: '90%',
      display: 'block',
      right: 'initial',
      marginLeft: -10
    }
  },
  flexContainer: {
    display: 'flex',
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    ['@media (max-width:959px)']: { 
      width: '100%',
      display: 'block'
    }
  },
  save1Container: {
    marginLeft: 65,
    marginTop: 10
  },
  formControl: {
    width: 250,
    "&:focus": {
      boxShadow: "none",
      outline: "none"
    },
    "&:active": {
      boxShadow: "none",
      outline: "none"
    }
  },
  applyContainer: {
    display: 'inline-block',
    marginTop: 0,
    marginLeft: 80
  },
  ratingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    justifyContent: 'flex-start',
    ['@media (max-width:768px)']: { 
      justifyContent: 'flex-start'
    }
  },
  tabContainer: {
    marginTop: 40,
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  buildingInput: {
    marginRight: 15
  }
}));

const Services = () => {
  const classes = useStyles();
  const id = localStorage.getItem('id');
  let [business, setBusiness] = useState('');
  let [services, updateServices] = useState('');
  let [allServices, setAllServices] = useState('');
  let [packages, setPackages] = useState('');
  let [selectedPackage, setSelectedPackage] = useState('');
  let [servicesPerHour, setServicesPerHour] = useState('');
  let [percentOffer, setPercentOffer] = useState('');
  let [durationDays, setDurationDays] = useState('');
  let [durationHours, setDurationHours] = useState('');
  let [offerUntil, setOfferUntil] = useState('');
  let [sort, setSort] = useState('');
  let [switchState, setSwitchState] = useState({});
  let [switchBuildingState, setSwitchBuildingState] = useState(false);
  let [building, setBuilding] = useState('');
  let [road, setRoad] = useState('');
  let [block, setBlock] = useState('');
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
        i18n.changeLanguage(language);
    }
    axios.get(API_URL + `/business-details/${id}`)
    .then(res => {
        let business = res.data.business ? res.data.business[0] : [];
        setBusiness(business);

        if(business) {
          setServicesPerHour(business.services_per_hour);
          setPercentOffer(business.offer_percent);
          setDurationDays(business.offer_duration_days);
          setDurationHours(business.offer_duration_hours);
          if(business.offer_until_date != business.start_date) {
            setOfferUntil(business.offer_until_date);
          }
        }

        axios.get(API_URL + `/wash-center/services/all/${business.id}`)
        .then(response => {
          // if(response.data.services) {
          //   setDynamicSwitchState(response.data.services[0].services);
          // }

          axios.get(API_URL + `/wash-center/packages/${business.id}`)
            .then(rsp => {
              let packages = rsp.data.packages;
              setPackages(packages);
              let svcs = response.data.services;

              if(packages && packages[0])  {
                setSelectedPackage(packages ? packages[0].service : '');
              
                if(packages && packages[0].service) {
                  if(svcs && svcs[0].services) {
                    let svc = svcs[0].services.filter(s => {
                      return s.type && s.type.toLowerCase() == packages[0].service.toLowerCase()
                    })
                    svcs[0].services = svc
                  }
                }
              }
              
              if(svcs) {
                setDynamicSwitchState(svcs[0].services);
              }    
              updateServices(svcs);
            })
          })

          axios.get(API_URL + `/business/services/sort/${business.id}`)
          .then(resp => {
            if(resp.data.sort) {
              setSort(resp.data.sort.value);
            }
          })

          axios.get(API_URL + `/wash-center/building-settings/${business.id}`)
          .then(rspns => {
            let settings =rspns.data.wash_center_building_settings
            if(settings && settings[0]) {
              setSwitchBuildingState(settings[0].enabled);
              setBuilding(settings[0].building);
              setRoad(settings[0].road);
              setBlock(settings[0].block);
            }
          })
    })
  }, [])
  
  if (!id) {
    return <Redirect to={'/login'} />;
  } 

  const handleFieldChange = (event, type) => {
    if(type == 'servicesPerHour') {
      setServicesPerHour(event.target.value);
    } else if(type == 'percentOffer') {
      setPercentOffer(event.target.value);
    } else if(type == 'durationDays') {
      setDurationDays(event.target.value);
    } else if(type == 'durationHours') {
      setDurationHours(event.target.value);
    } else if(type == 'building') {
      setBuilding(event.target.value);
    } else if(type == 'road') {
      setRoad(event.target.value);
    } else if(type == 'block') {
      setBlock(event.target.value);
    }
  };

  function handleClick(e) {
    e.preventDefault();

    if(servicesPerHour && parseInt(servicesPerHour) > 0) {
      const formData = new FormData();
      formData.append('services_per_hour', servicesPerHour);

      axios.post(API_URL + `/business/profile/update/${business.id}`, formData)
      .then(res => {
        if(res.data && res.data.business) {
          toast.success(t('services.saved'), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(t('services.error'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      toast.error(t('services.error'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleOfferClick(e) {
    e.preventDefault();

    let params;

    if(offerUntil) {
      // Cancel
      params = {
        offer_percent: '',
        offer_duration_days: '',
        offer_duration_hours: ''
      }
    } else {
      params = {
        offer_percent: percentOffer,
        offer_duration_days: durationDays,
        offer_duration_hours: durationHours
      }
    }

    axios.post(API_URL + `/business/offer/${business.id}`, params)
    .then(res => {
      if(res.data && res.data.offer) {
        toast.success(t('services.offersaved'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(true);
      } else {
        toast.error(t('services.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  function handleSortClick(e) {
    e.preventDefault();

    let params;
    if(sort == 'rating') {
      params = {
        value: sort
      }
    } else if(sort == 'custom') {
      params = {
        value: sort,
        services: services
      }
    }

    axios.post(API_URL + `/business/service/sort/update/${business.id}`, params)
    .then(res => {
      if(res.data) {
        toast.success(t('services.sortsaved'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(true);
      } else {
        toast.error(t('services.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  function renderStarRatings(feedback) {
    var ratings = [];
    if(feedback) {
      for(let i = 0; i < parseInt(feedback); i++){
        ratings.push(<div>
          <img
            src={starImage}
            className="rating-star"
          />
        </div>)
      }
    }
    return ratings;
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(services);
    const [reorderedItem] = items[0].services.splice(result.source.index, 1);
    items[0].services.splice(result.destination.index, 0, reorderedItem);

    updateServices(items);

    // Update sorting in database
    let params = {
      value: 'custom',
      services: items
    }

    axios.post(API_URL + `/business/service/sort/update/${business.id}`, params)
    .then(res => {
      console.log(res.data);
    });
  }

  const handleStatusChange = (index, event, checked, serviceId) => {
    const list = Object.assign({}, switchState);

    list['switch-' + index] = checked;

    let message = '';
    let post_url = '';
    if(checked) {
        message = t("services.activated");
        post_url = '/outcall-service/activate/';
    } else {
        message = t("services.deactivated");
        post_url = '/outcall-service/deactivate/';
    }

    axios.post(API_URL + post_url + `${serviceId}`, {})
    .then(res => {
      if(res.data && res.data.message) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSwitchState(list);
      }
    });
  }

  const setDynamicSwitchState = (list) => {
    if (!list) {
      return
    }

    const switchstate = {};

    list.forEach((item, index) => {
        switchstate['switch-' + index] = item.status == 1 ? true : false;
    });

    setSwitchState(switchstate);
  }

  const handleChange = async(event, newValue) => {
    setValue(newValue);

    let selected_package;
    if(packages) {
      packages.forEach((item, index) => {
        if(index == newValue) {
          selected_package = item.service;
          setSelectedPackage(selected_package);
        }
      })
    }

    if(selected_package) {
      axios.get(API_URL + `/wash-center/services/all/${business.id}`)
      .then(response => {
        let svcs = response.data.services;
        let svcs1 = []
        let svc = []
        if(packages && packages[0].service) {
          if(svcs && svcs[0].services) {
            svc = svcs[0].services.filter(s => {
              return s.type && s.type.toLowerCase() == selected_package.toLowerCase()
            })
          } 
        }
        svcs1.push({
          service_classification: svcs[0].service_classification,
          service_classification_id: svcs[0].service_classification_id,
          services: svc
        })

        if(svcs1) {
          setDynamicSwitchState(svcs1[0].services);
        } 
        updateServices(svcs1);
      })
    }
  };

  const handleBuildingSettingsChange = (event, checked) => {
    setSwitchBuildingState(checked);
  }

  function handleBuildingSettingsClick(e) {
    e.preventDefault();

    if(building) {
      let post_url = '/wash-center/building-settings/edit/';
      let message = switchBuildingState ? t("services.enabled") : t("services.disabled");;

      let params = {
        enabled: switchBuildingState ? 1  : 0,
        building: building,
        road: road,
        block: block
      }
      axios.post(API_URL + post_url + `${business.id}`, params)
      .then(res => {
        if(res.data && res.data.message) {
          toast.success(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      toast.error(t('services.error'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <div className="inner">
      <LoggedInTopMenu />
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
          <Hidden smDown>
            <div className={classes.sideMenu}>
              <SideMenu />
            </div>
          </Hidden>
          <div id="services-maincontent" className={classes.mainContent}>
            <div className={classes.article}>
                <div className={classes.pageHeader}>{t('services.services')}</div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginBottom: 15,
                        marginTop: 25
                      }}
                    >
                      <div>
                        <TextField 
                          id="servicesPerHour"
                          label={t('services.servicesperhour')} 
                          className={classes.input}
                          placeholder={t('services.servicesperhour')}
                          value={servicesPerHour || ''}
                          onChange={(event) => handleFieldChange(event, 'servicesPerHour')}
                        />
                      </div>
                      <div className={classes.saveContainer}>
                        <div className={classes.saveText}>{t('services.update')}</div>
                        <div 
                            onClick={handleClick}
                            className={classes.saveBtnContainer} 
                        >
                            <img 
                                className={classes.saveBtn} 
                                src={arrowrightwhiteimage} 
                                alt="Carcare" />
                        </div>
                      </div>
                    </div>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: 25
                    }}
                  >
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}>
                      <div
                        style={{
                          marginLeft: 5,
                          marginBottom: 15
                        }}
                      >
                        <TextField 
                          id="percentOffer"
                          label={t('services.percentoffer')} 
                          className={classes.input}
                          placeholder={t('services.percentoffer')}
                          value={percentOffer || ''}
                          onChange={(event) => handleFieldChange(event, 'percentOffer')}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: 5
                        }}
                      > 
                        <TextField 
                          id="durationDays"
                          label={t('services.durationdays')} 
                          className={classes.input}
                          placeholder={t('services.durationdays')}
                          value={durationDays || ''}
                          onChange={(event) => handleFieldChange(event, 'durationDays')}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: 5
                        }}
                      >
                        <TextField 
                          id="durationHours"
                          label={t('services.durationhours')} 
                          className={classes.input}
                          placeholder={t('services.durationhours')}
                          value={durationHours || ''}
                          onChange={(event) => handleFieldChange(event, 'durationHours')}
                        />
                      </div>
                      {offerUntil && 
                        <div
                          style={{
                            marginLeft: 5
                          }}
                        >
                          <div 
                            style={{
                              marginLeft: 3,
                              marginTop: -10,
                              color: '#808080',
                              fontSize: 11
                            }}
                          >
                            {t('services.timeleft')}
                          </div>
                          <Countdown date={offerUntil} />
                        </div>
                      }
                      <div className={classes.save1Container}>
                        <div className={classes.saveText}>{offerUntil ? t('services.cancel') : t('services.apply')}</div>
                        <div 
                            onClick={handleOfferClick}
                            className={classes.saveBtnContainer} 
                        >
                            <img 
                                className={classes.saveBtn} 
                                src={arrowrightwhiteimage} 
                                alt="Carcare" />
                        </div>
                      </div>
                    </div>
                  </div>    
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start'
                  }}
                >
                  <FormControlLabel
                    value="building"
                    control={<Switch 
                      key={1}
                      checked={switchBuildingState || false}
                      onChange={(event, checked) => handleBuildingSettingsChange(event, checked)}
                    />}
                    labelPlacement="start"
                    style={{
                      marginLeft: 0,
                      fontWeight: 'bold',
                      color: '#9F0000'
                    }}
                    label={
                      <Box component="div" fontSize={16}>
                        {t('services.building')}
                      </Box>
                    }
                  />
                  {!!switchBuildingState &&
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginLeft: 25
                      }}
                    >
                      <div className={classes.buildingInput}>
                        <TextField 
                          id="building"
                          label={t('services.buildingname')} 
                          className={classes.input}
                          placeholder={t('services.buildingname')}
                          value={building || ''}
                          onChange={(event) => handleFieldChange(event, 'building')}
                        />
                      </div>
                      <div className={classes.buildingInput}>
                        <TextField 
                          id="road"
                          label={t('services.road')} 
                          className={classes.input}
                          placeholder={t('services.road')}
                          value={road || ''}
                          onChange={(event) => handleFieldChange(event, 'road')}
                        />
                      </div>
                      <div className={classes.buildingInput}>
                        <TextField 
                          id="block"
                          label={t('services.block')} 
                          className={classes.input}
                          placeholder={t('services.block')}
                          value={block || ''}
                          onChange={(event) => handleFieldChange(event, 'block')}
                        />
                      </div>
                      <div className={classes.saveContainer}>
                        <div className={classes.saveText}>{t('services.apply')}</div>
                        <div 
                            onClick={handleBuildingSettingsClick}
                            className={classes.saveBtnContainer} 
                        >
                            <img 
                                className={classes.saveBtn} 
                                src={arrowrightwhiteimage} 
                                alt="Carcare" />
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <ToastContainer />
                <div id="tab-container" className={classes.tabContainer}>
                  {packages && packages.length > 0 &&
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="scrollable force tabs example"
                    >
                      {packages && packages.length > 0 && packages.map((item, index) => (
                        <Tab label={item.service} 
                        icon={ 
                          <img 
                            src={API_URL + '/car/type/image/' + item.image} 
                            style={{
                              width: 35,
                              height: 30,
                              objectFit: 'cover'
                            }}
                          /> } {...a11yProps(index)} />
                      ))} 
                    </Tabs>
                  </AppBar>
                  }
                  {services && services.length > 0 && services.map((service) => (
                    <TabPanel value={value} index={value}>
                      <>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          <Droppable droppableId="services">
                          {(provided) => (
                            <table {...provided.droppableProps} ref={provided.innerRef} style={{
                              width: '100%'
                            }}>
                              <thead>
                                {service.services && service.services.length > 1 && 
                                  <tr>
                                      <th className={classes.indentedHeader}>{t('services.servicename')}</th>
                                      <th className={classes.leftHeader}>{t('services.duration')}</th>
                                      <th className={classes.header}>S</th>
                                      <th className={classes.header}>M</th>
                                      <th className={classes.header}>L</th>
                                      <th className={classes.leftHeader}>{t('services.description')}</th>
                                      <th className={classes.header}>{t('services.percentofferheader')}</th>
                                  </tr>
                                }
                              </thead>
                              <tbody>
                                {packages && packages.length > 0 && service.services && service.services.length > 0 && service.services.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(provided) => (
                                    <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <td className={classes.col1}>
                                        <a className={classes.serviceLink} href={'/edit-service/' + item.id + '/' + item.service_classification_id}>{item.service_name}</a>
                                        <div
                                          style={{
                                            marginLeft: -12
                                          }}
                                        >
                                          <Switch key={index}
                                            checked={switchState['switch-' + index] || false}
                                            onChange={(event, checked) => handleStatusChange(index, event, checked, item.id)}
                                            value={item.status}
                                          />
                                        </div>
                                        <div className={classes.ratingsContainer}>{renderStarRatings(item.rating)}</div>
                                        <div>{item.name_ar}</div>
                                      </td>
                                      <td className={classes.col2}>{item.duration}{item.duration == 1 ? (item.unit == item.unit_to ? '' : item.unit) : (item.unit + 's')} {item.duration_to ? ('-' + item.duration_to + (item.duration_to == 1 ? item.unit_to : (item.unit_to + 's'))) : ''}</td>
                                      <td className={classes.col3}>
                                        {item.currency} {item.small_price}
                                        {switchBuildingState && item.building_small_price &&
                                          <>
                                            <div className={classes.header}>{t("services.building")}</div>
                                            <div>{item.currency} {item.building_small_price}</div>
                                          </>
                                        }
                                      </td>
                                      <td className={classes.col4}>
                                        {item.currency} {item.medium_price}
                                        {switchBuildingState && item.building_medium_price &&
                                          <>
                                            <div className={classes.header}>{t("services.building")}</div>
                                            <div>{item.currency} {item.building_medium_price}</div>
                                          </>
                                        }
                                      </td>
                                      <td className={classes.col5}>
                                        {item.currency} {item.large_price}
                                        {switchBuildingState && item.building_large_price &&
                                          <>
                                            <div className={classes.header}>{t("services.building")}</div>
                                            <div>{item.currency} {item.building_large_price}</div>
                                          </>
                                        }
                                      </td>
                                      <td className={classes.col6}>
                                        <span className={classes.descriptionText}>
                                          {item.description}
                                        </span>
                                        <div>
                                          <span className={classes.descriptionText}>
                                            {item.description_ar}
                                          </span>
                                        </div>
                                      </td>
                                      <td className={classes.col7}>
                                        <span
                                          style={{
                                            color: '#9F0000',
                                            fontWeight: 'bold'
                                          }}
                                        >{item.offer}</span>
                                      </td>
                                      {provided.placeholder}
                                    </tr>
                                    )}
                                  </Draggable>
                                ))}
                                <tr>
                                  <td colSpan="7" className={classes.colAdd}>
                                    <a className={classes.addService} href={'/add-service/' + service.service_classification_id}>
                                      <AddIcon className={classes.addIcon} />
                                      <div className={classes.addServiceText}>{t('services.addaservice')}</div>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                          </Droppable>
                        </DragDropContext>
                      </>
                    </TabPanel>
                  ))}
                </div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};
  
export default Services;