import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL, PLACES_API_KEY } from '../constants';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import moment from 'moment';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import starImage from '../images/star.png'; 
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Geocode from "react-geocode";
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Calendar from 'react-calendar-multiday';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    history: {
        width: '100%'
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        paddingBottom: 39,
        textAlign: 'center'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    amount: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    complete: {
        fontSize: 12,
        color: '#48D1B5',
        border: 'solid 1px #48D1B5',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    car: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        opacity: 0.25
    },
    customer: {
        width: 36,
        height: 36,
        borderRadius: 10,
        objectFit: 'cover',
        marginRight: 12
    },
    client: {
        display: 'flex',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    progress: {
        fontSize: 12,
        color: '#f1a542',
        border: 'solid 1px #f1a542',
        fontWeight: 'bold',
        width: 115,
        margin: '0 auto',
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    rank: {
        marginTop: 3
    },
    star: {
        width: 16,
        height: 16
    },
    ratingsContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 auto',
        justifyContent: 'center',
        ['@media (max-width:768px)']: { 
            justifyContent: 'flex-start'
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    dialogTitle: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    dialogContent: {
        width: 480,
        marginTop: 50,
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    serviceInfoDate: {
        fontSize: 14,
        color: '#000',
        opacity: 0.25,
        marginTop: -10
    },
    dialogUserContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    dialogUserImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogCarImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogUser: {
        textAlign: 'center'
    },
    dialogCar: {
        textAlign: 'center'
    },
    dialogFlexContainer: {
        display: 'flex'
    },
    dialogRow: {
        marginTop: 60
    },
    modalIconImage: {
        width: 36,
        height: 36
    },
    dialogRightText: {
        marginLeft: 20,
        paddingTop: 6,
        marginLeft: 20,
        paddingTop: 6,
        width: '85%'
    },
    dialogFlexEndContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogRight: {
        position: 'absolute',
        right: 64,
        fontWeight: 'bold'
    },
    dialogRightLast: {
        position: 'absolute',
        right: 64,
        marginTop: 25,
        fontWeight: 'bold'
    },
    dialogBottomRow: {
        marginTop: 25
    },
    mapContainer: {
        height: 319, 
        width: '100%', 
        borderRadius: 40,
        marginTop: 20
    },
    mapIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    whatsappIcon: {
        color: '#075e54',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    emailIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
}));

const Customers = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    const roleId = localStorage.getItem('roleId');
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('body');
    let [customers, setCustomers] = useState('');
    let [feedbacks, setFeedbacks] = useState('');
    let [selectedRating, setSelectedRating] = useState([]);
    let [center, setCenter] = useState('');
    let [zoom, setZoom] = useState('');
    let [google, setGoogle] = useState('');
    let [directions, setDirections] = useState('');
    let [drivetime, setDrivetime] = useState('');
    let [location, setLocation] = useState('');
    let [timeslot, setTimeslot] = useState('');
    let [customerlatitude, setCustomerlatitude] = useState('');
    let [customerlongitude, setCustomerlongitude] = useState('');
    let [customerlocation, setCustomerlocation] = useState('');
    let [whatsapplink, setWhatsapplink] = useState('');
    let [mailto, setMailto] = useState('');
    let [servicedays, setServicedays] = useState('');
    let [switchState, setSwitchState] = useState({});
    let [detailsShown, setDetailsShown] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        
        axios.get(API_URL + `/business/${id}`)
        .then(res => {
            let business = res.data.business;

            axios.get(API_URL + `/customers/${business.id}`)
            .then(response => {
                setCustomers(response.data.customers);
                setDynamicSwitchState(response.data.customers);
            })
        })
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    const setDynamicSwitchState = (list) => {
        if (!list) {
          return
        }
    
        const switchstate = {};
    
        list.forEach((item, index) => {
            switchstate['switch-' + index] = item.status == 1 ? true : false;
        });
    
        setSwitchState(switchstate);
    }

    const handleChange = (index, event, checked, customerId) => {
        const list = Object.assign({}, switchState);
    
        list['switch-' + index] = checked;

        let message = '';
        let post_url = '';
        if(checked) {
            message = t("customers.activated");
            post_url = '/customer/activate/';
        } else {
            message = t("customers.deactivated");
            post_url = '/customer/deactivate/';
        }

        axios.post(API_URL + post_url + `${customerId}`, {})
        .then(res => {
            if(res.data && res.data.message) {
                toast.success(message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSwitchState(list);
            }
        });
    }

    const viewBooking = (event, customerName) => {
        const shownState = detailsShown;
        const index = shownState.indexOf(customerName);
        if(index >= 0) {
                console.log('exists');
                shownState.splice(index, 1);
                setDetailsShown(shownState);
        } else {
                console.log('not exists');
                shownState.push(customerName);
                setDetailsShown(shownState);
        }
    }

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
            justify="space-between"
            container
            >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                    <ToastContainer />
                    <Table className={classes.history}>
                        <Thead>
                            <Tr>
                                <Th className={classes.header}>{t('customers.name')}</Th>
                                <Th className={classes.header}>{t('customers.email')}</Th>
                                <Th className={classes.header}>{t('customers.phonenumber')}</Th>
                                <Th className={classes.header}>{t('customers.address')}</Th>
                                <Th className={classes.header}>{roleId == 3 ? t('customers.status') : t('customers.noofbookings')}</Th>
                                <Th className={classes.header}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {customers && customers.map((customer, i) => (
                                <>
                                    <Tr key={customer.id}>
                                        <Td className={classes.left}>
                                            <div className={classes.client}>
                                                <div>
                                                    <img
                                                        className={classes.customer}
                                                        src={API_URL + '/profile/image/' + (customer.image ? customer.image : 'default.png')} 
                                                    />
                                                </div>
                                                <div style={{ paddingTop: 10 }}>
                                                    <div className={classes.name}>{customer.first_name} {customer.last_name}</div>
                                                </div>
                                            </div>
                                        </Td>
                                        <Td className={classes.center}>
                                            <a href="mailto:{customer.email}">{customer.email}</a>
                                        </Td>
                                        <Td className={classes.center}>
                                            <a href="tel:{customer.phone_number}">{customer.phone_number}</a>
                                        </Td>
                                        <Td className={classes.center}>
                                            {customer.home_address}
                                        </Td>
                                        
                                        <Td className={classes.center}>
                                            {roleId == 2 &&
                                                <span>{customer.bookings.length}</span>
                                            }
                                            {roleId == 3 &&
                                                <Switch key={i}
                                                    checked={switchState['switch-' + i] || false}
                                                    onChange={(event, checked) => handleChange(i, event, checked, customer.id)}
                                                    value={customer.status}
                                                />
                                            }
                                        </Td>
                                        <Td className={classes.center}>
                                            <div className={classes.progress}>
                                                <a 
                                                    style={{
                                                        color: '#f1a542'
                                                    }}
                                                    href="#"
                                                    onClick={(event) => viewBooking(event, customer.first_name + ' ' + customer.last_name)}
                                                >
                                                    {t('customers.viewbookings')}
                                                </a>
                                            </div>
                                        </Td>
                                    </Tr>
                                    <Hidden mdUp>
                                        {detailsShown.includes(customer.first_name + ' ' + customer.last_name) && (
                                            <Table className={classes.history}>
                                                <Thead>
                                                    <Tr>
                                                        <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.servicename')}</Th>
                                                        <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.cartype')}</Th>
                                                        <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.payment')}</Th>
                                                        <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.referencenumber')}</Th>
                                                        <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.date')}</Th>
                                                        <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.status')}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                {customer.bookings && customer.bookings.map((booking, i) => (
                                                    <Tr>
                                                        <Td style={{ height: 40 }} className={classes.center}>
                                                            {booking.service_name}
                                                        </Td>
                                                        <Td style={{ height: 40 }} className={classes.center}>
                                                            {booking.car_type == 'Small' ? t('booking.small') : (booking.car_type == 'Medium' ? t('booking.medium') : (booking.car_type == 'Large' ? t('booking.large') : ''))}
                                                        </Td>
                                                        <Td style={{ height: 40 }} className={classes.center}>
                                                            {booking.payment_method}
                                                        </Td>
                                                        <Td style={{ height: 40 }} className={classes.center}>
                                                            {booking.reference_number}
                                                        </Td>
                                                        <Td style={{ height: 40 }} className={classes.center}>
                                                            {booking.date}
                                                        </Td>
                                                        <Td style={{ height: 40 }} className={classes.center}>
                                                            {booking.completed_at ? 
                                                                t('booking.completed') 
                                                                : (booking.cancelled_at ? 
                                                                    t('booking.cancelled') : 
                                                                    (booking.status == 1 ? 
                                                                        t('booking.received') : 
                                                                        (booking.status == 3 ? 
                                                                            t('booking.ontheway') : 
                                                                            (booking.status == 2 ? 
                                                                                t('booking.paymentcomplete') : 
                                                                                    (booking.status == 4 ? t('booking.completed') : ''))
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        </Td>
                                                    </Tr>
                                                ))}
                                                </Tbody>
                                            </Table>
                                        )}  
                                    </Hidden>
                                    <Hidden smDown>
                                        {detailsShown.includes(customer.first_name + ' ' + customer.last_name) && (
                                            <>
                                            <Tr>
                                                <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.servicename')}</Th>
                                                <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.cartype')}</Th>
                                                <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.payment')}</Th>
                                                <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.referencenumber')}</Th>
                                                <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.date')}</Th>
                                                <Th style={{ paddingBottom: 10 }} className={classes.header}>{t('booking.status')}</Th>
                                            </Tr>
                                            {customer.bookings && customer.bookings.map((booking, i) => (
                                                <Tr>
                                                    <Td style={{ height: 40 }} className={classes.center}>
                                                        {booking.service_name}
                                                    </Td>
                                                    <Td style={{ height: 40 }} className={classes.center}>
                                                        {booking.car_type == 'Small' ? t('booking.small') : (booking.car_type == 'Medium' ? t('booking.medium') : (booking.car_type == 'Large' ? t('booking.large') : ''))}
                                                    </Td>
                                                    <Td style={{ height: 40 }} className={classes.center}>
                                                        {booking.payment_method}
                                                    </Td>
                                                    <Td style={{ height: 40 }} className={classes.center}>
                                                        {booking.reference_number}
                                                    </Td>
                                                    <Td style={{ height: 40 }} className={classes.center}>
                                                        {booking.date}
                                                    </Td>
                                                    <Td style={{ height: 40 }} className={classes.center}>
                                                        {booking.completed_at ? 
                                                            t('booking.completed') 
                                                            : (booking.cancelled_at ? 
                                                                t('booking.cancelled') : 
                                                                (booking.status == 1 ? 
                                                                    t('booking.received') : 
                                                                    (booking.status == 3 ? 
                                                                        t('booking.ontheway') : 
                                                                        (booking.status == 2 ? 
                                                                            t('booking.paymentcomplete') : 
                                                                                (booking.status == 4 ? t('booking.completed') : ''))
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    </Td>
                                                </Tr>
                                            ))}
                                            </>
                                        )}
                                    </Hidden>
                                </>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default Customers;