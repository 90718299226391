import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { Line, Bar} from 'react-chartjs-2';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Business } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    pageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    filterContainer: {
        marginTop: 20,
        display: 'flex'
    },
    selectTime: {
        width: 200,
        marginRight: 10,
        "&:active": {
            border: "initial"
        },
        "&:focus": {
            border: "initial"
        },
        ['@media (max-width:959px)']: { 
          width: 125
        }
    },
    selectRevenue: {
        width: 200,
        "&:active": {
            border: "initial"
        },
        "&:focus": {
            border: "initial"
        }
    }
}));

const Metrics = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    let [business, setBusiness] = useState('');
    let [revenue, setRevenue] = useState('');
    let [customers, setCustomers] = useState('');
    let [labels, setLabels] = useState('');
    let [data, setData] = useState('');
    let [timefilter, setTimefilter] = useState('week');
    let [revenuefilter, setRevenuefilter] = useState('revenues');
    let [revenueTotal, setRevenueTotal] = useState('');
    let [orderCount, setOrderCount] = useState('');
    const { t, i18n } = useTranslation();

    const options = {
        responsive: true,
        labels: labels,
        tooltips: {
            mode: 'label'
        },
        elements: {
            line: {
                fill: false
            }
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    display: true
                },
                labels: labels,
                scaleLabel: {
                    display: true,
                    labelString: localStorage.getItem('time') == 'week' ? t('metrics.days') : (localStorage.getItem('time') == 'month' ? t('metrics.weeks') : t('metrics.months'))
                }
            }],
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: {
                    display: true
                },
                labels: {
                    show: true
                },
                ticks: {
                    beginAtZero: true,
                    min: 0
                },
                scaleLabel: {
                    display: true,
                    labelString: localStorage.getItem('revenue') == 'revenues' ? t('metrics.revenueinbd') : t('metrics.nooforders')
                }
            },
            {
                type: 'linear',
                display: false,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            }]
        }
    };

    useEffect(() => {
        let bus;
        let revenueGrowth;
        let customer;
        let lab;
        localStorage.setItem('time', 'week');
        localStorage.setItem('revenue', 'revenues');

        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        
        axios.get(API_URL + `/business/${id}`)
            .then(res => {
                bus = res.data.business;
                setBusiness(bus);
                axios.get(API_URL + `/metrics/wash-center-new/${bus.id}/week/revenues`)
                .then(resp => {
                    revenueGrowth = resp.data.revenue;
                    customer = resp.data.customers;
                    lab = resp.data.labels;
                    setRevenue(revenueGrowth);
                    setCustomers(customer);
                    setLabels(lab);

                    setOrderCount(resp.data.order_count);
                    setRevenueTotal(resp.data.revenue_total);

                    localStorage.setItem('order_count', resp.data.order_count ? resp.data.order_count.toString() : '');
                    localStorage.setItem('revenue_total', resp.data.revenue_total ? resp.data.revenue_total.toString() : '');

                    let metrics = {
                        datasets: [{
                            label: t('metrics.revenues'),
                            type:'line',
                            data: revenueGrowth,
                            fill: false,
                            borderColor: '#9F0000',
                            backgroundColor: '#EC932F',
                            pointBorderColor: '#000000',
                            pointBackgroundColor: '#FFFFFF',
                            pointHoverBackgroundColor: '#9F0000',
                            pointHoverBorderColor: '#EC932F',
                            yAxisID: 'y-axis-2'
                        },{
                            type: 'bar',
                            label: t('metrics.orders'),
                            data: customer,
                            fill: false,
                            backgroundColor: 'rgba(249,210,210,1)',
                            borderColor: '#9F0000',
                            hoverBackgroundColor: '#9F0000',
                            hoverBorderColor: '#9F0000',
                            yAxisID: 'y-axis-1'
                        }]
                    };
                    setData(metrics)
                })
        });
    }, [])

    const handleTimeFilterChange = (event) => {
        localStorage.setItem('time', event.target.value);
        setTimefilter(event.target.value);
        filterData();
    };

    const handleRevenueFilterChange = (event) => {
        localStorage.setItem('revenue', event.target.value);
        setRevenuefilter(event.target.value);
        filterData();
    };

    function filterData() {
        let revenueGrowth;
        let customer;
        let lab;
        const time = localStorage.getItem('time');
        const revenue = localStorage.getItem('revenue');
        axios.get(API_URL + `/metrics/wash-center-new/${business.id}/${time ? time : 'week'}/${revenue ? revenue : 'revenues'}`)
            .then(resp => {
                revenueGrowth = resp.data.revenue;
                customer = resp.data.customers;
                lab = resp.data.labels;

                setOrderCount(resp.data.order_count);
                setRevenueTotal(resp.data.revenue_total);

                localStorage.setItem('order_count', resp.data.order_count ? resp.data.order_count.toString() : '');
                localStorage.setItem('revenue_total', resp.data.revenue_total ? resp.data.revenue_total.toString() : '');

                let metrics = {}
                if(revenue == 'revenues') {
                    setRevenue(revenueGrowth);
                    setLabels(lab);
                    metrics = {
                        datasets: [{
                            type: 'bar',
                            label: t('metrics.revenues'),
                            data: revenueGrowth,
                            fill: false,
                            backgroundColor: '#9F0000',
                            borderColor: '#9F0000',
                            hoverBackgroundColor: '#9F0000',
                            hoverBorderColor: '#9F0000',
                            yAxisID: 'y-axis-1'
                        }]
                    };
                } else if (revenue == 'orders') {
                    setCustomers(customer);
                    setLabels(lab);
                    metrics = {
                        datasets: [{
                            type: 'bar',
                            label: t('metrics.orders'),
                            data: customer,
                            fill: false,
                            backgroundColor: 'rgba(249,210,210,1)',
                            borderColor: 'rgba(249,210,210,1)',
                            hoverBackgroundColor: 'rgba(249,210,210,1)',
                            hoverBorderColor: 'rgba(249,210,210,1)',
                            yAxisID: 'y-axis-1'
                        }]
                    };
                } else {
                    setRevenue(revenueGrowth);
                    setCustomers(customer);
                    setLabels(lab);
                    metrics = {
                        datasets: [{
                            label: t('metrics.revenues'),
                            type:'line',
                            data: revenueGrowth,
                            fill: false,
                            borderColor: '#9F0000',
                            backgroundColor: '#EC932F',
                            pointBorderColor: '#000000',
                            pointBackgroundColor: '#FFFFFF',
                            pointHoverBackgroundColor: '#9F0000',
                            pointHoverBorderColor: '#EC932F',
                            yAxisID: 'y-axis-2'
                        },{
                            type: 'bar',
                            label: t('metrics.orders'),
                            data: customer,
                            fill: false,
                            backgroundColor: 'rgba(249,210,210,1)',
                            borderColor: '#9F0000',
                            hoverBackgroundColor: '#9F0000',
                            hoverBorderColor: '#9F0000',
                            yAxisID: 'y-axis-1'
                        }]
                    };
                }
                setData(metrics)
            })
    }
  
    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
                justify="space-between"
                container
            >
                <Hidden smDown>
                    <div className={classes.sideMenu}>
                        <SideMenu />
                    </div>
                </Hidden>
                <ToastContainer />
                <div className={classes.mainContent}>
                    <div className={classes.article}>
                        <div className={classes.pageHeader}>{t('metrics.metrics')}</div>
                        <div className={classes.filterContainer}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="time-select-outlined-label"
                                    id="time-select-outlined"
                                    value={timefilter}
                                    onChange={handleTimeFilterChange}
                                    className={classes.selectTime}
                                >
                                    <MenuItem value="week">{t('metrics.thisweek')}</MenuItem>
                                    <MenuItem value="month">{t('metrics.thismonth')}</MenuItem>
                                    <MenuItem value="year">{t('metrics.thisyear')}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="revenue-select-outlined-label"
                                    id="revenue-select-outlined"
                                    value={revenuefilter}
                                    onChange={handleRevenueFilterChange}
                                    className={classes.selectRevenue}
                                    displayEmpty
                                >
                                    {/* <MenuItem value="">{t('metrics.anyrevenue')}</MenuItem>
                                    <MenuItem value={1}>0 - 1000</MenuItem>
                                    <MenuItem value={2}> &gt; 1000</MenuItem>
                                    <MenuItem value={3}> &gt; 10000</MenuItem> */}
                                    <MenuItem value={"revenues"}>{t('metrics.revenues')}</MenuItem>
                                    <MenuItem value={"orders"}>{t('metrics.orders')}</MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{
                                    paddingTop: 8,
                                    paddingLeft: 10
                                }}>
                                <b>{revenuefilter == 'revenues' ? t('metrics.revenue') : t('metrics.orders')} {(timefilter == 'week' ? t('metrics.thisweek') : (timefilter == 'year' ? t('metrics.thisyear') : t('metrics.thismonth')))}:</b> {revenuefilter == 'revenues' ? (localStorage.getItem('revenue_total') ? ('BD ' + localStorage.getItem('revenue_total')) : '-') : (localStorage.getItem('order_count') ? (localStorage.getItem('order_count')) : '-')}
                            </div>
                        </div>
                        {data &&
                            <Bar
                                data={data}
                                options={options}
                            />
                        }
                    </div>
                </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default Metrics;