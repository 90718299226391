import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { API_URL, PUSHER_APP_KEY } from '../constants';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logoimage from '../images/logo-image.png'; 
import logotext from '../images/logo-text.png'; 
import Navbar from '../components/Side/Navbar';
import Hidden from '@material-ui/core/Hidden';
import bahrainimage from '../images/bahrain.png'; 
import usimage from '../images/us.png'; 
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { Icon, Popup, Header, Placeholder } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Pusher from 'pusher-js';
import axios from 'axios';
import useSound from 'use-sound';
import soundFile from '../assets/alert.mp3';

const drawerWidth = 0;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: `#fff` ,
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: 18
  },
  logoImage: {
    width: 40
  },
  logoText: {
    width: 104,
    position: 'absolute',
    top: 34,
    left: 102,
    ['@media (max-width:959px)']: { 
      top: 54,
      left: 77
    }
  },
  toolbar: {
    paddingLeft: 55,
    paddingRight: 32,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 20,
    ['@media (max-width:959px)']: { 
      paddingLeft: 32,
      paddingTop: 35
    }
  },
  rightMenu: {
    paddingRight: 38
  },
  userImageContainer: {
    width: 48,
    height: 48,
    display: 'inline-block',
    marginRight: 15
  },
  userImage: {
    width: 48,
    height: 48,
    objectFit: 'cover',
    borderRadius: 10
  },
  nameContainer: {
    fontSize: 14,
    color: '#000',
    display: 'inline-block',
    position: 'relative',
    marginTop: 15,
    float: 'right'
  },
  arabic: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 'solid 1px #9F0000',
    position: 'absolute',
    marginLeft: -75,
    marginTop: 11,
    "&:hover": {
        cursor: "pointer"
    }
  },
  english: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 'solid 1px #9F0000',
    position: 'absolute',
    marginLeft: -42,
    marginTop: 11,
    "&:hover": {
        cursor: "pointer"
    }
  },
  notificationsContainer: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: 'solid 1px rgba(0,0,0,0.1)'
  },
  notificationImage: {
    width: 36,
    height: 36,
    borderRadius: 12,
    objectFit: 'cover'
  },
  notificationDesc: {
    marginLeft: 15,
    color: '#000',
    "&:hover": {
        cursor: "pointer"
    }
  }
}));

function LoggedInTopMenu(props) {
  const classes = useStyles();
  const image = localStorage.getItem('image');
  const userName = localStorage.getItem('name');
  const userAvatar = API_URL + `/profile/image/` + image;
  const id = localStorage.getItem('id');
  const roleId = localStorage.getItem('roleId');
  const [data, setData] = React.useState(null);
  const [homeUrl, setHomeUrl] = React.useState('');
  const [notifications, setNotifications] = React.useState(null);
  const [recentNotifications, setRecentNotifications] = React.useState(null);
  const { t, i18n } = useTranslation();
  const timer = React.useRef();
  const [play] = useSound(soundFile);
  const inputRef = React.useRef(null);

  useEffect(() => {
    const language = localStorage.getItem('language');
    const id = localStorage.getItem('id');
    if(language) {
        i18n.changeLanguage(language);
    }
    if(id > 0) {
      setHomeUrl('/dashboard');
    } else {
      setHomeUrl('/');
    }
    
    axios.get(API_URL + `/business/${id}`)
    .then(res => {
      let business = res.data.business;

      const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: 'ap2',
        encrypted: true
      });

      if(business) {
        var channel = pusher.subscribe('business-' + business.id);
        channel.bind('notifications', function(data) {
          console.log('notificaton from pusher');
          inputRef.current.click();
        });

        // Get notifications
        axios.get(API_URL + `/notifications/${business.id}`)
        .then(response => {
          let notifs = response.data.notifications;
          setNotifications(notifs);

          if(notifs && notifs.length > 5) {
            setRecentNotifications(notifs.slice(0, 5));
          } else {
            setRecentNotifications(notifs);
          }
        });
      }
    });
  }, [])

  const changeLanguage = code => {
    i18n.changeLanguage(code);
    localStorage.setItem('language', code);
  };

  const offset = ({ placement, popper }) => {
    if (placement === 'bottom') {
      return [0, 10]
    }

    return []
  }

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <div ref={inputRef} onClick={play}>CarCare</div>
      <Toolbar className={classes.toolbar}>
        <Grid
          justify="space-between"
          container 
          spacing={24}
        >
          <Grid item>
            <a href={homeUrl}>
              <img 
                  className={classes.logoImage} 
                  src={logoimage} 
                  alt="Carcare" />
              <img 
                  className={classes.logoText} 
                  src={logotext} 
                  alt="Carcare" />
            </a>
          </Grid>
          <Grid 
            item
            className={classes.rightMenu} 
          >
            <Hidden mdUp>
              <Navbar />
            </Hidden>
            <Hidden smDown>
              <img 
                className={classes.arabic} 
                src={bahrainimage} 
                alt="Arabic" 
                onClick={() => changeLanguage('ar')}
              />
              <img 
                className={classes.english} 
                src={usimage} 
                alt="English" 
                onClick={() => changeLanguage('en')}
              />
              {roleId == 2 &&
                <>
                  <Popup
                    trigger={
                      <Badge 
                        style={{
                          marginRight: 30,
                          marginTop: -39,
                          marginLeft: 15
                        }}
                        badgeContent={notifications && notifications.length} 
                        color="primary"
                      >
                        <Icon size='large' name='bell' />
                      </Badge>
                    }
                    content='Has a half width offset'
                    offset={offset}
                    position='bottom center'
                    style={{
                      color: '#9F0000'
                    }}
                    wide
                    on='click'
                    onClose={() => {
                      setRecentNotifications(null)
                      clearTimeout(timer.current)
                    }}
                    onOpen={() => {
                      setRecentNotifications(null)

                      timer.current = setTimeout(() => {
                        setRecentNotifications(recentNotifications)
                      }, 1000)
                    }}
                    popperDependencies={[!!data]}
                  >
                    {recentNotifications === null ? (
                      <Placeholder style={{ minWidth: '200px' }}>
                        <Placeholder.Header>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    ) : (
                      <>
                        {recentNotifications && recentNotifications.map((item, index) => (
                          <div
                            className={classes.notificationsContainer}
                          >
                            <img
                              className={classes.notificationImage}
                              src={API_URL + '/profile/image/' + (item.image ? item.image : 'default.png')} 
                            />
                            <div
                              className={classes.notificationDesc}
                              onClick={() => props.notificationClick(item.request_id, item.request_date_id) }
                            >
                              <a
                                style={{
                                  color: '#000'
                                }}
                                href={'/notification/' + item.request_id}
                              >
                                { item.request_date_id ? item.request_date : item.date } [{ item.first_name } { item.last_name }] { item.action == 'new' ? t('notifications.new') : (item.action == 'edit' ? t('notifications.edit') : (item.action == 'cancel' ? t('notifications.cancel') : '')) }
                              </a>
                            </div>
                          </div>
                        ))}
                        {notifications && notifications.length > 5 &&
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: 11
                            }}
                          >
                            <a 
                              style={{
                                color: '#9F0000'
                              }}
                              href="/notifications">
                              {t('notifications.seeall')}
                            </a>
                          </div>
                        }
                      </>
                    )}
                  </Popup>
                  <a href="/profile">
                    <div className={classes.userImageContainer}>
                      <img 
                        className={classes.userImage} 
                        src={userAvatar}
                        alt="Carcare"
                      />
                    </div>
                    <div className={classes.nameContainer}>
                      {userName}
                    </div>
                  </a>
                </>
              }
              {roleId == 3 &&
                <a href="/admin-profile">
                  <div className={classes.userImageContainer}>
                    <img 
                      className={classes.userImage} 
                      src={userAvatar}
                      alt="Carcare"
                    />
                  </div>
                  <div className={classes.nameContainer}>
                    {userName}
                  </div>
                </a>
              }
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default LoggedInTopMenu;