import React, { useRef, useEffect, useState }  from "react";
import { makeStyles } from '@material-ui/core/styles';
import { API_URL } from '../constants';
import { Redirect, useParams, useHistory } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ReactLoading from 'react-loading';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    paymentContainer: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    paymentSuccess: {
        color: '#000',
        fontSize: 25,
        width: 200,
        margin: '0 auto'
    },
    check: {
        fontSize: 60,
        color: '#22bb33'
    },
    charge: {
        marginTop: 80,
        fontSize: 14
    },
    error: {
        fontSize: 60,
        color: '#ff3333'
    },
    paymentFailed: {
        color: '#000',
        fontSize: 25,
        width: 205,
        margin: '0 auto'
    },
    errorMessage: {
        marginTop: 80,
        fontSize: 14
    }
}));

const Pay =() => {
    const classes = useStyles();
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [charge, setCharge] = useState(false);
    const params = useParams();
    const [requestId, setRequestId] = useState(params.id);
    const [paymentUnsuccessful, setPaymentUnsuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let tap_id = params.get('tap_id');

        if(tap_id) {
            // Get charge details
            setLoading(true);
            axios.get(API_URL + `/charge-details-new/${tap_id}`)
            .then(response => {
                console.log(response);
                console.log(response.data.statusCode);
                if(response.data.statusCode == 200) {
                    if(response.data.body.response.code == '000') {
                        history.push(`/payment-success/${tap_id}`);
                    } else {
                        history.push(`/payment-fail/${tap_id}`);
                    }
                } else {
                    history.push(`/payment-fail/${tap_id}`);
                }
            })
            
        } else {
            axios.post(API_URL + `/charge-new/${requestId}`, {})
            .then(response => {
                if(response.status == 200) {
                    let url = response.data.body.transaction.url;
                    if(url) {
                        setLoading(false);
                        window.location.href = url;
                    }
                }
            })  
        }
    }, []);
    
    return (
        <div className={classes.paymentContainer}>
            {paymentSuccessful &&
                <div>
                    <CheckCircleIcon className={classes.check} />
                    <div className={classes.paymentSuccess}>Your payment was successful!</div>
                    <div className={classes.charge}><b>Charge ID:</b> {charge}</div>
                </div>
            }
            {paymentUnsuccessful &&
                <div>
                    <ErrorIcon className={classes.error} />
                    <div className={classes.paymentFailed}>Your payment was unsuccessful.</div>
                    <div className={classes.errorMessage}><b>Error:</b> {errorMessage}</div>
                </div>
            }
            {loading && 
                <ReactLoading type={'bars'} color="#9f0000" />
            }
        </div>
    );
}

export default Pay;